import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const Calendar = props => (
  <BaseIcon width="44" height="49" {...props} viewBox="0 0 44 49">
    <path
      fill="currentColor"
      d="M19.2 40l-7.6-7.667 1.867-1.866 5.733 5.666 11.334-11.266 1.866 1.866L19.2 40zM5 49c-1.244 0-2.277-.41-3.098-1.232-.823-.823-1.235-1.857-1.235-3.101v-34c0-1.245.412-2.278 1.235-3.099C2.723 6.745 3.756 6.333 5 6.333h4.667V.4h2.867v5.933h19.133V.4h2.667v5.933H39c1.245 0 2.279.412 3.102 1.235.821.821 1.232 1.854 1.232 3.099v34c0 1.244-.411 2.278-1.232 3.101C41.279 48.589 40.245 49 39 49H5zm0-2.667h34c.445 0 .833-.167 1.166-.501.334-.332.501-.72.501-1.165V21.333H3.334v23.334c0 .444.167.833.501 1.165.332.334.72.501 1.165.501zM3.334 18.667h37.333v-8c0-.445-.167-.833-.501-1.166C39.833 9.167 39.445 9 39 9H5c-.444 0-.833.167-1.165.501-.334.333-.501.721-.501 1.166v8z"
    />
  </BaseIcon>
);

export default Calendar;
