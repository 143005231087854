import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { border, space } from '../../../../shared/spacing';
import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';

const commonMenuItemStyles = ({ isDisabled, isSelected }) => css`
  cursor: default;
  outline: 0;
  ${isDisabled
    ? css`
        pointer-events: none;
        color: ${colors.greyLight};
      `
    : null}
  ${isSelected
    ? css`
        color: ${colors.primary};
      `
    : null}

  &:focus {
    background-color: ${colors.bg};
  }
  &:hover {
    background-color: ${colors.bg};
  }
`;

const commonMenuStyles = () => css`
  position: absolute;
  overflow: hidden;

  background-color: ${colors.white};
  border-radius: ${border.radius};
  z-index: 1;
`;

const StyledSelectMenuItem = styled.div`
  ${props => commonMenuItemStyles(props)}
  padding: ${space.x1};
  font-size: ${fonts.sizes.s};
`;

const StyledDropdownMenuItem = styled.div`
  ${props => commonMenuItemStyles(props)}
  padding: ${space.x125} ${space.x1};
  margin-bottom: 1px;
  background-color: ${colors.white};
  top: ${({ top }) => top + 1}px;
  left: 0;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${colors.bg};
        `
      : null};

  :last-of-type {
    margin-bottom: 0;
  }
`;

const StyledSelectMenu = styled.div`
  ${commonMenuStyles()};
  top: ${({ top }) => top}px;
  left: -${space.x1};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  ${({ minWidth }) =>
    minWidth === 'auto'
      ? css`
          min-width: auto;
        `
      : css`
          min-width: ${minWidth}px;
        `}
`;

const StyledDropdownMenu = styled.div`
  ${commonMenuStyles()};
  position: absolute;
  top: ${({ top }) => top + 1}px;
  left: 0;
  right: 0;
  border: 1px solid ${colors.greyLight};
  background: ${colors.greyLight};
  border-radius: ${border.radius};
  z-index: 1;
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`;

const getMenuPortalStyles = ({ top, left, minWidth }) => css`
  position: fixed;
  top: ${top}px;
  left: ${left}px;
  min-width: ${minWidth ? `${minWidth}px` : 'auto'};
  z-index: 9999;
`;

const StyledMenuPortal = styled.div`
  ${props => getMenuPortalStyles(props)}
`;

export {
  StyledSelectMenu,
  StyledSelectMenuItem,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledMenuPortal
};
