import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const Google = props => (
  <BaseIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.44 16.318c0-.993-.09-1.948-.255-2.864H16v5.415h7.535a6.44 6.44 0 01-2.794 4.226v3.513h4.524c2.648-2.438 4.175-6.027 4.175-10.29z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30c3.78 0 6.949-1.253 9.265-3.391l-4.524-3.513c-1.254.84-2.858 1.336-4.741 1.336-3.647 0-6.733-2.462-7.834-5.771H3.489v3.627C5.792 26.863 10.527 30 15.999 30z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.166 18.66A8.415 8.415 0 017.727 16c0-.922.16-1.82.44-2.66V9.713H3.488A13.995 13.995 0 002 16c0 2.26.54 4.398 1.49 6.288l4.676-3.628z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.568c2.055 0 3.9.707 5.351 2.094l4.016-4.016C22.942 3.387 19.773 2 16 2 10.527 2 5.792 5.137 3.489 9.713l4.677 3.627c1.1-3.31 4.187-5.772 7.834-5.772z"
      fill="#EA4335"
    />
  </BaseIcon>
);

export default Google;
