import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { trans } from '@spotahome/soyuz/client';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import userClient from '@spotahome/auth-common/src/api/user-client';

import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';

import {
  Container,
  Title,
  Form
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';
import TransitionContainer from '@spotahome/auth-common/src/components/TransitionContainer';

import useForm from '@spotahome/auth-common/src/hooks/useForm';
import { redirectToLogin } from '@spotahome/auth-common/src/lib/url-utils';

import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import {
  trackInvalidResetPasswordTokenUsed,
  trackResetPasswordErrorSubmit
} from '@spotahome/auth-common/src/tracking/login';

import { useParams } from 'react-router';

import PasswordUpdated from '../PasswordUpdated';

import * as S from './styles';

const REDIRECT_TIMEOUT = 3000;

const INITIAL_FORM_VALUES = {
  newPassword: '',
  confirmPassword: ''
};

const FORM_SCHEMA = Yup.object({
  newPassword: Yup.string()
    .min(6, trans('sahc.forget-password.landlord.password.invalid'))
    .required(trans('landlord.signup.create-account.password.error.required')),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      trans('sahc.login.label.confirm.password.invalid')
    )
    .required('landlord.signup.create-account.password.error.required')
});

const SetPasswordForm = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    INITIAL_FORM_VALUES,
    FORM_SCHEMA
  );

  const params = useParams();
  const { passwordId } = params;

  const handleSubmitFormError = error => {
    const errorMessage = trans(getErrorTransKey(error));
    setSubmitErrorMessage(errorMessage);
    trackResetPasswordErrorSubmit({ error });
  };

  useEffect(() => {
    const checkPasswordId = async () => {
      try {
        const res = await userClient.validatePasswordId(passwordId);
        const { isUsed } = res.data;
        setIsPasswordUpdated(isUsed);
        if (isUsed) {
          redirectToLogin(REDIRECT_TIMEOUT);
        }
      } catch (error) {
        trackInvalidResetPasswordTokenUsed({ error });
      }
    };

    if (passwordId) {
      checkPasswordId();
    }
  }, [passwordId]);

  const onSubmit = async ({ newPassword }, { setIsSubmitting }) => {
    setSubmitErrorMessage('');

    try {
      await userClient.setNewPassword(passwordId, { password: newPassword });
      setIsPasswordUpdated(true);
      redirectToLogin(REDIRECT_TIMEOUT);
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.RelativeContainer>
      <TransitionContainer show={isPasswordUpdated}>
        <PasswordUpdated />
      </TransitionContainer>
      <TransitionContainer show={!isPasswordUpdated} animateOnLoad={false}>
        <Container>
          <Title>{trans('landlord.set-password.heading')}</Title>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <S.Description>
              {trans('landlord.set-password.description')}
            </S.Description>
            <InputGroup
              id="newPassword"
              label={trans('landlord.set-password.new-password.label')}
              error={trans(errors.newPassword)}
            >
              <Input
                id="newPassword"
                name="newPassword"
                data-test="new-password"
                value={values.newPassword}
                onChange={handleChange}
                hasError={errors.newPassword}
                type="password"
                required
              />
            </InputGroup>
            <InputGroup
              id="confirmPassword"
              label={trans('landlord.set-password.confirm-password.label')}
              error={trans(errors.confirmPassword)}
            >
              <Input
                id="confirmPassword"
                name="confirmPassword"
                data-test="confirm-password"
                value={values.confirmPassword}
                onChange={handleChange}
                hasError={errors.confirmPassword}
                type="password"
                required
              />
            </InputGroup>
            {!isSubmitting && submitErrorMessage && (
              <S.StyledInputError>{submitErrorMessage}</S.StyledInputError>
            )}
            <S.SubmitBtn
              type="submit"
              loading={isSubmitting || isPasswordUpdated}
              data-test="update-password-button"
            >
              {trans('landlord.set-password.submit')}
            </S.SubmitBtn>
            <S.ReturnToLoginLink to={getRoutePath('landlord.login')}>
              {trans('sahc.login.label.back_to_login')}
            </S.ReturnToLoginLink>
          </Form>
        </Container>
      </TransitionContainer>
    </S.RelativeContainer>
  );
};

export default SetPasswordForm;
