const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <text
        fill="#880068"
        fontFamily="Gotham-Book, Gotham"
        fontSize="24"
        fontWeight="300"
      >
        <tspan x="12.609" y="24">
          i
        </tspan>
      </text>
      <circle cx="16" cy="16" r="16" stroke="#880068" />
    </g>
  </svg>
);

export default WarningIcon;
