import styled from '@emotion/styled';
import { Link } from 'react-router';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';

import InputError from '@spotahome/auth-common/src/components/InputError';
import {
  formContainerStyles,
  SubmitButton,
  Container
} from '@spotahome/auth-common/src/components/Form';

export const RelativeContainer = styled(Container)`
  position: relative;
`;

export const DescriptionContainer = styled.div`
  ${formContainerStyles()}
`;

export const Description = styled.p`
  font-size: ${fonts.sizes.s};
  line-height: ${pixelsToRem(22)};
  margin: 0 0 ${space.x3};
`;

export const SubmitBtn = styled(SubmitButton)`
  display: block;
`;

export const ReturnToLoginLink = styled(Link)`
  display: inline-block;
  font-size: ${fonts.sizes.s};
  color: ${colors.primary};
  margin: ${space.x3} 0 0;
`;

export const BackToLoginBtn = styled(SubmitButton)`
  margin-top: 0;
`;

export const StyledInputError = styled(InputError)`
  font-size: ${fonts.sizes.m};
`;
