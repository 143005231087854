import { enUS, de, es, fr, it, pt } from 'date-fns/locale';

const localeModuleMap = {
  en: enUS,
  de,
  fr,
  es,
  it,
  pt
};

/**
 * Retrieves the date-fns locale module based on the provided locale.
 *
 * @param {string} locale - The locale identifier (e.g., 'en', 'es', 'fr').
 * @returns {Object} The locale module corresponding to the provided locale with fallback to enUS.
 */
export function getDateFnsLocaleModule(locale) {
  return localeModuleMap[locale] ?? enUS;
}
