import styled from '@emotion/styled';
import { css } from '@emotion/react';

import colors from '../../shared/colors';
import fonts from '../../shared/fonts';
import { space, border } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';
import ArrowRight from '../../icons/ArrowRight';

export const Container = styled.a`
  display: inline-flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: calc(${border.radius} * 2);
  box-shadow: 0 2px 4px 0 rgba(0, 42, 77, 0.05);
  text-decoration: none;
  padding: ${space.x2};
  width: 100%;

  ${mediaQueries.sm(css`
    flex-direction: initial;
    align-items: center;
    padding: ${space.x3} ${space.x4};
  `)};

  ${({ fullwidth }) =>
    !fullwidth &&
    mediaQueries.sm(css`
      width: initial;
    `)}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 32px;
  height: 32px;
  margin-bottom: ${space.x2};

  ${mediaQueries.sm(css`
    max-width: 44px;
    height: 44px;
    margin-bottom: 0;
    margin-right: ${space.x3};
  `)}

  svg {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-family: ${fonts.GothamBold};
  font-size: ${fonts.sizes.l};
  color: ${colors.primary};
  margin: 0;
`;

export const RightArrow = styled(ArrowRight)`
  margin-left: ${space.x1};
`;

export const TextContainer = styled.div`
  font-size: ${fonts.sizes.s};
  color: ${colors.blackZendesk};
  padding-top: ${space.x1};
`;
