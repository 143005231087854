import { useState } from 'react';
import * as Yup from 'yup';
import { trans, withLocales } from '@spotahome/soyuz/client';

import PropTypes from 'prop-types';

import { localesShape } from '@spotahome/soyuz/shapes';

import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import userClient from '@spotahome/auth-common/src/api/user-client';
import TransitionContainer from '@spotahome/auth-common/src/components/TransitionContainer';

import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';

import {
  Container,
  Title,
  Form
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';

import useForm from '@spotahome/auth-common/src/hooks/useForm';

import {
  trackForgotPasswordEmailErrorThrown,
  trackForgotPasswordEmailSubmit
} from '@spotahome/auth-common/src/tracking/login';

import ForgotPasswordEmailSent from './components/ForgotPasswordEmailSent';

import * as S from './styles';

const INITIAL_FORM_VALUES = {
  email: ''
};

const FORM_SCHEMA = Yup.object({
  email: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}$/,
      trans('landlord.signup.create-account.email.error.format')
    )
    .required(trans('landlord.signup.create-account.email.error.required'))
});

const ForgotPasswordForm = ({ locales }) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    INITIAL_FORM_VALUES,
    FORM_SCHEMA
  );

  const handleSubmitFormError = error => {
    const errorMessage = trans(getErrorTransKey(error));
    setSubmitErrorMessage(errorMessage);
    trackForgotPasswordEmailErrorThrown({ error });
  };

  const onSubmit = async ({ email }, { setIsSubmitting }) => {
    trackForgotPasswordEmailSubmit({ email });
    setSubmitErrorMessage('');

    try {
      const req = {
        email,
        locale: locales.current
      };

      await userClient.requestNewPassword(req);
      setIsEmailSubmitted(true);
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.RelativeContainer>
      <TransitionContainer show={isEmailSubmitted}>
        <ForgotPasswordEmailSent email={values.email} />
      </TransitionContainer>
      <TransitionContainer show={!isEmailSubmitted} animateOnLoad={false}>
        <Container>
          <Title data-cy="forgot-password-heading">
            {trans('sahc.forget-password.landlord.page.heading')}
          </Title>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <S.Description>
              {trans('sahc.forget-password.landlord.page.text')}
            </S.Description>
            <InputGroup
              id="email"
              label={trans('landlord.signup.create-account.email.label')}
              error={trans(errors.email)}
            >
              <Input
                data-cy="forgot-pwd-email"
                id="email"
                name="email"
                placeholder={trans('landlord.login.email.placeholder')}
                value={values.email}
                onChange={handleChange}
                hasError={errors.email}
                type="email"
                required
              />
            </InputGroup>
            {!isSubmitting && submitErrorMessage && (
              <S.StyledInputError data-cy="forgot-password-error">
                {submitErrorMessage}
              </S.StyledInputError>
            )}
            <S.SubmitBtn
              data-cy="forgot-pwd-button"
              type="submit"
              loading={isSubmitting || isEmailSubmitted}
            >
              {trans('landlord.login.forgot-password.submit')}
            </S.SubmitBtn>
            <S.ReturnToLoginLink
              data-cy="forgot-password-back-login"
              to={getRoutePath('landlord.login')}
            >
              {trans('sahc.login.label.back_to_login')}
            </S.ReturnToLoginLink>
          </Form>
        </Container>
      </TransitionContainer>
    </S.RelativeContainer>
  );
};

ForgotPasswordForm.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired
};

export default withLocales()(ForgotPasswordForm);
