import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';

import ArrowRight from '../../components/icons/ArrowRight';

export const Link = styled.a`
  display: inline-block;
  color: ${colors.primary};
  cursor: pointer;
  height: 32px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

// text baseline adjustment
export const Text = styled.span`
  margin-bottom: -2.5px;
`;

/**
 * TODO: Add a ArrowLeft component to the ui-library
 */
export const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  margin-right: ${space.x1};
`;
