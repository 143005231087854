import { createElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router';
import { trans } from '@spotahome/soyuz/client';
import { Global } from '@emotion/react';

import { globalStyles } from '@spotahome/landlord-panel-ui-library/src/shared/styles';

import { getPath } from '../routes/auth';

import Layout from './Layout';
import LayoutTransition from './LayoutTransition';
import * as S from './styles';

export const setRouteLocale = locale => {
  const routePath = window.location.pathname.match(/(?!\/\w{2}\/)(\/.*)/)[1];
  if (locale !== 'en') {
    return `/${locale}${routePath || ''}`;
  }
  return routePath;
};

export const getRoutePath = id => getPath(id);

export const navigateToRoute = id => {
  return <Navigate to={getRoutePath(id)} />;
};

const handleBackButtonClick = (navigate, location, routeId) => {
  const queryParams = queryString.parse(location.search);
  queryParams.b = true;
  const routePath = `${getRoutePath(routeId)}?${queryString.stringify(
    queryParams
  )}`;

  navigate(routePath);
};

const RouteContent = ({ component, backRoute }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LayoutTransition show>
      {backRoute ? (
        <S.StyledBackButton
          onClick={() => handleBackButtonClick(navigate, location, backRoute)}
          text={trans('sahc.sign-up.layout.back')}
        />
      ) : (
        <S.BackButtonSpace />
      )}
      {createElement(component, {})}
    </LayoutTransition>
  );
};

const NotFoundRedirect = () => {
  useEffect(() => {
    const [, localeRoute] = window.location.pathname.match(/(\/\w{2})?(\/.*)/);
    window.location.pathname = `${localeRoute || ''}/not-found`;
  }, []);

  return null;
};

const Router = ({ routes }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Layout>
        <Layout.SidePanel />
        <Layout.ContentPanel>
          <Routes>
            {routes.map(route => {
              const { path, component, ...rest } = route;

              return (
                <Route
                  path={path}
                  key={path}
                  element={<RouteContent component={component} {...rest} />}
                />
              );
            })}
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        </Layout.ContentPanel>
      </Layout>
    </>
  );
};

Router.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired
};

export default Router;
