import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import Checkbox from '@spotahome/landlord-panel-ui-library/src/components/Checkbox';

const MarketingOptInCheck = ({ onChange }) => (
  <Checkbox
    id="marketing-opt-in"
    onChange={onChange}
    labelProps={{ style: { minHeight: 'auto' } }}
  >
    {trans('signup.is-subscribed')}
  </Checkbox>
);

MarketingOptInCheck.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default MarketingOptInCheck;
