import { styled } from '@mui/material/styles';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogActions,
  Box
} from '@mui/material';

export const StyledDialog = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': {
    width: '500px',
    maxWidth: '100%',
    borderRadius: '16px',
    textAlign: 'center'
  }
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  borderBottom: '1px solid var(--sah-medium-light)',
  '&:first-child': {
    padding: '64px 32px 32px'
  }
}));

export const StyledCloseButton = styled(Box)(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px'
}));

export const StyledAvatar = styled(Box)(() => ({
  margin: '0 auto'
}));

export const StyledTitle = styled(Box)(() => ({
  maxWidth: '295px',
  margin: '32px auto 16px'
}));

export const StyledSubtitle = styled(Box)(() => ({
  marginBottom: '32px'
}));

export const StyledLabel = styled(Box)(() => ({
  marginBottom: '16px'
}));

export const StyledInputWrapper = styled(Box)(() => ({
  marginBottom: '16px'
}));

export const StyledCheckboxWrapper = styled(Box)(() => ({
  display: 'inline-block'
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '16px 16px 32px',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '12px',
    paddingRight: '12px'
  }
}));

export const StyledButtonWrapper = styled(Box)(({ theme }) => ({
  flex: '1 0 auto',
  padding: '0 8px',
  [theme.breakpoints.up('md')]: {
    padding: '0 12px'
  },
  '&:not(:first-child)': {
    margin: 0
  }
}));
