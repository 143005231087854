import styled from '@emotion/styled';

import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

const InputHint = styled.div`
  margin-bottom: ${space.x1};
  font-size: ${fonts.sizes.xs};
  line-height: ${fonts.lineHeights.regular};
  color: ${({ hasError }) => (hasError ? colors.error : colors.grey)};
`;

export default InputHint;
