import { useEffect } from 'react';
import { trans } from '@spotahome/soyuz/client';

import { Link } from 'react-router';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';

import {
  trackUserIsTenant,
  trackUserIsLandlord
} from '@spotahome/auth-common/src/tracking/signup';

import {
  trackIdentifyUser,
  trackPageChanged
} from '@spotahome/auth-common/src/tracking/common';

import SearchHome from '@spotahome/auth-common/src/components/icons/SearchHome';
import HomeKey from '@spotahome/auth-common/src/components/icons/HomeKey';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import * as S from './styles';

const GetStartedStep = () => {
  useEffect(() => {
    trackIdentifyUser();
    trackPageChanged();
  }, []);

  return (
    <S.Container>
      <S.TopContainer>
        <S.TempTitle>{trans('landlord.signup.get-started.title')}</S.TempTitle>
        {trans('landlord.signup.get-started.which-describes-you')}
      </S.TopContainer>
      <S.CtaCardsWrapper>
        <S.CustomCardCTA
          onClick={trackUserIsTenant}
          data-cy="create-tenant-account-button"
          url="https://www.spotahome.com/"
          icon={<SearchHome color={colors.primary} />}
          title={trans('landlord.signup.get-started.tenant-cta')}
        />
        <S.CustomCardCTA
          onClick={trackUserIsLandlord}
          data-cy="create-landlord-account-button"
          as={Link}
          to={getRoutePath('landlord.signup.new-account')}
          icon={<HomeKey color={colors.primary} />}
          title={trans('landlord.signup.get-started.landlord-cta')}
        />
      </S.CtaCardsWrapper>
    </S.Container>
  );
};

export default GetStartedStep;
