import {
  parse as parseQueryString,
  stringify as stringifyQueryString
} from 'query-string';

function redirectToLogin(delay) {
  const redirect = () => {
    window.location = '/api/logout';
  };

  if (delay) {
    setTimeout(redirect, delay);
  } else {
    redirect();
  }
}

const getPrefixForLocale = (locales, currentLocale) => {
  if (!locales) return '';

  let localePath = locales.routes[currentLocale].path;
  if (localePath.endsWith('/')) {
    localePath = localePath.substring(0, localePath.length - 1);
  }

  return localePath;
};

export {
  redirectToLogin,
  parseQueryString,
  stringifyQueryString,
  getPrefixForLocale
};
