import Tracking from '@spotahome/soyuz-tracking';

export const trackIdentifyUser = ({ user } = { user: undefined }) => {
  Tracking.segment.identifyUser(user);
};

export const trackPageChanged = () => {
  Tracking.segment.trackPageChanged();
};

export const addDataLayerGAEvent = ({
  eventCategory,
  eventAction,
  eventLabel
}) => {
  if (typeof dataLayer !== 'undefined') {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'eventga',
      eventAction,
      eventCategory,
      eventLabel
    });
  }
};
