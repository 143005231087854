import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';

import {
  formContainerStyles,
  SubmitButton
} from '@spotahome/auth-common/src/components/Form';

export const DescriptionContainer = styled.div`
  ${formContainerStyles()}
`;

export const Description = styled.p`
  font-size: ${fonts.sizes.s};
  line-height: ${pixelsToRem(22)};
  margin: 0 0 ${space.x3};
`;

export const BackToLoginBtn = styled(SubmitButton)`
  margin-top: 0;
`;
