import PropTypes from 'prop-types';

import * as S from './styles';

function InputLabel({
  children = null,
  description = null,
  labelTextProps = {},
  descriptionProps = {},
  ...restProps
}) {
  return (
    (children || description) && (
      <S.Label {...restProps}>
        {children && <S.LabelText {...labelTextProps}>{children}</S.LabelText>}
        {description && (
          <S.Description {...descriptionProps}>{description}</S.Description>
        )}
      </S.Label>
    )
  );
}

InputLabel.propTypes = {
  /** The content of the label. */
  children: PropTypes.node,
  /** The description of the field. Is less prominent than the label. */
  description: PropTypes.node,
  /** Props to be spread in the label text element. */
  labelTextProps: PropTypes.object,
  /** Props to be spread in the description element. */
  descriptionProps: PropTypes.object
};

export default InputLabel;
