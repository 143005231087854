import PropTypes from 'prop-types';

import { StyledFormError } from './styles';

const FormError = ({ error = '' }) => {
  if (!error) {
    return null;
  }

  return <StyledFormError data-test="form-error">{error}</StyledFormError>;
};

FormError.propTypes = {
  error: PropTypes.string
};

export default FormError;
