import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const toNumberOfProperties = accountType => {
  const ACCOUNT_TYPE_PRIVATE = 'private';
  const ACCOUNT_TYPE_PROPERTY_MANAGER = 'property_manager';

  switch (accountType) {
    case ACCOUNT_TYPE_PRIVATE:
      return '1-3';
    case ACCOUNT_TYPE_PROPERTY_MANAGER:
      return '4+';
    default:
      return '';
  }
};

export const phoneIsValid = phone => {
  const phoneNumber = parsePhoneNumberFromString(phone);
  return phoneNumber && phoneNumber.isValid();
};

export const mapDataforTracking = (
  { accountId, firstName, lastName, email, phone, type, cityId, otherCity },
  locales
) => {
  return {
    locale: locales.current,
    user: {
      id: accountId,
      firstName,
      lastName,
      email,
      phone,
      landlord_type: type
    },
    type,
    numberOfProperties: toNumberOfProperties(type),
    cityId,
    desiredCity: otherCity,
    accountId
  };
};

export default { phoneIsValid, mapDataforTracking };
