import {
  MODIFY_PICTURES,
  LISTING_MODIFICATION,
  UNPUBLISH_REQUEST,
  ACTIVATE_INSTANT_BOOKING,
  BOOKING_STATUS,
  TENANT_COMPLAINT,
  LANDLORD_DATES_MODIFICATION,
  LANDLORD_BC_CANCELLATION,
  PAYMENT_MISSING,
  LANDLORD_REQUEST_INVOICE,
  CLAIM_GUARANTEE,
  LL_PANEL_ACCESS,
  LL_PANEL_BUG,
  LL_PANEL_DOUBTS,
  COMMERCIAL_ADVICE,
  LANDLORD_ACCOUNT_ACCESS,
  LANDLORD_CHANGE_CONTACT_DETAILS,
  LANDLORD_DELETE_ACCOUNT,
  PUBLISH_PROPERTIES,
  UPDATE_AVAILABILITY,
  SETUP_LL_POLICIES
} from './landlordReasonsConstants';

import {
  CITY,
  REFERENCE_CODE,
  MOVE_IN_DATE,
  COMMENT,
  RENTABLE_UNIT_ID,
  NUMBER_PROPERTIES,
  PHONE_NUMBER,
  LISTING_MODIFICATION_TYPE,
  NEW_CONTACT_DETAILS,
  UNPUBLISH_REASON,
  CANCELLATION_REASON,
  PHONE_NUMBER_OPTIONAL,
  ID_NUMBER,
  FULL_ADDRESS,
  NAME,
  EMAIL
} from './formFields';

const LL_BASIC_FIELDS = [CITY, COMMENT];
const NEW_LL_FIELDS = [CITY, NUMBER_PROPERTIES, PHONE_NUMBER, COMMENT];
const LL_WITH_RUID_FIELDS = [CITY, RENTABLE_UNIT_ID, COMMENT];
const LL_WITH_MOVE_IN_AND_BOOKING_FIELDS = [
  CITY,
  REFERENCE_CODE,
  MOVE_IN_DATE,
  PHONE_NUMBER_OPTIONAL,
  COMMENT
];

export const LANDLORD_REASONS = {
  [MODIFY_PICTURES]: {
    fields: LL_WITH_RUID_FIELDS,
    helpCenterArticles: [
      'articles/360014618900-How-can-I-edit-the-photos-on-my-listing'
    ]
  },
  [LISTING_MODIFICATION]: {
    fields: [CITY, RENTABLE_UNIT_ID, LISTING_MODIFICATION_TYPE, COMMENT],
    helpCenterArticles: [
      'sections/360001611560-Manage-Your-Account-Landlord-Panel'
    ]
  },
  [UNPUBLISH_REQUEST]: {
    fields: [...LL_WITH_RUID_FIELDS, UNPUBLISH_REASON],
    helpCenterArticles: []
  },
  [ACTIVATE_INSTANT_BOOKING]: {
    fields: LL_WITH_RUID_FIELDS,
    helpCenterArticles: ['articles/360005350120-What-is-instant-booking']
  },
  [BOOKING_STATUS]: {
    fields: LL_WITH_MOVE_IN_AND_BOOKING_FIELDS,
    helpCenterArticles: ['']
  },
  [TENANT_COMPLAINT]: {
    fields: LL_WITH_MOVE_IN_AND_BOOKING_FIELDS,
    helpCenterArticles: []
  },
  [LANDLORD_DATES_MODIFICATION]: {
    fields: LL_WITH_MOVE_IN_AND_BOOKING_FIELDS,
    helpCenterArticles: ['articles/360004981699-How-can-I-manage-a-booking']
  },
  [LANDLORD_BC_CANCELLATION]: {
    fields: [...LL_WITH_MOVE_IN_AND_BOOKING_FIELDS, CANCELLATION_REASON],
    helpCenterArticles: ['']
  },
  [PAYMENT_MISSING]: {
    fields: LL_WITH_MOVE_IN_AND_BOOKING_FIELDS,
    helpCenterArticles: [
      'articles/360014619980-When-will-I-receive-the-first-payment-'
    ]
  },
  [LANDLORD_REQUEST_INVOICE]: {
    fields: [...LL_WITH_MOVE_IN_AND_BOOKING_FIELDS, ID_NUMBER, FULL_ADDRESS],
    helpCenterArticles: []
  },
  [CLAIM_GUARANTEE]: {
    fields: LL_WITH_MOVE_IN_AND_BOOKING_FIELDS,
    helpCenterArticles: [
      'articles/360005551019-What-is-the-Spotahome-Landlord-Guarantee'
    ]
  },
  [LL_PANEL_ACCESS]: {
    fields: [NAME, EMAIL, ...LL_BASIC_FIELDS],
    helpCenterArticles: [
      'articles/360014673379-What-should-I-do-if-I-have-problems-with-my-Landlord-Panel'
    ]
  },
  [LL_PANEL_BUG]: {
    fields: LL_BASIC_FIELDS,
    helpCenterArticles: [
      'articles/360014673379-What-should-I-do-if-I-have-problems-with-my-Landlord-Panel'
    ]
  },
  [LL_PANEL_DOUBTS]: {
    fields: [],
    helpCenterArticles: [
      'articles/360005542359-What-can-I-do-in-the-Landlord-Panel-'
    ]
  },
  [COMMERCIAL_ADVICE]: {
    fields: LL_WITH_RUID_FIELDS,
    helpCenterArticles: [
      'articles/360004201720-How-long-will-it-take-to-rent-my-property-through-Spotahome'
    ]
  },
  [LANDLORD_ACCOUNT_ACCESS]: {
    fields: LL_BASIC_FIELDS,
    helpCenterArticles: ['articles/360014669279-How-do-I-create-an-account']
  },
  [LANDLORD_CHANGE_CONTACT_DETAILS]: {
    fields: [...LL_BASIC_FIELDS, NEW_CONTACT_DETAILS],
    helpCenterArticles: []
  },
  [LANDLORD_DELETE_ACCOUNT]: {
    fields: [],
    helpCenterArticles: [
      'articles/360005602660-How-does-Spotahome-use-my-personal-data'
    ]
  },
  [PUBLISH_PROPERTIES]: {
    fields: NEW_LL_FIELDS,
    conditions: {
      [PHONE_NUMBER]: {
        dependsOn: [NUMBER_PROPERTIES],
        compare: numberProperties => numberProperties === '5more'
      }
    },
    helpCenterArticles: [
      'sections/360001361559-Listing-Your-Property',
      'sections/360001334760-Getting-Started',
      'articles/360004152460-Is-Spotahome-s-service-free-for-the-landlord-'
    ]
  },
  [UPDATE_AVAILABILITY]: {
    fields: [],
    helpCenterArticles: [
      'articles/360005489440-How-can-I-change-the-availability-occupancy-of-my-properties-'
    ]
  },
  [SETUP_LL_POLICIES]: {
    fields: LL_WITH_RUID_FIELDS,
    helpCenterArticles: []
  }
};
