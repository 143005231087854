import PropTypes from 'prop-types';

export default PropTypes.shape({
  /** Function to show a toast */
  show: PropTypes.func.isRequired,
  /** Function to remove a toast by its ID */
  remove: PropTypes.func.isRequired,
  /** Function to remove all toasts */
  removeAll: PropTypes.func.isRequired
});
