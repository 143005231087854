import { getRouteConfig, getRoutePath, getRouteUrl } from './utils';

export const routeIds = {
  LOGIN: 'landlord.login',
  FORGOT_PASSWORD: 'landlord.login.forgot-password',
  CANNOT_ACCESS: 'landlord.login.cannot-access',
  SET_PASSWORD: 'landlord.set-password',
  REDEEM_INVITATION: 'landlord.redeem-invitation',
  SIGNUP_GET_STARTED: 'landlord.signup.get-started',
  SIGNUP_NEW_ACCOUNT: 'landlord.signup.new-account',
  SIGNUP_ADDITIONAL_INFO: 'landlord.signup.additional-info'
};

export const routes = [
  {
    id: routeIds.LOGIN,
    path: '/login'
  },
  {
    id: routeIds.FORGOT_PASSWORD,
    path: '/login/forgot-password'
  },
  {
    id: routeIds.CANNOT_ACCESS,
    path: '/login/cannot-access'
  },
  {
    id: routeIds.SET_PASSWORD,
    path: '/set-password/:passwordId'
  },
  {
    id: routeIds.REDEEM_INVITATION,
    path: '/redeem-invitation/:invitationId'
  },
  {
    id: routeIds.SIGNUP_GET_STARTED,
    path: '/signup'
  },
  {
    id: routeIds.SIGNUP_NEW_ACCOUNT,
    path: '/signup/new-account'
  },
  {
    id: routeIds.SIGNUP_ADDITIONAL_INFO,
    path: '/signup/additional-info'
  }
];

export function getRoute(routeId) {
  return getRouteConfig(routes, routeId);
}

export function getPath(routeId) {
  return getRoutePath(routes, routeId);
}

export function getUrl(routeId, params) {
  return getRouteUrl(routes, routeId, params);
}
