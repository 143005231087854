export const DEFAULT_CITY = 'madrid';
export const DEFAULT_ROOMS = '1';
export const MAX_ROOMS = 15;
export const MQ_DESKTOP_MIN_WIDTH = 1101;
export const MQ_TABLET_MAX_WIDTH = MQ_DESKTOP_MIN_WIDTH - 1;
export const MQ_TABLET_MIN_WIDTH = 701;
export const MQ_MOBILE_MAX_WIDTH = MQ_TABLET_MIN_WIDTH - 1;
export const MIN_LENGTH_AUTOCOMPLETER = 1;
export const HOMECARD_IMAGE_SIZES = {
  homecardHidpi: '640w',
  homecard: '320w'
};
export const FEATURED_CITIES = [
  'madrid',
  'berlin',
  'brussels',
  'barcelona',
  'milan'
];

export const TOP_CITIES = {
  madrid: 9341,
  barcelona: 5708,
  rome: 4688,
  milan: 5423,
  lisbon: 3707,
  berlin: 2705,
  valencia: 3967,
  brussels: 2933,
  dublin: 1238,
  london: 3383,
  paris: 1696
};

export default {
  DEFAULT_CITY,
  MQ_DESKTOP_MIN_WIDTH,
  MQ_TABLET_MAX_WIDTH,
  MQ_TABLET_MIN_WIDTH,
  MQ_MOBILE_MAX_WIDTH,
  HOMECARD_IMAGE_SIZES,
  MIN_LENGTH_AUTOCOMPLETER,
  FEATURED_CITIES,
  TOP_CITIES
};

export const SECTION_HEADING_THEME_DARK = 'dark';
export const SECTION_HEADING_TYPE_NONE = 'none';
