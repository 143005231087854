import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ArrowBack } from '@mui/icons-material';

import { Collapsible, Autocompleter } from '@spotahome/ui-library';

import CardBanner from '../../Redesign/CardBanner';

export const StyledButtonsRoot = styled(Box)({
  marginBottom: '12px'
});

export const StyledInfoContainer = styled(CardBanner)({
  marginBottom: '40px'
});

export const StyledTitleContainer = styled(Box)({
  marginTop: '16px',
  marginBottom: '16px'
});

export const StyledBackButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  height: '56px'
});

export const StyledBackButton = styled(ArrowBack)({
  marginRight: '16px',
  cursor: 'pointer',
  fontSize: '3rem'
});

export const StyledBackButtonTitle = styled(Box)({
  fontSize: '17px',
  color: '#3C3C3C',
  '&:hover': {
    cursor: 'pointer'
  }
});

export const StyledAccordion = styled(Collapsible)(({ theme }) => ({
  borderBottom: '1px solid var(--sah-medium-light)',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2)
  },
  backgroundColor: 'white'
}));

export const StyledHeader = styled(Box)({
  padding: '16px 0px',
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: 'var(--sah-font-bold)',
  color: 'black'
});

export const StyledBody = styled(Box)({
  padding: '16px 0px',
  backgroundColor: 'white'
});

export const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  '& li:not(:last-child)': {
    marginBottom: theme.spacing(1)
  }
}));

export const StyledActionLink = styled(Box)({
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '14px',
  marginLeft: '4px',
  marginBottom: '12px',
  '&:hover': {
    color: 'var(--sah-translucid)'
  }
});

// Contact Form Fields
export const StyledContactForm = styled(Box)({
  display: 'block',
  width: '100%',
  position: 'relative'
});

export const StyledDescription = styled(Box)({
  color: '#3C3C3C',
  padding: '12px',
  border: '2px dotted #3C3C3C',
  borderRadius: '4px'
});

export const StyledArticleListHint = styled(Box)({
  display: 'block',
  color: '#3C3C3C',
  textDecoration: 'underline',
  marginTop: '8px',
  wordBreak: 'break-word',
  '&:hover': {
    color: 'var(--sah-translucid)'
  }
});

export const StyledRecaptcha = styled(Box)({
  marginTop: '24px'
});

export const StyledSubmitContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

export const StyledSubmitButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginRight: '16px',
  '&:not(:last-child)': {
    marginBottom: '24px'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    margin: '0',
    marginRight: '16px'
  }
}));

export const StyledSubmitError = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledFormError = styled(Box)({
  display: 'flex',
  marginTop: '4px',
  color: 'var(--sah-danger)'
});

export const StyledAutocompleter = styled(Autocompleter)({
  marginTop: '24px',
  border: '1px solid var(--sah-light)',
  borderRadius: '4px',
  background: 'white',
  '::placeholder': {
    color: 'var(--sah-medium)'
  },
  '& > div': {
    zIndex: '1',
    height: '70px'
  }
});

export const StyledField = styled(Box)({
  marginTop: '24px'
});

export const StyledGdprText = styled(Box)({
  marginTop: '16px',
  marginBottom: '20px',
  paddingLeft: '15px',
  borderLeft: '4px solid var(--sah-danger)',
  lineHeight: '1.5',
  '& p': {
    marginBottom: '10px'
  }
});
