import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap'
});

export const StyledLogo = styled(Box)({
  marginBottom: '45px'
});

export const StyledTextContainer = styled(Box)({
  flex: '1 0 100%',
  textAlign: 'center'
});

export const StyledContent = styled(Box)({
  maxWidth: '560px',
  margin: '0 auto 32px'
});

export const StyledTitle = styled(Box)({
  marginBottom: '24px'
});
