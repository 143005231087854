import moment from 'moment';
import {
  differenceInMonths,
  differenceInDays,
  differenceInYears,
  differenceInHours,
  addDays as dfAddDays,
  subMonths as dfSubMonths,
  format as dfFormat,
  formatDistanceToNow,
  compareAsc,
  isBefore,
  isAfter,
  isSameDay as dfIsSameDay,
  getDate,
  getYear,
  getMonth,
  startOfDay,
  setDefaultOptions
} from 'date-fns';

import { getDateFnsLocaleModule } from './locales';

// TYPES

/**
 * Represents the inclusivity options, from|to edges,
 * for date range comparing operations, like isBetweenX functions
 *
 * @typedef {Object} Inclusivity
 * @property {boolean} from
 * @property {boolean} to
 */

// CONSTANTS
const monthKeys = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const defaultDateFormat = 'yyyy-MM-dd';
export const displayDateFormat = 'dd-MM-yyyy';

/**
 * Set default locale to be used on dates format
 *
 * @param {string} locale
 */
export function setDefaultLocale(locale) {
  moment.locale(locale);
  setDefaultOptions({ locale: getDateFnsLocaleModule(locale) });
}

// DATE FUNCTIONS

/**
 * Difference of months between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function monthDiff(from, to) {
  return differenceInMonths(from, to);
}

/**
 * Difference of days between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function dayDiff(from, to) {
  return differenceInDays(from, to);
}

/**
 * Difference of years between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */
export function yearDiff(from, to) {
  return differenceInYears(from, to);
}

/** Difference of hours between 2 dates
 *
 * @param from
 * @param to
 * @returns {number}
 */

export function hoursDiff(from, to) {
  return differenceInHours(from, to);
}

/**
 * Checks if a date is between from|to dates
 *
 * @param {Date} date
 * @param {Date} from
 * @param {Date} to
 * @param {Inclusivity} incl
 * @returns {boolean}
 */
export function isBetweenDays(date, from, to) {
  const dDate = startOfDay(date);
  const dFrom = startOfDay(from);
  const dTo = startOfDay(to);

  return compareAsc(dDate, dFrom) > 0 && compareAsc(dDate, dTo) < 0;
}

/**
 * Checks if a date is between from|to (both inclusive) dates
 *
 * @param date
 * @param from
 * @param to
 * @returns {boolean}
 */
export function isBetweenDaysInclusive(date, from, to) {
  const dDate = startOfDay(date);
  const dFrom = startOfDay(from);
  const dTo = startOfDay(to);

  return compareAsc(dDate, dFrom) >= 0 && compareAsc(dDate, dTo) <= 0;
}

/**
 * Checks if a date is between the months of from|to dates
 *
 * @param {Date} date
 * @param {Date} from
 * @param {Date} to
 * @param {Inclusivity} incl
 * @returns {boolean}
 */
export function isBetweenMonths(date, from, to) {
  const dateMonthIndex = getYear(date) * 12 + getMonth(date);
  const fromMonthIndex = getYear(from) * 12 + getMonth(from);
  const toMonthIndex = getYear(to) * 12 + getMonth(to);

  const minVal = Math.min(fromMonthIndex, toMonthIndex);
  const maxVal = Math.max(fromMonthIndex, toMonthIndex);

  return dateMonthIndex > minVal && dateMonthIndex < maxVal;
}

/**
 * Returns a date with the decrement in terms of months
 *
 * @param {Date} date
 * @param {number} decrement - a positive|negative number of months
 * @returns {Date}
 */
export function substractMonths(date, decrement) {
  return dfSubMonths(date, decrement);
}

/**
 * Returns a date with the increment in terms of days
 *
 * @param {Date} date
 * @param {number} increment - a positive|negative number of days
 * @returns {Date}
 */
export function addDays(date, increment) {
  return dfAddDays(date, increment);
}

/**
 * Returns the key representation of the month of the date (for example: "2018-01-01" -> 'jan')
 *
 * @param date
 * @returns {string}
 */
export function monthKey(date) {
  return monthKeys[date.getMonth()];
}

/**
 * Returns the day of a date (for example: "2019-04-01" -> 1)
 *
 * @param date
 * @returns {string}
 */
export function getDay(date) {
  return getDate(date);
}

/**
 * Returns a localized representation for a given month (for example "Jan" -> "Ene" if our locale is ES)
 *
 * @param {string} monthName
 * @returns {string}
 */
export function localizedMonthName(monthName) {
  const index = monthKeys.indexOf(monthName.toLowerCase());

  if (index === -1) {
    return monthName;
  }

  const tempDate = new Date(2000, index, 1);

  return dfFormat(tempDate, 'MMM');
}

/**
 * Returns a localized formatted string of the date
 * It uses Unicode tokens to define the format.
 * @see https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 *
 * @param date
 * @param formatString - the output string format, see docs:
 * @see https://date-fns.org/v4.1.0/docs/format
 * @see https://date-fns.org/v4.1.0/docs/Unicode-Tokens
 * @returns {string}
 */
export function format(date, formatString = defaultDateFormat) {
  return dfFormat(date, formatString);
}

/**
 * Returns a date formatted into string with the general display format
 *
 * @param date
 * @returns {string}
 */

export function dateToString(date) {
  return dfFormat(date, displayDateFormat);
}

/**
 *  Checks if a date happened before (or in the same day) a reference date (default today) in the timeunit established (default days)
 *
 * @param {initialDate} date
 * @param {referenceDate} date
 * @returns {boolean}
 */

export function isDaySameOrBefore(initialDate, referenceDate = new Date()) {
  if (dfIsSameDay(initialDate, referenceDate)) {
    return true;
  }

  return isBefore(initialDate, referenceDate);
}

export function rangesOverlap(start1, end1, start2, end2) {
  return !isBefore(end1, start2) && !isAfter(start1, end2);
}
