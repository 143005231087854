import { generatePath } from 'react-router';

export const getRouteConfig = (routes = [], routeId) => {
  const route = routes.find(r => r.id === routeId);

  if (!route) {
    throw new Error(`The route with id "${routeId}" was not found in routes.`);
  }

  return route;
};

export const getRoutePath = (routes = [], routeId) => {
  const route = getRouteConfig(routes, routeId);

  if (!route.path) {
    throw new Error(`The route with id "${routeId}" has no path defined.`);
  }

  return route.path;
};

export const getRouteUrl = (routes = [], pathId, params = {}) => {
  const path = getRoutePath(routes, pathId);

  return generatePath(path, params);
};
