import styled from '@emotion/styled';

import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

export const Container = styled.div`
  max-width: ${pixelsToRem(304)};
  height: 100%;
`;

export const Content = styled.div``;

export const Header = styled.div`
  text-transform: uppercase;
  letter-spacing: ${pixelsToRem(2)};
  font-size: ${fonts.sizes.xs};

  b {
    font-family: ${fonts.GothamBold};
  }
`;

export const Title = styled.h1`
  font-family: ${fonts.GothamBold};
  margin: ${space.x1} 0 ${space.x8};
`;

export const FeatureBoxListWrapper = styled.span`
  font-size: ${fonts.sizes.s};
  font-family: ${fonts.GothamMedium};
`;
