/**
 * Select mode
 */
export const modeTypes = {
  SELECT: 'select',
  DROPDOWN: 'dropdown',
  NATIVE: 'native'
};
export const modeTypesArray = Object.keys(modeTypes).map(key => modeTypes[key]);

/**
 * Select touch mode
 */
export const touchModeTypes = {
  NATIVE: 'native',
  INHERITED: 'inherited' // means to inherited the value from 'modes'
};
export const touchModeTypesArray = Object.keys(touchModeTypes).map(
  key => touchModeTypes[key]
);
