import { useEffect } from 'react';
import PropTypes from 'prop-types';

import useModalHandle from '../../hooks/useModalHandle';
import { getCookie, deleteCookie } from '../../utils/cookie';

import { ConfirmDialog } from '../Dialog';

import OptInIcon from './OptInIcon';

import * as S from './styles';

const COOKIE_SIGNED_UP_UP_FROM_SOCIAL = 'sah-ui-social';

const MarketingOptInModal = ({
  modalBodytext,
  buttonAcceptText,
  buttonRejectText,
  onOpen = () => {},
  onReject = () => {},
  onAccept = () => {}
}) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();

  const handleClose = () => {
    deleteCookie(COOKIE_SIGNED_UP_UP_FROM_SOCIAL);
    handleModalClose();
  };

  const handleOnAccept = () => {
    onAccept();
    handleClose();
  };

  const handleOnReject = () => {
    onReject();
    handleClose();
  };

  useEffect(() => {
    if (getCookie(COOKIE_SIGNED_UP_UP_FROM_SOCIAL) && !isModalOpen) {
      handleModalOpen();
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmDialog
      title=""
      isOpen={isModalOpen}
      text={
        <S.ModalBody>
          <OptInIcon />
          <S.ModalText>{modalBodytext}</S.ModalText>
        </S.ModalBody>
      }
      acceptButtonText={buttonAcceptText}
      cancelButtonText={buttonRejectText}
      onAccept={handleOnAccept}
      onCancel={handleOnReject}
      onRequestClose={handleOnReject}
    />
  );
};

MarketingOptInModal.propTypes = {
  onOpen: PropTypes.func,
  onReject: PropTypes.func,
  onAccept: PropTypes.func,
  modalBodytext: PropTypes.string.isRequired,
  buttonAcceptText: PropTypes.string.isRequired,
  buttonRejectText: PropTypes.string.isRequired
};

export default MarketingOptInModal;
