import styled from '@emotion/styled';
import { css } from '@emotion/react';

const commonStyles = () => css`
  position: relative;
  outline: 0;
`;

const StyledSelectContainer = styled.div`
  ${commonStyles()}
  display: inline-block;
`;

const StyledDropdownContainer = styled.div`
  ${commonStyles()}
`;

export { StyledSelectContainer, StyledDropdownContainer };
