import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const TransitionContainer = ({
  show = true,
  children,
  animateOnLoad = true,
  ...rest
}) => {
  const [shouldRender, setShouldRender] = useState(show);
  const [shouldAnimateOnLoad, setShouldAnimateOnLoad] = useState(animateOnLoad);

  useEffect(() => {
    if (show) {
      setShouldRender(true);
    } else if (!shouldAnimateOnLoad) {
      setShouldAnimateOnLoad(true);
    }
  }, [show, shouldAnimateOnLoad]);

  const handleAnimationEnd = () => {
    if (!show) setShouldRender(false);
  };

  const getDirection = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('b')) {
      return S.DIRECTION.BACKWARD;
    }
    return S.DIRECTION.FORWARD;
  };

  return (
    shouldRender && (
      <S.TransitionContainer
        show={show}
        direction={getDirection()}
        onAnimationEnd={handleAnimationEnd}
        animateOnLoad={shouldAnimateOnLoad}
        {...rest}
      >
        {children}
      </S.TransitionContainer>
    )
  );
};

TransitionContainer.propTypes = {
  show: PropTypes.bool,
  animateOnLoad: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default TransitionContainer;
