import axios from 'axios';

const API_PREFIX = '/api/fe/default-landlord-pages';

const userClient = {
  login(data) {
    const path = '/api/login';
    return axios.post(path, data);
  },

  requestNewPassword(data) {
    const path = '/api/forgotten-passwords';
    return axios.post(path, data);
  },

  validatePasswordId(passwordId) {
    const path = `/api/forgotten-passwords/${passwordId}`;
    return axios.get(path);
  },

  setNewPassword(forgottenPasswordId, data) {
    const path = `/api/forgotten-passwords/${forgottenPasswordId}/password`;
    return axios.put(path, data);
  },

  createUser(data) {
    const path = `${API_PREFIX}/create-user`;
    return axios.post(path, data);
  },

  redeemInvitation(invitationId, data) {
    const path = `/api/invitations/${invitationId}/redemption`;
    return axios.put(path, data);
  }
};

export default userClient;
