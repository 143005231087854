import styled from '@emotion/styled';
import { Link } from 'react-router';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import InputError from '@spotahome/auth-common/src/components/InputError';
import { formContainerStyles } from '@spotahome/auth-common/src/components/Form';

export const ForgotPasswordLink = styled(Link)`
  display: inline-block;
  width: 50%;
  font-size: ${fonts.sizes.xs};
  color: ${colors.primary};
  &:nth-of-type(2) {
    text-align: right;
  }
`;

export const SignupPrompt = styled.p`
  margin: ${space.x3} 0;

  ${formContainerStyles()}
`;

export const SignupLink = styled.a`
  color: ${colors.primary};
`;

export const StyledInputError = styled(InputError)`
  margin-top: ${space.x2};
  font-size: ${fonts.sizes.m};
`;
