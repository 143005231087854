import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyledDropdownButton, StyledIcon } from './SelectButton.styles';

const DropdownButton = forwardRef(
  ({ caption, isOpen, onClick, ...htmlProps }, ref) => (
    <StyledDropdownButton
      ref={ref}
      isOpen={isOpen}
      role="button"
      tabIndex="0"
      onClick={onClick}
      {...htmlProps}
    >
      <span>{caption}</span>

      <StyledIcon name={isOpen ? 'angleUp' : 'angleDown'} />
    </StyledDropdownButton>
  )
);

DropdownButton.defaultProps = {
  isOpen: false,
  onClick: () => {},
  caption: ''
};

DropdownButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  caption: PropTypes.string
};

export default DropdownButton;
