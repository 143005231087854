import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { mediaQueries } from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';
import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';
import Button from '@spotahome/landlord-panel-ui-library/src/components/Button';

const FORM_WIDTH = '331px';

export const formContainerStyles = () => css`
  ${mediaQueries.sm(css`
    width: ${FORM_WIDTH};
  `)}
`;

export const Container = styled.div``;

export const Title = styled.h1`
  font-family: ${fonts.GothamBold};
  font-size: ${pixelsToRem(32)};
  margin: 0 0 ${space.x3};
`;

export const Form = styled.form`
  ${formContainerStyles()}
`;

export const SubmitButton = styled(Button)`
  margin-top: ${space.x3};
`;
