import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';

import { SPOTAHOME_THEME, ThemeProvider } from '@spotahome/ui-library';
import {
  ToastContainer,
  ToastProvider
} from '@spotahome/landlord-panel-ui-library/src/components/Toast';
import {
  trackIdentifyUser,
  trackPageChanged
} from '@spotahome/auth-common/src/tracking/common';
import getGqlClient from '@spotahome/auth-common/src/graphql/client';

import {
  StateMachineProvider,
  useStateMachineContext
} from '@spotahome/landlord-panel-common/src/components/SupportForms/context/StateMachineContext';
import STATES_CONFIG from '@spotahome/landlord-panel-common/src/components/SupportForms/context/statesConfig';
import { LL_PANEL_ACCESS } from '@spotahome/landlord-panel-common/src/components/SupportForms/constants/landlordReasonsConstants';
import SupportFormSuccess from '@spotahome/landlord-panel-common/src/components/SupportForms/components/SupportFormSuccess/SupportFormSuccess';
import ContactForm from '@spotahome/landlord-panel-common/src/components/SupportForms/components/ContactForm';

const ViewManager = () => {
  const { state } = useStateMachineContext();

  switch (state) {
    case 'show_form':
    default:
      return <ContactForm showBackButton={false} isPublicForm />;
    case 'show_success':
      return <SupportFormSuccess backURL={'/login'} />;
  }
};

const CannotAccess = () => {
  useEffect(() => {
    trackIdentifyUser();
    trackPageChanged();
  }, []);

  return (
    <ThemeProvider theme={SPOTAHOME_THEME}>
      <ApolloProvider client={getGqlClient()}>
        <ToastProvider>
          <StateMachineProvider
            states={STATES_CONFIG}
            initialState={{
              state: 'show_form',
              payload: { reasonId: LL_PANEL_ACCESS }
            }}
          >
            <ViewManager />
            <ToastContainer />
          </StateMachineProvider>
        </ToastProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default CannotAccess;
