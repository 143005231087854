import EmailLogo from '@spotahome/landlord-panel-ui-library/src/icons/EmailLogo';
import Proptypes from 'prop-types';
import RedesignButton from '@spotahome/landlord-panel-ui-library/src/components/RedesignButton/RedesignButton';
import { trans } from '@spotahome/soyuz/client';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import {
  StyledContainer,
  StyledLogo,
  StyledTextContainer,
  StyledContent,
  StyledTitle
} from './styles';

const SupportFormSuccess = ({ backURL = null }) => {
  return (
    <StyledContainer>
      <StyledLogo component={EmailLogo} />
      <StyledTextContainer>
        <StyledContent>
          <StyledTitle variant="DisplayM">
            {trans('landlord.support-forms.success.title')}
          </StyledTitle>
          <Typography variant="ParagraphM">
            {trans('landlord.support-forms.success.text')}
          </Typography>
        </StyledContent>
        <RedesignButton href={backURL || '/support'} size="big">
          <Typography variant="LabelM">
            {trans('sahc.bookings.complaint.back')}
          </Typography>
        </RedesignButton>
      </StyledTextContainer>
    </StyledContainer>
  );
};

SupportFormSuccess.propTypes = {
  backURL: Proptypes.string
};

export default SupportFormSuccess;
