function ProfileSvg({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      {...props}
    >
      <path
        fill="#F4EFED"
        d="M40 42.667c11.782 0 21.333-9.552 21.333-21.334S51.782 0 40 0C28.218 0 18.666 9.551 18.666 21.333c0 11.782 9.552 21.334 21.334 21.334Z"
      />
      <path
        fill="#0A0777"
        d="M2.667 80c0-20.617 16.714-37.333 37.332-37.333 20.618 0 37.334 16.716 37.334 37.333H2.667Z"
      />
    </svg>
  );
}

export default ProfileSvg;
