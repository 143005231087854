import Tracking from '@spotahome/soyuz-tracking';

const SUPPORT_CATEGORY_FORM_BUTTON_CLICKED = 'SupportCategoryFormButtonClicked';
const SUPPORT_QUESTION_FORM_BUTTON_CLICKED = 'SupportQuestionFormButtonClicked';
const SUPPORT_FORM_SUBMIT_ATTEMPT = 'SupportFormSubmitAttempt';
const SUPPORT_FORM_SUBMIT_VALIDATION_ERROR = 'SupportFormValidationError';
const SUPPORT_FORM_SUBMIT_ERROR = 'SupportFormSubmitError';
const SUPPORT_FORM_SUBMIT_SUCCESS = 'SupportFormSubmitSuccess';
const SUPPORT_FORM_CATEGORY_BUTTON_CLICKED = 'SupportFormCategoryButtonClicked';
const SUPPORT_FORM_WHATSAPP_BUTTON_CLICKED = 'SupportFormWhatsappButtonClicked';
const SUPPORT_FORM_PHONE_BUTTON_CLICKED = 'SupportFormPhoneButtonClicked';
const SUPPORT_FORM_FAQ_BUTTON_CLICKED = 'SupportFormFAQButtonClicked';

// eslint-disable-next-line import/prefer-default-export
export const sendSupportCategoryFormButtonClicked = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_CATEGORY_FORM_BUTTON_CLICKED, user, {
    category: id
  });
};

export const sendSupportQuestionFormButtonClicked = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_QUESTION_FORM_BUTTON_CLICKED, user, {
    section: id
  });
};

export const sendSupportFormSubmitAttempt = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_FORM_SUBMIT_ATTEMPT, user, {
    reason: id
  });
};

export const sendSupportFormSubmitValidationError = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_FORM_SUBMIT_VALIDATION_ERROR, user, {
    reason: id
  });
};

export const sendSupportFormSubmitError = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_FORM_SUBMIT_ERROR, user, {
    reason: id
  });
};

export const sendSupportFormSubmitSuccess = (id, user) => {
  Tracking.segment.trackEvent(SUPPORT_FORM_SUBMIT_SUCCESS, user, {
    reason: id
  });
};

export const sendSupportFormCategoryButtonClicked = user => {
  Tracking.segment.trackEvent(SUPPORT_FORM_CATEGORY_BUTTON_CLICKED, user);
};

export const sendSupportFormWhatsappButtonClicked = user => {
  Tracking.segment.trackEvent(SUPPORT_FORM_WHATSAPP_BUTTON_CLICKED, user);
};

export const sendSupportFormPhoneButtonClicked = user => {
  Tracking.segment.trackEvent(SUPPORT_FORM_PHONE_BUTTON_CLICKED, user);
};

export const sendSupportFormFAQButtonClicked = user => {
  Tracking.segment.trackEvent(SUPPORT_FORM_FAQ_BUTTON_CLICKED, user);
};
