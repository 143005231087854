import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const Facebook = props => (
  <BaseIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16c0-7.732-6.268-14-14-14S2 8.268 2 16c0 6.988 5.12 12.78 11.813 13.83v-9.783h-3.555V16h3.555v-3.084c0-3.51 2.09-5.447 5.287-5.447 1.532 0 3.134.273 3.134.273v3.446H20.47c-1.74 0-2.282 1.079-2.282 2.186V16h3.883l-.62 4.047h-3.262v9.783C24.88 28.78 30 22.988 30 16z"
      fill="#1877F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.45 20.047L22.07 16h-3.883v-2.626c0-1.107.543-2.187 2.282-2.187h1.765V7.742S20.632 7.47 19.1 7.47c-3.197 0-5.288 1.938-5.288 5.447V16h-3.554v4.047h3.555v9.783a14.097 14.097 0 004.374 0v-9.783h3.263z"
      fill="#fff"
    />
  </BaseIcon>
);

export default Facebook;
