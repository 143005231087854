import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';

import { useQuery, useMutation } from '@apollo/client';

import * as Yup from 'yup';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { trans, useSoyuzFlag, withLocales } from '@spotahome/soyuz/client';
import { localesShape } from '@spotahome/soyuz/shapes';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import PhoneInput from '@spotahome/landlord-panel-ui-library/src/components/PhoneInput';
import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';
import { NativeSelect } from '@spotahome/landlord-panel-ui-library/src/components/Select';

import { trackUserCreationSuccess } from '@spotahome/auth-common/src/tracking/signup';
import {
  trackIdentifyUser,
  trackPageChanged
} from '@spotahome/auth-common/src/tracking/common';

import {
  trackAccountCreatedSuccess,
  trackAccountCreationClicked
} from '@spotahome/auth-common/src/tracking/signupAdditionalInfo';

import {
  phoneIsValid,
  mapDataforTracking
} from '@spotahome/auth-common/src/lib/utils';

import useForm from '@spotahome/auth-common/src/hooks/useForm';

import LANDLORD_CREATE_ACCOUNT_MUTATION from '@spotahome/auth-common/src/graphql/mutations/landlord';

import {
  Container,
  Title,
  Form,
  SubmitButton
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';
import OptInModalWrapper from '@spotahome/auth-common/src/components/OptInModalWrapper';
import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import ADDITIONAL_INFO_QUERY from './graphql/queries';

import * as S from './styles';

const INITIAL_FORM_VALUES = {
  firstName: '',
  lastName: '',
  type: '',
  phone: '',
  accountId: '',
  email: ''
};

const FORM_SCHEMA = Yup.object({
  firstName: Yup.string()
    .ensure()
    .required(
      trans('landlord.signup.additional-info.first-name.error.required')
    ),
  lastName: Yup.string()
    .ensure()
    .required(
      trans('landlord.signup.additional-info.last-name.error.required')
    ),
  type: Yup.string()
    .ensure()
    .required('landlord.signup.additional-info.type.error.required'),
  phone: Yup.string()
    .test(
      'phone-validation',
      'landlord.signup.additional-info.phone.error.format',
      phoneIsValid
    )
    .required('landlord.signup.additional-info.phone.error.required')
});

const COUNTRIES_MAPPING = {
  GG: 'GB'
};

const AdditionalInfoStep = ({ locales }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(INITIAL_FORM_VALUES);
  const [defaultCountryCode, setDefaultCountryCode] = useState();
  const [accountIsCreated, setAccountIsCreated] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const { loading: additionalInfoLoading, data: additionalInfoData } = useQuery(
    ADDITIONAL_INFO_QUERY,
    {
      variables: {
        input: { userId: location?.state?.userId || '' }
      }
    }
  );

  const [createLandlordAccount] = useMutation(LANDLORD_CREATE_ACCOUNT_MUTATION);

  const showNewOnboardingPageFlag = useSoyuzFlag('ShowNewOnboardingPage');

  const addAListingURL = '/listings/new';
  const basePath = '/';

  const onboardingRedirectUrl = showNewOnboardingPageFlag.isOn()
    ? basePath
    : addAListingURL;

  useEffect(() => {
    trackPageChanged();
  }, []);

  useEffect(() => {
    if (!additionalInfoLoading && !additionalInfoData?.userInfo?.id) {
      navigate(getRoutePath('landlord.signup.get-started'));
    }
  }, [additionalInfoLoading, additionalInfoData, navigate]);

  useEffect(() => {
    const parseUserInfo = async ({ fullname, id, email }) => {
      const fullNameArray = fullname.split(' ');

      setUserInfo(prev => ({
        ...prev,
        accountId: id,
        email,
        firstName: fullNameArray[0] || '',
        lastName: fullNameArray.splice(1).join(' ') || ''
      }));
    };

    if (additionalInfoData) {
      parseUserInfo(additionalInfoData.userInfo);
      trackIdentifyUser({ user: additionalInfoData.userInfo });
      trackUserCreationSuccess({
        signUpMethod: location?.state?.method ?? 'provider',
        id: additionalInfoData.userInfo.id
      });
    }
  }, [additionalInfoData, location?.state?.method]);

  useEffect(() => {
    if (locales.current) {
      const countryCode = locales.current === 'en' ? 'gb' : locales.current;
      setDefaultCountryCode(countryCode);
    }
  }, [locales]);

  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    userInfo,
    FORM_SCHEMA
  );

  const handleNativeSelectChange = (value, name) => {
    handleChange({ target: { value, name } });
  };

  const handleSubmitFormError = error => {
    const errorMessage = trans(getErrorTransKey(error));
    setSubmitErrorMessage(errorMessage);
  };

  const mapCountryCode = countryCode => {
    return COUNTRIES_MAPPING[countryCode.toUpperCase()] ?? countryCode;
  };

  const onSubmit = async (data, { setIsSubmitting }) => {
    const { accountId, firstName, lastName, email, phone, type } = data;

    setSubmitErrorMessage();

    const phoneData = parsePhoneNumberFromString(phone);

    try {
      await createLandlordAccount({
        variables: {
          input: {
            accountId,
            firstName,
            lastName,
            email,
            phone: phoneData.number,
            type,
            countryCode: mapCountryCode(phoneData.country)
          }
        }
      });
      setAccountIsCreated(true);
      trackAccountCreatedSuccess({
        ...mapDataforTracking(data, locales)
      });

      document.location.href = onboardingRedirectUrl;
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccountCreationClick = () => {
    trackAccountCreationClicked({
      ...mapDataforTracking(values, locales)
    });
  };

  const handlePhoneChange = value => {
    handleNativeSelectChange(value, 'phone');
  };

  return (
    <S.RelativeContainer>
      {additionalInfoData && (
        <Container>
          <Title>{trans('landlord.signup.additional-info.heading')}</Title>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <InputGroup
              id="firstName"
              label={trans('landlord.signup.additional-info.first-name.label')}
              error={trans(errors.firstName)}
            >
              <Input
                id="firstName"
                name="firstName"
                data-cy="login-additional-first-name"
                placeholder={trans(
                  'landlord.signup.additional-info.first-name.placeholder'
                )}
                value={values.firstName}
                onChange={handleChange}
                hasError={errors.firstName}
                type="text"
              />
            </InputGroup>
            <InputGroup
              id="lastName"
              label={trans('landlord.signup.additional-info.last-name.label')}
              error={trans(errors.lastName)}
            >
              <Input
                id="lastName"
                name="lastName"
                data-cy="login-additional-last-name"
                placeholder={trans(
                  'landlord.signup.additional-info.last-name.placeholder'
                )}
                value={values.lastName}
                onChange={handleChange}
                hasError={errors.lastName}
                type="text"
              />
            </InputGroup>
            <InputGroup
              id="type"
              label={trans('landlord.signup.additional-info.type.label')}
              error={trans(errors.type)}
            >
              <NativeSelect
                id="type"
                name="type"
                data-cy="property-type-selector"
                onChange={value => handleNativeSelectChange(value, 'type')}
                value={values.type}
                options={[
                  {
                    label: trans(
                      'landlord.signup.additional-info.type.select-option'
                    ),
                    value: ''
                  },
                  {
                    label: trans(
                      'landlord.signup.additional-info.type.option.private'
                    ),
                    value: 'private'
                  },
                  {
                    label: trans(
                      'landlord.signup.additional-info.type.option.property-manager'
                    ),
                    value: 'property_manager'
                  }
                ]}
                isOptionDisabled={option => option.value === ''}
                dropdownButton
              />
            </InputGroup>
            <InputGroup
              id="phone"
              label={trans('landlord.signup.additional-info.phone.label')}
              error={trans(errors.phone)}
            >
              <PhoneInput
                country={defaultCountryCode}
                inputProps={{
                  id: 'phone',
                  name: 'phone',
                  'data-cy': 'phone'
                }}
                disableAreaCodes
                value={values.phone}
                onChange={value => handlePhoneChange(value)}
                hasError={errors.phone}
                onEnterKeyPress={e => (e.target.blur = () => {})}
              />
            </InputGroup>
            {submitErrorMessage && !isSubmitting && (
              <S.StyledInputError>{submitErrorMessage}</S.StyledInputError>
            )}
            <SubmitButton
              type="submit"
              loading={isSubmitting || accountIsCreated}
              onClick={handleAccountCreationClick}
              data-cy="signup-additional-info-get-started"
            >
              {trans('landlord.signup.additional-info.submit_button')}
            </SubmitButton>
          </Form>
          <OptInModalWrapper />
        </Container>
      )}
    </S.RelativeContainer>
  );
};

AdditionalInfoStep.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired
};

export default withLocales()(AdditionalInfoStep);
