/* eslint-disable no-underscore-dangle */
import { useMutation } from '@apollo/client';
import { trans } from '@spotahome/soyuz/client';

import MarketingOptInModal from '@spotahome/landlord-panel-ui-library/src/components/MarketingOptInModal';

import SET_MARKETING_OPT_IN from '../../graphql/mutations/marketing-opt-in';

const OptInModalWrapper = () => {
  const [setMarketingOptIn] = useMutation(SET_MARKETING_OPT_IN);

  const handleOnAccept = () => {
    // Throw events?
    setMarketingOptIn();
  };

  const handleOnReject = () => {
    // Throw events?
  };

  return (
    <MarketingOptInModal
      modalBodytext={trans('opt-in-checkbox-question')}
      buttonAcceptText={trans('opt-in-button-accept')}
      buttonRejectText={trans('opt-in-button-reject')}
      onReject={handleOnReject}
      onAccept={handleOnAccept}
    />
  );
};

export default OptInModalWrapper;
