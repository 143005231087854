import PropTypes from 'prop-types';

import * as S from './styles';

const LayoutTransition = ({ children, ...rest }) => (
  <S.LayoutTransitionContainer {...rest}>
    <S.LayoutTransitionContent>
      <S.LayoutTransitionContentWrapper>
        {children}
      </S.LayoutTransitionContentWrapper>
    </S.LayoutTransitionContent>
  </S.LayoutTransitionContainer>
);

LayoutTransition.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutTransition;
