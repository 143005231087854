import PropTypes from 'prop-types';

import {
  StyledBackButtonContainer,
  StyledBackButton,
  StyledBackButtonTitle
} from './styles';

const BackButton = ({ title, onClick }) => {
  return (
    <StyledBackButtonContainer>
      <StyledBackButton onClick={onClick} />
      <StyledBackButtonTitle
        type="button"
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        {title}
      </StyledBackButtonTitle>
    </StyledBackButtonContainer>
  );
};

BackButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default BackButton;
