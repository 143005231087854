import { styled } from '@mui/material/styles';

const setColor = (color, fallback = 'var(--sah-interaction-dark)') =>
  color || fallback;

const baseStyles = {
  margin: 0
};

const displayStyles = {
  fontFamily: "'Agrandir', 'Agrandor'",
  fontWeight: '400'
};

const headingStyles = {
  fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
  fontWeight: '500'
};

const paragraphStyles = {
  fontFamily: "'PoppinsLight', 'PoppinsLight-fallback'",
  fontWeight: '300'
};

const labelStyles = {
  fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
  fontWeight: '500'
};

const tagStyles = {
  fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
  fontWeight: '500'
};

export const StyledDisplay = styled('h1')(({ color, size }) => ({
  ...baseStyles,
  ...displayStyles,
  color: setColor(color),
  ...(size === 'xxl' && {
    fontSize: '76px',
    lineHeight: '86px'
  }),
  ...(size === 'xl' && {
    fontSize: '64px',
    lineHeight: '74px'
  }),
  ...(size === 'l' && {
    fontSize: '56px',
    lineHeight: '66px'
  }),
  ...(size === 'm' && {
    fontSize: '40px',
    lineHeight: '48px'
  }),
  ...(size === 's' && {
    fontSize: '34px',
    lineHeight: '42px'
  }),
  ...(size === 'xs' && {
    fontSize: '26px',
    lineHeight: '34px'
  })
}));

export const StyledHeading = styled('h1')(({ color, size }) => ({
  ...baseStyles,
  ...headingStyles,
  color: setColor(color),
  ...(size === 'l' && {
    fontSize: '22px',
    lineHeight: '26px'
  }),
  ...(size === 'm' && {
    fontSize: '18px',
    lineHeight: '24px'
  }),
  ...(size === 's' && {
    fontSize: '16px',
    lineHeight: '20px'
  })
}));

export const StyledParagraph = styled('p')(({ color, size }) => ({
  ...baseStyles,
  ...paragraphStyles,
  color: setColor(color),
  ...(size === 'xl' && {
    fontSize: '20px',
    lineHeight: '28px'
  }),
  ...(size === 'l' && {
    fontSize: '18px',
    lineHeight: '26px'
  }),
  ...(size === 'm' && {
    fontSize: '16px',
    lineHeight: '24px',
    '& b': {
      fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
      fontWeight: '500'
    }
  }),
  ...(size === 'st' && {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400'
  }),
  ...(size === 's' && {
    fontSize: '14px',
    lineHeight: '24px',
    '& b': {
      fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
      fontWeight: '500'
    }
  }),
  ...(size === 'ss' && {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '600'
  }),
  ...(size === 'xs' && {
    fontSize: '12px',
    lineHeight: '16px'
  })
}));

export const StyledLabel = styled('p')(({ color, size }) => ({
  ...baseStyles,
  ...labelStyles,
  color: setColor(color),
  ...(size === 'm' && {
    fontSize: '16px',
    lineHeight: '20px'
  }),
  ...(size === 's' && {
    fontSize: '14px',
    lineHeight: '18px'
  })
}));

export const StyledTag = styled('p')(({ color, size }) => ({
  ...baseStyles,
  ...tagStyles,
  color: setColor(color),
  ...(size === 'l' && {
    fontSize: '16px',
    lineHeight: '20px'
  }),
  ...(size === 'm' && {
    fontSize: '12px',
    lineHeight: '16px'
  }),
  ...(size === 's' && {
    fontSize: '10px',
    lineHeight: '14px',
    color: setColor(color, 'var(--sah-medium)')
  })
}));
