import { AddCircle, Home } from '@mui/icons-material';

import {
  MODIFY_PICTURES,
  LISTING_MODIFICATION,
  UNPUBLISH_REQUEST,
  ACTIVATE_INSTANT_BOOKING,
  BOOKING_STATUS,
  TENANT_COMPLAINT,
  LANDLORD_DATES_MODIFICATION,
  LANDLORD_BC_CANCELLATION,
  PAYMENT_MISSING,
  LANDLORD_REQUEST_INVOICE,
  CLAIM_GUARANTEE,
  LL_PANEL_BUG,
  LL_PANEL_DOUBTS,
  COMMERCIAL_ADVICE,
  LANDLORD_CHANGE_CONTACT_DETAILS,
  LANDLORD_DELETE_ACCOUNT,
  PUBLISH_PROPERTIES,
  UPDATE_AVAILABILITY,
  SETUP_LL_POLICIES
} from './landlordReasonsConstants';

const NOT_SPOTAHOME_CATEGORY = 'not_spotahome';
const SPOTAHOME_CATEGORY = 'spotahome';

export const LANDLORD_CATEGORY_IDS = [
  { key: SPOTAHOME_CATEGORY, icon: () => <Home /> },
  { key: NOT_SPOTAHOME_CATEGORY, icon: () => <AddCircle /> }
];

const LISTING = 'listing';
const BOOKING = 'booking';
const LANDLORD_PANEL = 'landlord_panel';
const PERFORMANCE = 'performance';
const ACCOUNT = 'account';
const NOT_SPOTAHOME = 'not_spotahome';

export const LANDLORD_SECTION_IDS = [
  LISTING,
  BOOKING,
  LANDLORD_PANEL,
  PERFORMANCE,
  ACCOUNT,
  NOT_SPOTAHOME
];

export const LANDLORD_CONFIG = {
  [SPOTAHOME_CATEGORY]: {
    [LISTING]: [
      MODIFY_PICTURES,
      LISTING_MODIFICATION,
      UNPUBLISH_REQUEST,
      ACTIVATE_INSTANT_BOOKING,
      UPDATE_AVAILABILITY
    ],
    [BOOKING]: [
      BOOKING_STATUS,
      TENANT_COMPLAINT,
      LANDLORD_DATES_MODIFICATION,
      LANDLORD_BC_CANCELLATION,
      PAYMENT_MISSING,
      LANDLORD_REQUEST_INVOICE,
      CLAIM_GUARANTEE
    ],
    [LANDLORD_PANEL]: [LL_PANEL_BUG, LL_PANEL_DOUBTS],
    [PERFORMANCE]: [COMMERCIAL_ADVICE, SETUP_LL_POLICIES],
    [ACCOUNT]: [LANDLORD_CHANGE_CONTACT_DETAILS, LANDLORD_DELETE_ACCOUNT]
  },
  [NOT_SPOTAHOME_CATEGORY]: {
    [NOT_SPOTAHOME]: [PUBLISH_PROPERTIES]
  }
};
