/* eslint-disable react/require-default-props */
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { noop } from '../../utils/fn';

import * as S from './styles';

function Checkbox({
  id,
  children = null,
  checked = undefined,
  disabled = undefined,
  description = null,
  hasError = false,
  onChange = noop,
  onClick = noop,
  containerProps = {},
  labelProps = {},
  ...restProps
}) {
  return (
    <S.Container {...containerProps}>
      <S.Input
        {...restProps}
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        aria-invalid={hasError ? 'true' : undefined}
      />
      <S.Label
        {...labelProps}
        as="label"
        htmlFor={id}
        hasError={hasError}
        onClick={onClick}
      >
        <S.Indicator hasError={hasError} />
        {children || (
          <Fragment>
            {description && <S.Description>{description}</S.Description>}
          </Fragment>
        )}
      </S.Label>
    </S.Container>
  );
}

Checkbox.propTypes = {
  /** If children is passed, it will get rendered instead of label and description. */
  children: PropTypes.node,
  /** ID that will end up in the input and will be the "for" attribute on the label. */
  id: PropTypes.string.isRequired,
  /** Input checked or unchecked. For a controlled input, always pass a value. */
  checked: PropTypes.bool,
  /** Intput disabled or not disabled.  */
  disabled: PropTypes.bool,
  /** Description of the checkbox. */
  description: PropTypes.string,
  /** Mark the input with an error. */
  hasError: PropTypes.bool,
  /** Callback to be called when the input changes. */
  onChange: PropTypes.func,
  /** Callback to be called when the input is clicked. */
  onClick: PropTypes.func,
  /** Props that will be spread on the top parent container. */
  containerProps: PropTypes.object,
  /** Props that will be spread on the label. */
  labelProps: PropTypes.object
};

export default Checkbox;
