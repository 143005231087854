import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import CardCTA from '@spotahome/landlord-panel-ui-library/src/components/CardCTA';

export const Container = styled.div``;

export const TopContainer = styled.div`
  padding-bottom: ${space.x5};
  width: auto;
`;

export const CtaCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TempTitle = styled.div`
  font-family: ${fonts.GothamBold};
  font-size: ${pixelsToRem(32)};
  padding-bottom: ${space.x1};
`;

export const LoginLink = styled.a`
  padding-left: ${space.x05};
  color: ${colors.primary};
  text-decoration: underline;
`;

export const CustomCardCTA = styled(CardCTA)`
  margin-bottom: ${space.x3};
  margin-right: auto;
`;
