import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import { getPagePath, useSoyuzLocales } from '@spotahome/soyuz/client';

import { Logo } from '@spotahome/ui-library/base';

import { breakpoints } from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';

import LanguageSwitcher from '../../components/LanguageSwitcher';

import LayoutSideContent from '../LayoutSideContent';

import * as S from './styles';

const LANDLORD_LANDING_ROUTE_ID = 'externals.landlord.landing';

const Layout = ({ children }) => {
  const isMobileView = useMediaQuery({ maxWidth: breakpoints.lg - 1 });
  const { current: locale } = useSoyuzLocales();

  return (
    <S.Container>
      <Global styles={S.globalStyles} />
      <S.TopContainer>
        <S.LogoLink href={getPagePath(LANDLORD_LANDING_ROUTE_ID, { locale })}>
          <Logo size="normal" theme="light" />
        </S.LogoLink>
        <S.LanguageSwitcherWrapper>
          <LanguageSwitcher theme={isMobileView ? 'light' : 'dark'} />
        </S.LanguageSwitcherWrapper>
      </S.TopContainer>
      {children}
    </S.Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

Layout.SidePanel = () => (
  <S.SidePanel>
    <S.SidePanelContentWrapper>
      <LayoutSideContent />
    </S.SidePanelContentWrapper>
  </S.SidePanel>
);
Layout.ContentPanel = ({ children }) => (
  <S.ContentPanel>{children}</S.ContentPanel>
);

Layout.ContentPanel.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
