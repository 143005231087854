import Tracking from '@spotahome/soyuz-tracking';

import { trackIdentifyUser } from './common';

const ACCOUNT_CREATION_CLICKED = 'AccountCreationClicked';
const ACCOUNT_CREATION_SUCCESS = 'AccountCreationSuccess';

export const trackAccountCreatedSuccess = ({
  user,
  locale,
  city,
  accountId,
  numberOfProperties
}) => {
  trackIdentifyUser({ user });
  Tracking.segment.trackEvent(ACCOUNT_CREATION_SUCCESS, user, {
    locale,
    city,
    number_properties: numberOfProperties,
    id: accountId
  });
};

export const trackAccountCreationClicked = ({
  user,
  locale,
  city,
  desiredCity,
  numberProperties
}) => {
  trackIdentifyUser({ user });
  Tracking.segment.trackEvent(ACCOUNT_CREATION_CLICKED, user, {
    city,
    desiredCity,
    number_properties: numberProperties,
    locale
  });
};
