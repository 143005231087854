import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const StateContext = createContext();

const INITIAL_STATE = {
  state: 'init',
  payload: {}
};

export const useStateMachine = (states, initialState = INITIAL_STATE) => {
  const [currentSnapshot, setState] = useState(initialState);

  return {
    state: currentSnapshot.state,
    payload: currentSnapshot.payload,
    send: (ev, payload = {}) => {
      const nextState = states[currentSnapshot.state].on[ev];
      if (nextState) {
        setState(prevSnapshot => {
          const expectedPayload = { ...prevSnapshot.payload, ...payload };
          return {
            state: nextState,
            payload: expectedPayload
          };
        });
      }
    }
  };
};

export const StateMachineProvider = ({
  children,
  states,
  initialState = INITIAL_STATE
}) => {
  const stateMachine = useStateMachine(states, initialState);

  return (
    <StateContext.Provider value={{ ...stateMachine }}>
      {children}
    </StateContext.Provider>
  );
};

StateMachineProvider.propTypes = {
  children: PropTypes.node.isRequired,
  states: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      on: PropTypes.shape({
        [PropTypes.string]: PropTypes.string
      })
    })
  }).isRequired,
  initialState: PropTypes.shape({
    state: PropTypes.string,
    payload: PropTypes.shape({})
  })
};

export const useStateMachineContext = () => useContext(StateContext);

export default {
  StateMachineProvider,
  useStateMachineContext,
  useStateMachine
};
