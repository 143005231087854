import { css } from '@emotion/react';
import { rgba } from 'polished';

import colors from './colors';
import { border } from './spacing';

function borderGreyLight(opacity = 0.5) {
  return css`
    border: ${border.size} solid ${rgba(colors.greyLight, opacity)};
  `;
}

function borderGreySlim(opacity = 0.5) {
  return css`
    border: 0;
    border-bottom: ${border.size} solid ${rgba(colors.greySeparator, opacity)};
  `;
}

function borderGreen(opacity = 0.5) {
  return css`
    border: ${border.size} solid ${rgba(colors.green, opacity)};
  `;
}

export { borderGreyLight, borderGreySlim, borderGreen };
