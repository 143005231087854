/*
  DISCLAIMER: This is a workaround to enable Soyuz's `renderInDocumentWithRouter` to work with React Router v7
  in library mode without requiring substantial modifications to Soyuz's build system.
  This is meant to be a temporary solution until Soyuz is updated to fully support React Router v7 in framework mode.
*/

import { BrowserRouter } from 'react-router';
import { hydrateRoot, createRoot } from 'react-dom/client';

import {
  CONTEXT_KEY,
  SSR_ENABLED_KEY
} from '@spotahome/soyuz/dist/common/constants';
import { SoyuzProvider } from '@spotahome/soyuz/client';

function isSSREnabled() {
  const context = window[CONTEXT_KEY];

  if (context) {
    return context[SSR_ENABLED_KEY];
  }

  return false;
}

function renderInDocumentWithRouter(Main) {
  const context = window[CONTEXT_KEY];
  const basePath = context.request ? context.request.baseUrl : '/';
  const container = document.getElementById('root');
  const children = (
    <SoyuzProvider>
      <BrowserRouter basename={basePath}>
        <Main />
      </BrowserRouter>
    </SoyuzProvider>
  );

  if (isSSREnabled()) {
    hydrateRoot(container, children);
  } else {
    createRoot(container).render(children);
  }
}

export default renderInDocumentWithRouter;
