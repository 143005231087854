import { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import optionShape from '../../shapes/option';
import Icon from '../../../Icon/Icon';
import { defaultValue } from '../../shared/utils/utils';

import {
  ArrowIconWrapper,
  NativeSelectContainer,
  StyledSelect
} from './NativeSelect.styles';

function isValueControlled(value) {
  return value !== undefined;
}

class NativeSelect extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { value } = nextProps;
    let nextState = {};

    if (isValueControlled(value) && value !== state.value) {
      nextState = { ...nextState, value };
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  state = {
    value: defaultValue(
      this.props.value,
      this.props.options,
      this.props.placeholder
    )
  };

  constructor(props) {
    super(props);
    this.selectRef = createRef();
  }

  handleOnChange = e => {
    this.select(e.target.value);
  };

  get isValueControlled() {
    return isValueControlled(this.props.value);
  }

  select = value => {
    if (this.isValueControlled) {
      this.props.onChange(value);
    } else {
      this.setState(() => ({
        value
      }));
    }
  };

  shouldRenderPlaceholder = () => {
    const { value } = this.state;
    const { placeholder } = this.props;
    return value === null && placeholder;
  };

  render() {
    const {
      options,
      placeholder,
      isOptionDisabled,
      dropdownButton,
      ...htmlProps
    } = this.props;
    const { value } = this.state;
    const { onChange, ...rest } = htmlProps; // onChange event cannot be passed down

    return (
      <NativeSelectContainer isDropdownButton={dropdownButton}>
        <ArrowIconWrapper>
          <Icon name={'angleDown'} />
        </ArrowIconWrapper>
        <StyledSelect
          ref={this.selectRef}
          isDropdownButton={dropdownButton}
          value={value || ''}
          onChange={this.handleOnChange}
          {...rest}
        >
          {this.shouldRenderPlaceholder() && (
            <option key="empty" value="">
              {placeholder}
            </option>
          )}
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
              disabled={isOptionDisabled && isOptionDisabled(option)}
            >
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </NativeSelectContainer>
    );
  }
}

NativeSelect.defaultProps = {
  dropdownButton: false,
  options: [],
  value: undefined,
  placeholder: '',
  isOptionDisabled: undefined,
  onChange: () => {}
};

NativeSelect.propTypes = {
  /** Whether the button of the select should be displayed as dropdown */
  dropdownButton: PropTypes.bool,
  /** Array of options values */
  options: PropTypes.arrayOf(optionShape),
  /** Controlled prop for the value of the select */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The initial placeholder for the select */
  placeholder: PropTypes.string,
  /** Function which receives every value of the select to check if is disabled or not */
  isOptionDisabled: PropTypes.func,
  /** Callback for the value change */
  onChange: PropTypes.func
};

export default NativeSelect;
