import { trans } from '@spotahome/soyuz/client';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';

import FeatureBoxList from '../../components/FeatureBoxList';

import * as S from './styles';

const LayoutSideContent = () => {
  const isMobileView = useMediaQuery({ maxWidth: breakpoints.lg - 1 });

  return (
    <S.Container>
      <S.Content>
        <S.Header
          dangerouslySetInnerHTML={{
            __html: trans('sahc.sign-up.side-panel.header')
          }}
        />
        {!isMobileView && (
          <>
            <S.Title>{trans('sahc.sign-up.side-panel.title')}</S.Title>
            <S.FeatureBoxListWrapper>
              <FeatureBoxList />
            </S.FeatureBoxListWrapper>
          </>
        )}
      </S.Content>
    </S.Container>
  );
};

export default LayoutSideContent;
