import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../shared/fonts';
import { space, border } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';
import colors from '../../shared/colors';

const generateInputStyle = hasError => css`
  appearance: none;
  border: ${border.size} solid ${hasError ? colors.error : colors.greyLight};
  border-radius: ${border.radius};
  padding: ${space.x15};
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${colors.greyLight};
  }
  &::placeholder {
    font-size: ${fonts.sizes.s};
    color: ${colors.greySeparator};
  }

  ${mediaQueries.md(css`
    padding: ${space.x125};
  `)};
`;

const Input = styled.input`
  ${props => generateInputStyle(props.hasError)};
`;

const StyledInput = ({
  hasError = false,
  disabled = false,
  placeholder = '',
  ...htmlProps
}) => (
  <Input
    hasError={hasError}
    disabled={disabled}
    placeholder={placeholder}
    {...htmlProps}
  />
);

StyledInput.propTypes = {
  /** Placeholder that the input will have */
  placeholder: PropTypes.string,
  /** Indicates whether the input has an error */
  hasError: PropTypes.bool,
  /** Disables the input  */
  disabled: PropTypes.bool
};

export default StyledInput;
