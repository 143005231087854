import PropTypes from 'prop-types';
import { useSoyuzLocales, getPagePath, trans } from '@spotahome/soyuz/client';

import * as S from './styles';

const Disclaimer = ({ translationKey }) => {
  const { current: locale } = useSoyuzLocales();
  return (
    <S.Disclaimer
      data-cy="terms-conditions"
      tag="p"
      trans={trans}
      translationKey={translationKey}
      params={{
        terms: getPagePath('externals.terms-and-conditions', { locale }),
        privacy: getPagePath('externals.privacy-policies', { locale })
      }}
    />
  );
};

Disclaimer.propTypes = {
  translationKey: PropTypes.string.isRequired
};

export default Disclaimer;
