import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../../../shared/fonts';
import { space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';
import Button from '../../../Button';
import Dialog from '../Dialog';

import WarningIcon from './WarningIcon';

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fonts.sizes.s};
  line-height: 1.64;
`;

const WarningContainer = styled.div`
  display: flex;
  margin-top: ${space.x2};
  align-items: center;

  svg {
    min-width: 34px;
    margin-right: ${space.x2};
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${space.x25};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    width: 100%;

    &:first-of-type {
      margin-bottom: ${space.x2};
    }
  }

  ${mediaQueries.md(css`
    flex-direction: row;

    button {
      width: auto;

      &:first-of-type {
        margin-bottom: 0;
        margin-right: ${space.x2};
      }
    }
  `)};
`;

const ConfirmDialog = ({
  isOpen,
  title,
  text,
  warningText = null,
  onCancel,
  onAccept,
  acceptButtonText,
  cancelButtonText,
  onRequestClose = () => {},
  acceptButtonProps = {}
}) => (
  <Dialog title={title} isOpen={isOpen} onRequestClose={onRequestClose}>
    <DialogBody>
      {text}
      {warningText && (
        <WarningContainer>
          <WarningIcon />
          {warningText}
        </WarningContainer>
      )}
      <ButtonsContainer>
        <Button alt onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button onClick={onAccept} {...acceptButtonProps}>
          {acceptButtonText}
        </Button>
      </ButtonsContainer>
    </DialogBody>
  </Dialog>
);

ConfirmDialog.propTypes = {
  /** Bool value to determine if dialog is opened */
  isOpen: PropTypes.bool.isRequired,
  /** Text to be shown on the header of the dialog */
  title: PropTypes.string.isRequired,
  /** Content to be shown on the body of the dialog */
  text: PropTypes.node.isRequired,
  /** Text to be shown on the warning section of the dialog */
  warningText: PropTypes.string,
  /** Text to be shown on dialog's primary button */
  acceptButtonText: PropTypes.string.isRequired,
  /** Text to be shown on dialog's sencodary button */
  cancelButtonText: PropTypes.string.isRequired,
  /** Function to be called on primary button click */
  onAccept: PropTypes.func.isRequired,
  /** Function to be called on secondary button click */
  onCancel: PropTypes.func.isRequired,
  /** Function to be called when the dialog is requested to be closed by clicking on overlay or pressing ESC */
  onRequestClose: PropTypes.func,
  /** Object to add a id for testing */
  acceptButtonProps: PropTypes.object
};

export default ConfirmDialog;
