import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const CircleCheck = props => (
  <BaseIcon width="44" height="44" {...props} viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M20.133 34.4L37.2 17.333l-1.867-1.866-15.2 15.2-7.6-7.6-1.866 1.866 9.466 9.467zM24 48c-3.333 0-6.455-.633-9.365-1.899-2.912-1.267-5.446-2.98-7.6-5.136-2.157-2.154-3.869-4.688-5.136-7.6C.633 30.455 0 27.333 0 24s.633-6.456 1.899-9.368c1.267-2.91 2.98-5.444 5.136-7.6 2.154-2.155 4.688-3.866 7.6-5.133C17.545.633 20.667 0 24 0s6.456.633 9.368 1.899c2.91 1.267 5.444 2.978 7.6 5.133 2.155 2.156 3.866 4.69 5.133 7.6C47.367 17.544 48 20.667 48 24s-.633 6.455-1.899 9.365c-1.267 2.912-2.978 5.446-5.133 7.6-2.156 2.157-4.69 3.869-7.6 5.136C30.456 47.367 27.333 48 24 48zm0-2.667c5.956 0 11-2.066 15.133-6.2 4.134-4.133 6.2-9.177 6.2-15.133 0-5.956-2.066-11-6.2-15.133C35 4.733 29.956 2.667 24 2.667c-5.956 0-11 2.066-15.133 6.2C4.733 13 2.667 18.044 2.667 24c0 5.956 2.066 11 6.2 15.133 4.133 4.134 9.177 6.2 15.133 6.2z"
    />
  </BaseIcon>
);

export default CircleCheck;
