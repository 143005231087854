import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router';

import * as Yup from 'yup';

import { trans, useSoyuzFlag, useSoyuzLocales } from '@spotahome/soyuz/client';

import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';

import {
  trackIdentifyUser,
  trackPageChanged
} from '@spotahome/auth-common/src/tracking/common';

import { trackUserCreationClicked } from '@spotahome/auth-common/src/tracking/signup';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import userClient from '@spotahome/auth-common/src/api/user-client';

import useForm from '@spotahome/auth-common/src/hooks/useForm';

import {
  Container,
  Title,
  Form,
  SubmitButton
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';
import Disclaimer from '@spotahome/auth-common/src/components/Disclaimer';
import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import SocialSignup from './components/SocialSignup';
import MarketingOptInCheck from './components/MarketingOptInCheck';

import * as S from './styles';

const INITIAL_FORM_VALUES = {
  name: '',
  email: '',
  password: ''
};

const MAX_LENGTH_INPUT = 255;
const MAX_LENGTH_EMAIL = 66;

const FORM_SCHEMA = Yup.object({
  name: Yup.string().required(
    trans('landlord.signup.create-account.name.error.required')
  ),
  email: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      trans('landlord.signup.create-account.email.error.format')
    )
    .required(trans('landlord.signup.create-account.email.error.required')),
  password: Yup.string()
    .min(6, trans('landlord.signup.create-account.password.length'))
    .required(trans('landlord.signup.create-account.password.error.required'))
});

const CreateAccountStep = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(document.location.search);
  const [submitError, setSubmitError] = useState('');
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const [accountIsCreated, setAccountIsCreated] = useState(false);
  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    {
      ...INITIAL_FORM_VALUES,
      name: queryParams.get('name') || '',
      email: queryParams.get('email') || ''
    },
    FORM_SCHEMA
  );
  const { current: locale } = useSoyuzLocales();
  const hideFacebookOauth = useSoyuzFlag('LandlordHideFacebookOauth').isOn();
  const hideGoogleOauth = useSoyuzFlag('LandlordHideGoogleOauth').isOn();

  useEffect(() => {
    trackIdentifyUser();
    trackPageChanged();
  }, []);

  const handleUserCreationClick = () => {
    trackUserCreationClicked({ signUpMethod: 'email' });
  };

  const handleMarketingOptInChange = () => {
    setMarketingOptIn(!marketingOptIn);
  };

  const handleSubmitFormError = error => {
    const errorMessage = trans(getErrorTransKey(error));
    setSubmitError(errorMessage);
  };

  const handleCreateUser = async ({ name, email, password }) => {
    const { data } = await userClient.createUser({
      name,
      email,
      password,
      locale,
      marketingOptIn
    });
    return data;
  };

  const onSubmit = async (data, { setIsSubmitting }) => {
    try {
      const NEXT_STEP = getRoutePath('landlord.signup.additional-info');
      const { id } = await handleCreateUser(data);
      setAccountIsCreated(true);
      navigate(NEXT_STEP, { userId: id, method: 'email' });
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Title>{trans('landlord.signup.create-account.title')}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputGroup
          id="name"
          label={trans('landlord.signup.create-account.name.label')}
          error={trans(errors.name)}
        >
          <Input
            data-cy="name"
            id="name"
            name="name"
            placeholder={trans(
              'landlord.signup.create-account.name.placeholder'
            )}
            value={values.name}
            onChange={handleChange}
            hasError={errors.name}
            type="text"
            maxLength={MAX_LENGTH_INPUT}
            required
          />
        </InputGroup>
        <InputGroup
          id="email"
          label={trans('landlord.signup.create-account.email.label')}
          error={trans(errors.email)}
        >
          <Input
            data-cy="email"
            id="email"
            name="email"
            placeholder={trans(
              'landlord.signup.create-account.email.placeholder'
            )}
            value={values.email}
            onChange={handleChange}
            hasError={errors.email}
            type="email"
            maxLength={MAX_LENGTH_EMAIL}
            required
          />
        </InputGroup>
        <InputGroup
          id="password"
          label={trans('landlord.signup.create-account.password.label')}
          error={trans(errors.password)}
        >
          <Input
            data-cy="password"
            id="password"
            name="password"
            placeholder={trans(
              'landlord.signup.create-account.password.placeholder'
            )}
            value={values.password}
            onChange={handleChange}
            hasError={errors.password}
            type="password"
            maxLength={MAX_LENGTH_INPUT}
            required
          />
          <S.HelpText>
            {trans('landlord.signup.create-account.password.helptext')}
          </S.HelpText>
        </InputGroup>
        {submitError && <S.StyledInputError>{submitError}</S.StyledInputError>}
        <MarketingOptInCheck onChange={handleMarketingOptInChange} />
        <Disclaimer
          translationKey={'landlord.signup.create-account.disclaimer'}
        />
        <SubmitButton
          onClick={handleUserCreationClick}
          data-cy="signup-button"
          type="submit"
          loading={isSubmitting || accountIsCreated}
        >
          {trans('landlord.signup.create-account.submit.label')}
        </SubmitButton>
      </Form>
      {hideFacebookOauth && hideGoogleOauth ? null : (
        <Fragment>
          <S.OrSeperator>
            {trans('landlord.signup.create-account.or-seperator')}
          </S.OrSeperator>
          <SocialSignup
            hideFacebookOauth={hideFacebookOauth}
            hideGoogleOauth={hideGoogleOauth}
          />
        </Fragment>
      )}
      <S.LoginPrompt>
        <span>
          {trans('landlord.signup.create-account.already-have-account')}{' '}
        </span>
        <S.LoginLink to={getRoutePath('landlord.login')}>
          {trans('landlord.signup.create-account.login')}
        </S.LoginLink>
      </S.LoginPrompt>
    </Container>
  );
};

export default CreateAccountStep;
