/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import optionShape from '../../shapes/option';

import {
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledMenuPortal,
  StyledSelectMenu,
  StyledSelectMenuItem
} from './SelectMenu.styles';

// ------
// SELECT
// ------

const SelectMenuItem = ({
  option,
  isSelected = false,
  isDisabled = false,
  onSelect
}) => {
  const { label, value } = option;

  function handleOnClick() {
    onSelect(value);
  }

  return (
    <StyledSelectMenuItem
      role="button"
      tabIndex={0}
      isSelected={isSelected}
      isDisabled={isDisabled}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleOnClick}
    >
      <span>{label}</span>
    </StyledSelectMenuItem>
  );
};

SelectMenuItem.propTypes = {
  option: optionShape.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

const SelectMenu = ({
  isOpen,
  top,
  minWidth,
  children,
  ...selectMenuProps
}) => (
  <StyledSelectMenu
    role="button"
    tabIndex={0}
    isOpen={isOpen}
    top={top}
    minWidth={minWidth}
    {...selectMenuProps}
  >
    {children}
  </StyledSelectMenu>
);

SelectMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

// --------
// DROPDOWN
// --------

const DropdownMenuItem = ({
  option,
  isSelected = false,
  isDisabled = false,
  onSelect
}) => {
  const { label, value } = option;

  function handleOnClick() {
    onSelect(value);
  }

  return (
    <StyledDropdownMenuItem
      role="button"
      tabIndex={0}
      isSelected={isSelected}
      isDisabled={isDisabled}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleOnClick}
    >
      <span>{label}</span>
    </StyledDropdownMenuItem>
  );
};

DropdownMenuItem.propTypes = {
  option: optionShape.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  /**
   * @param {string|number} value
   * @param {boolean} isDisabled
   */
  onSelect: PropTypes.func.isRequired
};

const DropdownMenu = ({ isOpen, top, children, ...dropdownMenuProps }) => (
  <StyledDropdownMenu
    role="button"
    tabIndex={0}
    isOpen={isOpen}
    top={top}
    {...dropdownMenuProps}
  >
    {children}
  </StyledDropdownMenu>
);

DropdownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

// -------
// PORTAL
// -------

const MenuPortal = ({
  parentElement,
  portalTargetSelector,
  minWidth,
  children
}) => {
  if (!parentElement.current) {
    return null;
  }
  const rect = parentElement.current.getBoundingClientRect();
  const menuWrapper = (
    <StyledMenuPortal top={rect.top} left={rect.left} minWidth={minWidth}>
      {children}
    </StyledMenuPortal>
  );

  const targetElement = portalTargetSelector
    ? document.querySelector(portalTargetSelector)
    : document.querySelector('body');

  return createPortal(menuWrapper, targetElement);
};

export {
  SelectMenu,
  SelectMenuItem,
  DropdownMenu,
  DropdownMenuItem,
  MenuPortal
};
