import styled from '@emotion/styled';

import RichTranslation from '@spotahome/landlord-panel-ui-library/src/components/RichTranslation';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';

// eslint-disable-next-line import/prefer-default-export
export const Disclaimer = styled(RichTranslation)`
  font-family: ${fonts.GothamBold};
  font-size: ${fonts.sizes.s};
  line-height: ${pixelsToRem(22)};
  margin: ${space.x3} 0 0;

  a {
    color: ${colors.primary};
  }
`;
