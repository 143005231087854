import styled from '@emotion/styled';

import colors from '../../shared/colors';
import { space, border } from '../../shared/spacing';
import { borderGreyLight } from '../../shared/borders';
import { boxShadow } from '../../shared/shadows';

const Box = styled.div`
  padding: ${space.x2};
  ${borderGreyLight(0.5)};
  border-radius: ${border.radius};
  background-color: ${colors.white};
  ${boxShadow};
`;

export default Box;
