import PropTypes from 'prop-types';

const EmailLogo = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="73"
    fill="none"
    className={className}
  >
    <path fill="#C9E3F7" d="M0 0h100v73H0z" />
    <path fill="#0A0777" d="M0 0h100l-50 50-50-50Z" />
  </svg>
);

EmailLogo.propTypes = {
  className: PropTypes.string
};

export default EmailLogo;
