import PropTypes from 'prop-types';

import * as S from './styles';

const FeatureBox = ({ icon, description }) => {
  return (
    <S.Container>
      <div>{icon}</div>
      <S.Description>{description}</S.Description>
    </S.Container>
  );
};

FeatureBox.propTypes = {
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired
};

export default FeatureBox;
