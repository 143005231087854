import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Icon from '../../../Icon/Icon';
import { border, space } from '../../../../shared/spacing';
import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';

const commonStyles = () => css`
  outline: 0;
  cursor: default;
`;

const StyledSelectArrowIcon = styled(Icon)`
  position: absolute;
  right: 0;
`;

const StyledSelectButton = styled.div`
  ${commonStyles()}
  font-size: ${fonts.sizes.s};
  padding: ${space.x1} ${space.x3} ${space.x1} 0;
`;

const StyledDropdownButton = styled.div`
  ${commonStyles()}
  position: relative;
  display: flex;
  padding: ${space.x125} ${space.x15};
  background-color: ${colors.white};
  justify-content: space-between;
  border: 1px solid ${colors.greyLight};
  border-radius: ${border.radius};
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `};
`;

const StyledIcon = styled(Icon)`
  color: ${colors.primary};
`;

export {
  StyledSelectArrowIcon,
  StyledSelectButton,
  StyledDropdownButton,
  StyledIcon
};
