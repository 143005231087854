import { useState, useCallback } from 'react';

const useModalHandle = () => {
  const [isModalOpen, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => setOpen(true), []);
  const handleModalClose = useCallback(() => setOpen(false), []);

  return {
    isModalOpen,
    handleModalOpen,
    handleModalClose
  };
};

export default useModalHandle;
