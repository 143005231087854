const withCookie =
  fn =>
  (...args) => {
    if (!document) {
      return null;
    }
    return fn(...args);
  };

const getCookies = () =>
  document.cookie.split(';').reduce((acc, cookie) => {
    const [key, val] = cookie.trim().split('=').map(decodeURIComponent);
    return {
      ...acc,
      [key]: val
    };
  }, {});

export const getCookie = withCookie(cookieName => {
  const cookies = getCookies();

  return cookies[cookieName];
});

export const deleteCookie = withCookie(cookieName => {
  document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
});
