import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

import BackButton from '../components/BackButton';

export const StyledBackButton = styled(BackButton)`
  margin-top: -${space.x1};
  margin-bottom: ${space.x2};
`;

export const BackButtonSpace = styled.div`
  height: ${space.x5};
`;
