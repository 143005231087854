import { ApolloProvider } from '@apollo/client';

import {
  trackIdentifyUser,
  trackPageChanged
} from '@spotahome/auth-common/src/tracking/common';

import getGqlClient from '@spotahome/auth-common/src/graphql/client';

import { useEffect } from 'react';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  useEffect(() => {
    trackIdentifyUser();
    trackPageChanged();
  }, []);

  return (
    <ApolloProvider client={getGqlClient()}>
      <ForgotPasswordForm />
    </ApolloProvider>
  );
};

export default ForgotPassword;
