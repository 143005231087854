import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import ChevronDown from '@spotahome/landlord-panel-ui-library/src/icons/ChevronDown';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';

export const LanguageSwitcherContainer = styled.div`
  position: relative;
  color: ${({ theme }) => {
    switch (theme) {
      case 'dark':
        return colors.greyDark;
      case 'light':
        return colors.white;
      default:
        return colors.greyDark;
    }
  }};
`;

export const Menu = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  font-family: ${fonts.GothamMedium};
  padding: ${space.x1};
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowDownIcon = styled(ChevronDown)`
  width: ${pixelsToRem(12)};
`;

export const LanguageSwitcherSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  color: ${colors.grey};
`;
