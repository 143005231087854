import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

let client;

const initClient = () => {
  client = new ApolloClient({
    link: createHttpLink({
      uri: '/api/default-landlord-pages/graphql',
      credentials: 'same-origin'
    }),
    cache: new InMemoryCache(),
    assumeImmutableResults: false
  });

  return client;
};

export default () => client || initClient();
