import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  StyledSelectArrowIcon,
  StyledSelectButton
} from './SelectButton.styles';

const SelectButton = forwardRef(
  ({ caption, isOpen, onClick, ...htmlProps }, ref) => (
    <StyledSelectButton
      ref={ref}
      isOpen={isOpen}
      role="button"
      tabIndex="0"
      onClick={onClick}
      {...htmlProps}
    >
      <span>{caption}</span>

      <StyledSelectArrowIcon name={isOpen ? 'angleUp' : 'angleDown'} />
    </StyledSelectButton>
  )
);

SelectButton.defaultProps = {
  isOpen: false,
  onClick: () => {},
  caption: ''
};

SelectButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  caption: PropTypes.string
};

export default SelectButton;
