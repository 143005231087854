import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  getPagePath,
  trans,
  useSoyuzClientConfig,
  useSoyuzLocales,
  useSoyuzUser
} from '@spotahome/soyuz/client';
import * as yup from 'yup';
import ReCaptcha from 'react-google-recaptcha';
import { useMutation } from '@apollo/client';
import toastShape from '@spotahome/landlord-panel-ui-library/src/shapes/toast';
import { withToast } from '@spotahome/landlord-panel-ui-library/src/components/Toast';

import {
  MUTATION_CREATE_SUPPORT_TICKET,
  MUTATION_CREATE_SUPPORT_TICKET_UNLOGGED
} from '../../../graphql/support/mutations';
import {
  sendSupportFormSubmitAttempt,
  sendSupportFormSubmitError,
  sendSupportFormSubmitSuccess,
  sendSupportFormSubmitValidationError
} from '../../../lib/segment/support';

import { useStateMachineContext } from '../context/StateMachineContext';

import { LANDLORD_REASONS } from '../constants/landlordReasons';
import { LANDLORD_DELETE_ACCOUNT } from '../constants/landlordReasonsConstants';
import { FORM_FIELDS } from '../constants/formFields';

import DeleteAccountModal from '../../AccountInformation/DeleteAccountModal';

import { trackWantToStayButtonClicked } from '../../../lib/segment/settings';

import FormProvider from './FormProvider';
import {
  StyledArticleListHint,
  StyledContactForm,
  StyledDescription,
  StyledRecaptcha,
  StyledSubmitButton,
  StyledSubmitContainer,
  StyledGdprText
} from './styles';

import BackButton from './BackButton';

const FORM_COMPONENTS = {
  text: {
    component: FormProvider.Input,
    props: {
      type: 'text'
    }
  },
  number: {
    component: FormProvider.Input,
    props: {
      type: 'number'
    }
  },
  email: {
    component: FormProvider.Input,
    props: {
      type: 'email'
    }
  },
  date: {
    component: FormProvider.Date
  },
  textarea: {
    component: FormProvider.Input,
    props: {
      rows: 4,
      type: 'textarea'
    }
  },
  select: {
    component: FormProvider.Select
  },
  cityAutocompleter: {
    component: FormProvider.CityAutocompleter
  },
  phone: {
    component: FormProvider.PhoneInput
  }
};

const composeFormSchema = (formFields, conditions) => {
  const schemaObject = formFields.reduce((acc, fieldId) => {
    const fieldCondition = conditions[fieldId];
    if (fieldCondition) {
      const { dependsOn, compare } = fieldCondition;
      acc[fieldId] = FORM_FIELDS[fieldId].schema.when(dependsOn, {
        is: compare,
        then: yup.string().ensure().required('contact-us.required-field')
      });
    } else {
      acc[fieldId] = FORM_FIELDS[fieldId].schema;
    }

    return acc;
  }, {});

  return yup.object().shape(schemaObject);
};

const ContactForm = ({
  showBackButton = true,
  showRelatedArticle = true,
  isPublicForm = false,
  toast
}) => {
  const { current: currentLocale } = useSoyuzLocales();
  const { recaptchaSiteKey } = useSoyuzClientConfig();
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const user = useSoyuzUser();

  const {
    send,
    payload: { reasonId }
  } = useStateMachineContext();

  const [createSupportTicket] = useMutation(MUTATION_CREATE_SUPPORT_TICKET, {
    context: { uri: '/api/default-landlord-pages/graphql' }
  });

  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const handleOnClickWantToStayButton = () => {
    trackWantToStayButtonClicked(user);
    setDeleteAccountModalOpen(false);
  };

  const [createSupportTicketUnlogged] = useMutation(
    MUTATION_CREATE_SUPPORT_TICKET_UNLOGGED,
    {
      context: { uri: '/landlord/public/graphql' }
    }
  );

  const {
    helpCenterArticles,
    linkTo,
    fields: formFields,
    sosNumber,
    conditions = {}
  } = LANDLORD_REASONS[reasonId];

  const formSchema = composeFormSchema(formFields, conditions);

  const scrollTop = () => window && window.scrollTo({ top: 0 });

  const handleFormError = () => {
    sendSupportFormSubmitValidationError(reasonId, user);
  };

  const shouldDeleteAccount =
    !isPublicForm && reasonId === LANDLORD_DELETE_ACCOUNT;

  async function createTicket(values) {
    const {
      city,
      referenceCode,
      bookingId,
      name,
      email,
      rentableUnitId,
      moveInDate,
      comment,
      numberProperties,
      phoneNumber,
      phoneNumberOptional,
      budget,
      device,
      browser,
      tenantProfile,
      neighbourhood,
      propertyType,
      idNumber,
      address,
      listingModificationType,
      newContactDetails,
      unpublishReason,
      cancellationReason
    } = values;

    setLoading(true);

    const input = {
      reason: reasonId,
      role: 'landlord',
      name,
      email,
      phoneNumber: phoneNumber || phoneNumberOptional,
      numberProperties,
      referenceCode,
      bookingId,
      city: city ? city.id : null,
      rentableUnitId,
      moveInDate,
      comment,
      budget,
      device,
      browser,
      tenantProfile,
      neighbourhood,
      propertyType,
      idNumber,
      address,
      listingModificationType,
      newContactDetails,
      unpublishReason,
      cancellationReason
    };

    try {
      if (isPublicForm) {
        await createSupportTicketUnlogged({
          variables: {
            input
          }
        });
      } else {
        delete input.name;
        delete input.email;
        await createSupportTicket({
          variables: {
            input
          }
        });
      }
      setLoading(false);
      if (!shouldDeleteAccount) {
        send('NEXT', { ...values, sosNumber });
      }
      scrollTop();
      sendSupportFormSubmitSuccess(reasonId, user);
    } catch (error) {
      setLoading(false);
      toast.show({
        type: 'error',
        title: trans('sahc.conversation.error.title')
      });
      sendSupportFormSubmitError(reasonId, user);
    }
  }

  const handleFormSubmit = async values => {
    sendSupportFormSubmitAttempt(reasonId, user);

    if (shouldDeleteAccount) {
      setFormValues(values);
      setDeleteAccountModalOpen(true);
      return;
    }

    await createTicket(values);
  };

  const getArticleCopy = () =>
    helpCenterArticles.length > 1
      ? trans(`contact-us.landlord.hint.help_center.multiple_articles`)
      : trans(`contact-us.landlord.hint.help_center.single_article`);

  const getArticleList = () =>
    helpCenterArticles.map(zendeskPath => {
      const zendeskUrl = `${getPagePath(
        'externals.help-center'
      )}/${zendeskPath}`;
      return (
        <a href={zendeskUrl} target="_blank" rel="noreferrer" key={zendeskPath}>
          {zendeskUrl}
        </a>
      );
    });

  const onCaptchaSuccess = () => {
    setRecaptchaPassed(true);
  };

  const onCaptchaExpired = () => {
    setRecaptchaPassed(false);
  };

  return (
    <StyledContactForm>
      {showBackButton && (
        <BackButton
          title={trans(`contact-us.landlord.${reasonId}`)}
          onClick={() => send('BACK')}
        />
      )}
      <DeleteAccountModal
        modalOpen={deleteAccountModalOpen}
        onClose={handleOnClickWantToStayButton}
        landlordEmail={user.email}
        handleOnDeleteAccount={() => createTicket(formValues)}
      />
      {linkTo && (
        <StyledDescription>
          <span>{trans(`contact-us.landlord.hint.go_to_link.${linkTo}`)}</span>
          <StyledArticleListHint>
            <a href={getPagePath(linkTo)} target="_blank" rel="noreferrer">
              {trans(linkTo)}
            </a>
          </StyledArticleListHint>
        </StyledDescription>
      )}

      {helpCenterArticles &&
        !!helpCenterArticles.length &&
        showRelatedArticle && (
          <StyledDescription>
            <span>{getArticleCopy()}</span>
            <StyledArticleListHint>{getArticleList()}</StyledArticleListHint>
          </StyledDescription>
        )}

      {reasonId === LANDLORD_DELETE_ACCOUNT && (
        <StyledGdprText>
          <p>{trans('contact-us.landlord.landlord_delete_account.text.1')}</p>
          <p>{trans('contact-us.landlord.landlord_delete_account.text.2')}</p>
        </StyledGdprText>
      )}

      {
        <FormProvider
          disabled={!recaptchaPassed}
          schema={formSchema}
          onSubmit={handleFormSubmit}
          onError={handleFormError}
        >
          {({ values }) => (
            <>
              {formFields
                .map(fieldId => ({
                  id: fieldId,
                  ...FORM_FIELDS[fieldId]
                }))
                .filter(fieldConfig => {
                  const fieldCondition = conditions[fieldConfig.id];
                  if (fieldCondition) {
                    const { compare, dependsOn } = fieldCondition;
                    const compareArgs = dependsOn.map(dep => values[dep]);
                    return compare(...compareArgs);
                  }
                  return true;
                })
                .map(({ id, type, props }) => {
                  const Component = FORM_COMPONENTS[type].component;
                  return (
                    <Component
                      id={id}
                      key={id}
                      name={id}
                      title={trans(`contact-us.field.${id}.title`)}
                      placeholder={trans(`contact-us.field.${id}.placeholder`)}
                      {...FORM_COMPONENTS[type].props}
                      {...props}
                      isPublicForm={isPublicForm}
                    />
                  );
                })}
              <StyledRecaptcha>
                <ReCaptcha
                  hl={currentLocale}
                  sitekey={recaptchaSiteKey}
                  onExpired={onCaptchaExpired}
                  onChange={onCaptchaSuccess}
                />
              </StyledRecaptcha>
              <StyledSubmitContainer>
                <StyledSubmitButton data-test="submit-form-button">
                  <FormProvider.Submit isLoading={loading}>
                    {loading
                      ? trans('loading')
                      : trans('contact-us.form.submit')}
                  </FormProvider.Submit>
                </StyledSubmitButton>
              </StyledSubmitContainer>
            </>
          )}
        </FormProvider>
      }
    </StyledContactForm>
  );
};

ContactForm.propTypes = {
  toast: toastShape.isRequired,
  showBackButton: PropTypes.bool,
  showRelatedArticle: PropTypes.bool,
  isPublicForm: PropTypes.bool
};

export default withToast(ContactForm);
