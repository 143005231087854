import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { lighten } from 'polished';

import colors from '../../shared/colors';
import { space, border } from '../../shared/spacing';
import fonts from '../../shared/fonts';

import Card from '../Card';
import { borderGreyLight } from '../../shared/borders';
import {
  check as checkIconUrl,
  error as errorIconUrl
} from '../../shared/base64Urls';

export const Container = styled.div`
  position: relative;

  & + & {
    margin-top: ${space.x2};
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

export const Label = styled(Card)`
  ${({ hasError }) => css`
    display: block;
    width: 100%;
    min-height: 6.4rem;
    padding: 0;
    border: none;
    font-size: ${fonts.sizes.s};
    color: ${colors.grey};
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;

    /* Focus state */
    ${Input}:focus + & {
      ${borderGreyLight(1)};
      background-color: ${lighten(0.19, colors.greyLight)};
    }

    /* Active state */
    ${Input}:active:not(:disabled) + & {
      transform: translateY(1px);
    }

    /* Checked state */
    ${Input}:checked + & {
      border-color: ${colors.green};
    }

    /* Disabled state */
    ${Input}:disabled + & {
      opacity: 0.75;
      cursor: not-allowed;
    }

    /* Error state*/
    ${hasError
      ? `
        ${Input}[aria-invalid="true"] + & {
          border-color: ${colors.error};
        }
      `
      : ''}
  `}
`;

export const Indicator = styled.span`
  ${({ hasError }) => `
    position: relative;
    top: 0.15em;
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    min-height: 1.6rem;
    margin-right: 1rem;
    border: ${border.size} solid ${colors.greyLight};
    border-radius: ${border.radius};
    user-select: none;

    ${Input}[type="radio"] + ${Label} & {
      border-radius: 50%;
    }

    /* Checked state */
    ${Input}:checked + ${Label} & {
      border: none;
      background-color: ${colors.green};
      background-image: url(${checkIconUrl});
      background-size: 62%;
      background-position: 50%;
      background-repeat: no-repeat;
    }

    /* Error state */
    ${
      hasError
        ? `
      ${Input}[aria-invalid="true"] + ${Label} & {
        border: ${border.size} solid ${colors.error};
        background-color: ${colors.error};
        background-image: url(${errorIconUrl});
        background-size: 62%;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    `
        : ''
    }
  `}
`;

export const LabelContent = styled.div`
  ${({ centered }) => `
    flex: 1;
    ${centered ? `padding-right: ${space.x2};` : ''};
    padding-left: ${space.x2};
    ${centered ? 'text-align: center;' : ''};
  `}
`;

export const Title = styled.div`
  font-family: ${fonts.GothamMedium};
  font-size: ${fonts.sizes.m};
  line-height: 1.2;
`;

export const Description = styled.div`
  font-family: ${fonts.Gotham};
  font-size: ${fonts.sizes.s};
  line-height: 1.2;
`;

export const Image = styled.div`
  ${({ url }) => `
    align-self: stretch;
    width: 5.6rem;
    border-radius: ${border.radius};
    background-image: url('${url}');
    background-position: 50%;
    background-size: cover;
  `}
`;

export const iconStyles = css`
  width: 2.8rem;
  height: 2.8rem;
`;

export const IconWrapper = styled.span`
  display: inline-flex;
  width: 2.8rem;
  align-items: center;
  pointer-events: none;

  & > * {
    width: 100%;
    height: auto;
  }
`;
