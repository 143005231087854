import { trans } from '@spotahome/soyuz/client';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';

import CircleCheck from '../../components/icons/CircleCheck';
import Calendar from '../../components/icons/Calendar';
import Verified from '../../components/icons/Verified';

import FeatureBox from '../FeatureBox';

import * as S from './styles';

const featureList = [
  {
    icon: <CircleCheck color={colors.primaryLight} />,
    descriptionKey: 'sahc.sign-up.feature-list.quality-tenants'
  },
  {
    icon: <Calendar color={colors.primaryLight} />,
    descriptionKey: 'sahc.sign-up.feature-list.guaranteed-payment'
  },
  {
    icon: <Verified color={colors.primaryLight} />,
    descriptionKey: 'sahc.sign-up.feature-list.payment-protection'
  }
];

const FeatureBoxSection = () => (
  <S.Container>
    {featureList.map(({ descriptionKey, icon }) => (
      <S.ListItem key={descriptionKey}>
        <FeatureBox icon={icon} description={trans(descriptionKey)} />
      </S.ListItem>
    ))}
  </S.Container>
);

export default FeatureBoxSection;
