import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '../../shared/fonts';
import { space, border } from '../../shared/spacing';
import { mediaQueries } from '../../shared/breakpoints';
import colors from '../../shared/colors';

import ReactPhoneInputWrapper from './ReactPhoneInputWrapper';

const StyledPhoneInput = styled(ReactPhoneInputWrapper)`
  &.react-tel-input > input {
    appearance: none;
    box-shadow: none;
    border-radius: ${border.radius};
    border-color: ${({ hasError }) =>
      hasError ? colors.error : colors.greyLight};
    padding: ${space.x15};
    padding-left: 58px;
    width: 100%;

    &:focus,
    &.open {
      outline: none;
      box-shadow: none;
      border-color: ${colors.primary};
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: ${colors.greyLight};
    }

    &::placeholder {
      font-size: ${fonts.sizes.s};
      color: ${colors.greySeparator};
    }
  }

  ${mediaQueries.md(css`
    &.react-tel-input > input {
      padding: ${space.x125};
      padding-left: 58px;
    }
  `)};
`;

export default StyledPhoneInput;
