import { useState } from 'react';
import * as Yup from 'yup';
import { trans, useSoyuzRequest } from '@spotahome/soyuz/client';

import userClient from '@spotahome/auth-common/src/api/user-client';

import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';

import {
  Container,
  Title,
  Form
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';
import TransitionContainer from '@spotahome/auth-common/src/components/TransitionContainer';

import useForm from '@spotahome/auth-common/src/hooks/useForm';
import { redirectToLogin } from '@spotahome/auth-common/src/lib/url-utils';

import {
  trackRedeemInvitationErrorSubmit,
  trackRedeemInvitationSubmit,
  trackRedeemInvitationSubmitted
} from '@spotahome/auth-common/src/tracking/login';

import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import PasswordCreated from '../PasswordCreated';

import * as S from './styles';

const REDIRECT_TIMEOUT = 3000;

const INITIAL_FORM_VALUES = {
  newPassword: '',
  confirmPassword: ''
};

const FORM_SCHEMA = Yup.object({
  newPassword: Yup.string()
    .min(6, trans('sahc.redeem-invitation.landlord.password.invalid'))
    .required(trans('sahc.redeem-invitation.landlord.password.required'))
});

const RedeemInvitationForm = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [isPasswordCreated, setIsPasswordCreated] = useState(false);
  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    INITIAL_FORM_VALUES,
    FORM_SCHEMA
  );

  const {
    params: { invitationId }
  } = useSoyuzRequest();

  const handleSubmitFormError = error => {
    const errorMessage = trans(
      getErrorTransKey(error, 'sahc.redeem-invitation.something-went-wrong')
    );
    setSubmitErrorMessage(errorMessage);
    trackRedeemInvitationErrorSubmit({ error });
  };

  const onSubmit = async ({ newPassword }, { setIsSubmitting }) => {
    trackRedeemInvitationSubmit();
    setSubmitErrorMessage('');

    try {
      await userClient.redeemInvitation(invitationId, {
        password: newPassword
      });
      setIsPasswordCreated(true);
      trackRedeemInvitationSubmitted();
      redirectToLogin(REDIRECT_TIMEOUT);
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.RelativeContainer>
      <TransitionContainer show={isPasswordCreated}>
        <PasswordCreated />
      </TransitionContainer>
      <TransitionContainer show={!isPasswordCreated} animateOnLoad={false}>
        <Container>
          <Title>{trans('sahc.redeem-invitation.heading')}</Title>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <S.Description>
              {trans('sahc.redeem-invitation.description')}
            </S.Description>
            <InputGroup
              id="newPassword"
              label={trans('sahc.redeem-invitation.new-password.label')}
              error={trans(errors.newPassword)}
            >
              <Input
                id="newPassword"
                name="newPassword"
                data-test="new-password"
                value={values.newPassword}
                onChange={handleChange}
                hasError={errors.newPassword}
                type="password"
                required
              />
            </InputGroup>
            {!isSubmitting && submitErrorMessage && (
              <S.StyledInputError>{submitErrorMessage}</S.StyledInputError>
            )}
            <S.SubmitBtn
              type="submit"
              loading={isSubmitting || isPasswordCreated}
              data-test="set-new-password"
            >
              {trans('sahc.redeem-invitation.submit')}
            </S.SubmitBtn>
          </Form>
        </Container>
      </TransitionContainer>
    </S.RelativeContainer>
  );
};

export default RedeemInvitationForm;
