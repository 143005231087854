import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Description = styled('div')`
  padding-left: ${space.x2};
`;

export { Container, Description };
