import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const ArrowRight = props => (
  <BaseIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M0 9.022V6.977h12l-4-4.09L9 .842 16 8l-7 7.158-1-2.045 4-4.09H0z"
      fill="currentColor"
    />
  </BaseIcon>
);

export default ArrowRight;
