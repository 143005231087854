import PropTypes from 'prop-types';

import { TextFieldInput } from './styles';

function TextField({
  id = false,
  label = false,
  placeholder = false,
  name = false,
  helperText = undefined,
  disabled = false,
  error = false,
  columnPosition = undefined,
  rowPosition = false,
  defaultValue = false,
  value = null,
  onChange = () => {},
  onBlur = () => {},
  ...rest
}) {
  const classesLabel = { root: 'StyledLabel', label__m: 'StyledLabelMedium' };
  const classesTag = {
    root: 'StyledTag',
    tag__s: 'StyledTagSmall',
    tag__m: 'StyledTagMedium'
  };
  const classesParagraph = {
    root: 'StyledParagraph',
    paragraph__m: 'StyledParagraphMedium'
  };

  return (
    <TextFieldInput
      variant="outlined"
      id={id}
      label={label}
      placeholder={placeholder}
      name={name}
      helperText={helperText}
      disabled={disabled}
      error={error}
      columnPosition={columnPosition}
      rowPosition={rowPosition}
      fullWidth
      InputLabelProps={{
        className: `${classesTag.root} ${classesTag.tag__s}`,
        shrink: true
      }}
      inputProps={{
        className: disabled
          ? `${classesParagraph.root} ${classesParagraph.paragraph__m}`
          : `${classesLabel.root} ${classesLabel.label__m}`,
        'data-test': name
      }}
      FormHelperTextProps={{
        className: `${classesTag.root} ${classesTag.tag__m}`
      }}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  );
}

TextField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  columnPosition: PropTypes.oneOf(['left', 'center', 'right']),
  rowPosition: PropTypes.oneOf(['top', 'center', 'bottom'])
};

export default TextField;
