import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

export const Container = styled('div', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'mode'
})`
  ${({ mode }) =>
    mode === 'fieldset'
      ? `
          display: block;
          padding: 0;
          border: none;
          margin-right: 0;
          margin-left: 0;
        `
      : ''};
  margin-bottom: ${space.x2};
`;

export const Content = styled.div`
  &:not(:last-child) {
    margin-bottom: ${space.x1};
  }
`;
