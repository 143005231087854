import { gql } from '@apollo/client';

const LANDLORD_CREATE_ACCOUNT_MUTATION = gql`
  mutation LandlordCreateAccount($input: LandlordCreateAccountInput!) {
    landlordCreateAccount(input: $input) {
      id
    }
  }
`;

export default LANDLORD_CREATE_ACCOUNT_MUTATION;
