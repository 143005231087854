/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { trans } from '@spotahome/soyuz/client';

import {
  FormSelect,
  FormInput,
  FormDate,
  FormPhoneInput,
  Autocompleter
} from '@spotahome/ui-library';
import RedesignButton from '@spotahome/landlord-panel-ui-library/src/components/RedesignButton';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import * as yup from 'yup';

import { ALL_CITIES_QUERY } from '../../../graphql/cities/queries/cities';
import { FEATURED_CITIES } from '../constants';

import FormError from './FormError';
import { StyledField, StyledAutocompleter } from './styles';

const FormContext = createContext('FormContext');

const FormProvider = ({
  onSubmit = () => {},
  onChange = null,
  onError = () => {},
  schema,
  children,
  disabled = false,
  autocomplete = 'off'
}) => {
  const [values, setValues] = useState({});
  const [errors, setFormErrors] = useState({});

  const validateField = async ({ name, value }) => {
    try {
      await yup.reach(schema, name).validate(value);
      setFormErrors({
        ...errors,
        [name]: undefined
      });
    } catch (error) {
      const { message } = error;
      setFormErrors({
        ...errors,
        [name]: message
      });
    }
  };

  const handleInputChange = async ({ value, name }) => {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    // if the field has an error, check if new value removes it
    if (errors[name]) {
      await validateField({ name, value });
    }

    if (onChange) {
      onChange(value, name);
    }
  };

  const handleInputBlur = async ({ value, name }) => {
    await validateField({ name, value });
    try {
      await yup.reach(schema, name).validate(value);
      setFormErrors({
        ...errors,
        [name]: undefined
      });
    } catch (error) {
      const { message } = error;
      setFormErrors({
        ...errors,
        [name]: message
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (disabled) {
      return;
    }

    try {
      await schema.validate(values, { abortEarly: false });
      onSubmit(values);
    } catch (error) {
      const newErrors = {};
      if (error && error.inner) {
        error.inner.forEach(errorItem => {
          newErrors[errorItem.path] = errorItem.message;
        });
        setFormErrors(newErrors);
      }
      onError();
    }
  };

  const value = {
    errors,
    values,
    disabled,
    handleInputChange,
    handleInputBlur,
    handleSubmit
  };

  return (
    <FormContext.Provider value={value}>
      <form onSubmit={handleSubmit} autoComplete={autocomplete}>
        {typeof children === 'function' ? children({ values }) : children}
      </form>
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  schema: PropTypes.shape({
    validate: PropTypes.func
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string
};

FormProvider.Input = ({
  id = 'form-input',
  name,
  title = '',
  placeholder = '',
  type = 'input',
  rows = 1
}) => {
  const { handleInputChange, handleInputBlur, errors } =
    useContext(FormContext);

  return (
    <StyledField>
      <FormInput
        id={id}
        title={title}
        name={name}
        type={type}
        rows={rows}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required
      />
      <FormError error={trans(errors[name])} />
    </StyledField>
  );
};

FormProvider.Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'password',
    'email',
    'number',
    'tel'
  ]),
  placeholder: PropTypes.string,
  rows: PropTypes.number
};

FormProvider.Date = ({ name, title = '', placeholder = '' }) => {
  const { handleInputChange, handleInputBlur, errors, values } =
    useContext(FormContext);

  return (
    <StyledField>
      <FormDate
        title={title}
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </StyledField>
  );
};

FormProvider.Date.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string
};

FormProvider.Submit = ({ isLoading, children }) => {
  const { disabled } = useContext(FormContext);

  return (
    <StyledField>
      <RedesignButton
        disabled={disabled || isLoading}
        data-test="form-submit"
        size="big"
        isFormSubmit
      >
        <Typography variant="LabelS">{children}</Typography>
      </RedesignButton>
    </StyledField>
  );
};

FormProvider.Submit.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

FormProvider.Select = ({ name, title = '', placeholder = '', options }) => {
  const { handleInputChange, handleInputBlur, errors, values } =
    useContext(FormContext);

  return (
    <StyledField>
      <FormSelect
        name={name}
        options={options.map(({ value, text, locoKey }) => ({
          value,
          text: text || trans(locoKey)
        }))}
        title={title}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </StyledField>
  );
};

FormProvider.Select.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      locoKey: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired
};

FormProvider.CityAutocompleter = ({
  name,
  placeholder = '',
  isPublicForm = false
}) => {
  const { values, handleInputBlur, errors, handleInputChange } =
    useContext(FormContext);
  const [availableCities, setAvailableCities] = useState([]);

  const getDataFromCity = city =>
    availableCities.find(
      cityData => city === cityData.translations.cityLongName
    );

  const handleCitySelected = citySelected => {
    handleInputChange({ value: getDataFromCity(citySelected), name });
  };

  const graphQLUri = isPublicForm
    ? '/landlord/public/graphql'
    : '/landlord/graphql';

  const { data: citiesResponse } = useQuery(ALL_CITIES_QUERY, {
    context: { uri: graphQLUri }
  });

  useEffect(() => {
    if (citiesResponse === undefined) {
      return;
    }

    setAvailableCities(citiesResponse.cities);
  }, [citiesResponse]);

  return (
    <StyledField>
      <StyledAutocompleter
        list={availableCities.map(city => city.translations.cityLongName)}
        placeholder={placeholder}
        typingPlaceholder={trans('autocompleter.placeholder.typing-hint')}
        dataTest="city-selector-autocomplete"
        onSelectedElement={handleCitySelected}
        onBlur={handleInputBlur}
        name={name}
        initialValue={
          values[name] ? values[name].translations.cityLongName : ''
        }
        featuredResults={availableCities
          .filter(city => FEATURED_CITIES.includes(city.id))
          .map(city => city.translations.cityLongName)}
      />
      <FormError error={trans(errors[name])} />
    </StyledField>
  );
};

FormProvider.CityAutocompleter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isPublicForm: PropTypes.bool
};

FormProvider.PhoneInput = ({ id, name, title, required }) => {
  const { handleInputChange, handleInputBlur, errors, values } =
    useContext(FormContext);

  return (
    <StyledField>
      <FormPhoneInput
        id={id}
        name={name}
        title={title}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required={required}
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </StyledField>
  );
};

FormProvider.PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};

export default FormProvider;
