import styled from '@emotion/styled';

import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import {
  formContainerStyles,
  SubmitButton
} from '@spotahome/auth-common/src/components/Form';

export const DescriptionContainer = styled.div`
  ${formContainerStyles()}
`;

export const Description = styled.p`
  font-size: ${fonts.sizes.s};
  line-height: ${fonts.lineHeights.regular};
  margin: 0;
`;

export const BackToLoginBtn = styled(SubmitButton)``;
