import { gql } from '@apollo/client';

export const ALL_CITIES_QUERY = gql`
  query allCities {
    cities {
      id
      translations {
        cityLongName
      }
    }
  }
`;

export const ENABLED_CITIES_QUERY = gql`
  query enabledCities($locale: String!) {
    cities(input: { enabled: true, locale: $locale }) {
      id
      translations {
        cityShortName
      }
    }
  }
`;
