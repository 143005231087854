import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

const Container = styled('div')``;

const ListItem = styled('div')`
  :not(:last-child) {
    padding-bottom: ${space.x8};
  }
`;

export { Container, ListItem };
