import PropTypes from 'prop-types';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const DEFAULT_CLASS_NAME = 'react-tel-input';

const ReactPhoneInputWrapper = ({ className = null, ...restProps }) => (
  <ReactPhoneInput
    {...restProps}
    containerClass={`${className} ${DEFAULT_CLASS_NAME}`}
  />
);

ReactPhoneInputWrapper.propTypes = {
  className: PropTypes.string
};

export default ReactPhoneInputWrapper;
