import PropTypes from 'prop-types';

import colors from '../../shared/colors';

import * as S from './styles';

const CardCTA = ({ icon, title, text = null, url, ...restProps }) => (
  <S.Container fullwidth={text} href={url} {...restProps}>
    <S.IconWrapper>{icon}</S.IconWrapper>
    <S.ContentWrapper>
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
        <S.RightArrow color={colors.primary} />
      </S.TitleContainer>
      {text && <S.TextContainer>{text}</S.TextContainer>}
    </S.ContentWrapper>
  </S.Container>
);

CardCTA.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default CardCTA;
