import PropTypes from 'prop-types';

import * as S from './styles';

const BackButton = ({ text, ...rest }) => (
  <S.Link {...rest}>
    <S.Content>
      <S.ArrowLeft />
      <S.Text>{text}</S.Text>
    </S.Content>
  </S.Link>
);

BackButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default BackButton;
