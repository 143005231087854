import styled from '@emotion/styled';
import { css } from '@emotion/react';

import colors from '../../../../shared/colors';
import { border, space } from '../../../../shared/spacing';
import fonts from '../../../../shared/fonts';

const ArrowIconWrapper = styled.span`
  position: absolute;
  top: ${space.x1};
  z-index: 0;
  font-size: ${fonts.sizes.s};
  ${({ isBordered: isDropdownButton }) =>
    isDropdownButton
      ? css`
          right: ${space.x15};
          margin-right: 1px; // adjust for the border
          margin-top: 1px; // adjust for the border
        `
      : css`
          top: ${space.x15};
          right: ${space.x125};
        `};
`;

const NativeSelectContainer = styled.div`
  position: relative;
  outline: 0;
  ${({ isDropdownButton }) =>
    isDropdownButton
      ? css`
          width: 100%;
        `
      : css`
          display: inline-block;
        `}
`;

const StyledSelect = styled.select`
  font-size: ${fonts.sizes.s};
  padding: ${space.x15};
  appearance: none;
  background: none;
  outline: 0;

  ${({ isDropdownButton }) =>
    isDropdownButton
      ? css`
          width: 100%;
          background: ${colors.white};
          border: 1px solid ${colors.greyLight};
          border-radius: ${border.radius};
          padding-right: ${space.x4};
        `
      : css`
          border: none;
        `}
`;

export { ArrowIconWrapper, StyledSelect, NativeSelectContainer };
