import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const HomeKey = props => (
  <BaseIcon width="44" height="44" viewBox="0 0 44 45" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill="none">
      <path
        d="M27.617 41.86H2.647V18.08L22 3.49l19.35 14.59v23.78H27.618zm15.855-25.5L22.798.77a1.314 1.314 0 00-1.595 0L.527 16.36c-.33.25-.527.65-.527 1.06v25.76c0 .73.593 1.33 1.325 1.33h41.35c.73 0 1.325-.6 1.325-1.33V17.42c0-.41-.198-.81-.528-1.06z"
        fill="currentColor"
      />
      <path
        d="M13.798 21.12a6.574 6.574 0 0110.138 3.738H34v3.285l-1.643 1.643-1.643-1.643-1.643 1.643-1.642-1.643h-3.493a6.574 6.574 0 01-10.138 3.738A6.564 6.564 0 0111 26.501a6.564 6.564 0 012.798-5.382zm1.194 2.23a3.287 3.287 0 00.937 6.436 3.286 3.286 0 10-.937-6.436zm10.65 7.293a9.071 9.071 0 11-8.072-13.214 9.068 9.068 0 018.073 4.928H34a2.5 2.5 0 012.5 2.5v3.286a2.5 2.5 0 01-.732 1.768l-1.643 1.643a2.5 2.5 0 01-3.41.116 2.5 2.5 0 01-3.411-.116l-.911-.911h-.75zm-9.713-3.357a.786.786 0 100-1.572.786.786 0 000 1.572z"
        fill="currentColor"
      />
    </g>
  </BaseIcon>
);

export default HomeKey;
