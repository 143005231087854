// TODO: change this when @usersaurus merge the PR

export const MODIFY_PICTURES = 'modify_pictures';
export const LISTING_MODIFICATION = 'listing_modification';
export const UNPUBLISH_REQUEST = 'unpublish_request';
export const ACTIVATE_INSTANT_BOOKING = 'activate_instant_booking';
export const BOOKING_STATUS = 'booking_status';
export const TENANT_COMPLAINT = 'tenant_complaint';
export const LANDLORD_DATES_MODIFICATION = 'landlord_dates_modification';
export const LANDLORD_BC_CANCELLATION = 'landlord_bc_cancellation';
export const PAYMENT_MISSING = 'payment_missing';
export const CLAIM_GUARANTEE = 'claim_guarantee';
export const LL_PANEL_ACCESS = 'llpanel_access';
export const LL_PANEL_BUG = 'llpanel_bug';
export const LL_PANEL_DOUBTS = 'llpanel_doubts';
export const COMMERCIAL_ADVICE = 'commercial_advice';
export const LANDLORD_ACCOUNT_ACCESS = 'landlord_account_access';
export const LANDLORD_CHANGE_CONTACT_DETAILS =
  'landlord_change_contact_details';
export const LANDLORD_DELETE_ACCOUNT = 'landlord_delete_account';
export const PUBLISH_PROPERTIES = 'publish_properties';
export const UPDATE_AVAILABILITY = 'update_availability';
export const SETUP_LL_POLICIES = 'setup_ll_policies';
export const LANDLORD_REQUEST_INVOICE = 'landlord_request_invoice';

export const LANDLORD_REASON_IDS = [
  MODIFY_PICTURES,
  LISTING_MODIFICATION,
  UNPUBLISH_REQUEST,
  ACTIVATE_INSTANT_BOOKING,
  BOOKING_STATUS,
  TENANT_COMPLAINT,
  LANDLORD_DATES_MODIFICATION,
  LANDLORD_BC_CANCELLATION,
  PAYMENT_MISSING,
  CLAIM_GUARANTEE,
  LL_PANEL_ACCESS,
  LL_PANEL_BUG,
  LL_PANEL_DOUBTS,
  COMMERCIAL_ADVICE,
  LANDLORD_ACCOUNT_ACCESS,
  LANDLORD_CHANGE_CONTACT_DETAILS,
  LANDLORD_DELETE_ACCOUNT,
  PUBLISH_PROPERTIES,
  UPDATE_AVAILABILITY,
  SETUP_LL_POLICIES,
  LANDLORD_REQUEST_INVOICE
];

export const LANDLORD_REASONS_CONFIG = {
  [MODIFY_PICTURES]: {
    subject: {
      prefix: 'I want to modify my pictures',
      suffix: 'city'
    },
    tags: ['new_pictures']
  },
  [LISTING_MODIFICATION]: {
    subject: {
      prefix: 'I want to modify my listing',
      suffix: 'city'
    },
    tags: ['new_policies']
  },
  [UNPUBLISH_REQUEST]: {
    subject: {
      prefix: 'I want to unpublish',
      suffix: 'city'
    },
    tags: ['deactivate_listing']
  },
  [ACTIVATE_INSTANT_BOOKING]: {
    subject: {
      prefix: 'I want to activate the Instant Booking',
      suffix: 'city'
    },
    tags: ['add_instantbooking']
  },
  [BOOKING_STATUS]: {
    subject: {
      prefix: 'I want information about a booking status',
      suffix: 'referenceCode'
    },
    tags: ['br_status']
  },
  [TENANT_COMPLAINT]: {
    subject: {
      prefix: "I am experiencing issue with my tenant's behaviour",
      suffix: 'referenceCode'
    },
    tags: ['ll_personal_conflict']
  },
  [LANDLORD_DATES_MODIFICATION]: {
    subject: {
      prefix: 'I need to modify the dates of a booking',
      suffix: 'referenceCode'
    },
    tags: ['modify_checkin_date']
  },
  [LANDLORD_BC_CANCELLATION]: {
    subject: {
      prefix: 'I want to cancel a booking',
      suffix: 'referenceCode'
    },
    tags: ['ll_bc_cancelled']
  },
  [PAYMENT_MISSING]: {
    subject: {
      prefix: 'I am missing a payment',
      suffix: 'referenceCode'
    },
    tags: ['missing_payment']
  },
  [CLAIM_GUARANTEE]: {
    subject: {
      prefix: 'I want to claim my guarantee',
      suffix: 'referenceCode'
    },
    tags: ['guarantee']
  },
  [LL_PANEL_ACCESS]: {
    subject: {
      prefix: 'I can not access my LL panel',
      suffix: 'city'
    },
    tags: ['access_issues']
  },
  [LL_PANEL_BUG]: {
    subject: {
      prefix: 'I am experiencing a bug with my Landlord Panel',
      suffix: 'landlord'
    },
    tags: ['ll_panel_bug']
  },
  [COMMERCIAL_ADVICE]: {
    subject: {
      prefix: 'I need help setting the price of my property',
      suffix: 'city'
    },
    tags: ['price_recommendations']
  },
  [LANDLORD_ACCOUNT_ACCESS]: {
    subject: {
      prefix: 'I can not access my account',
      suffix: 'landlord'
    },
    tags: ['access_issues']
  },
  [LANDLORD_CHANGE_CONTACT_DETAILS]: {
    subject: {
      prefix: 'I want to update my contact details',
      suffix: 'landlord'
    },
    tags: ['update_contact_details']
  },
  [LANDLORD_DELETE_ACCOUNT]: {
    subject: {
      prefix: 'I want to delete my account',
      suffix: 'landlord'
    },
    tags: ['account_deactivation']
  },
  [PUBLISH_PROPERTIES]: {
    subject: {
      prefix: 'I want to publish my properties',
      suffix: 'city'
    },
    tags: ['lead_landlord']
  },
  [SETUP_LL_POLICIES]: {
    subject: {
      prefix: 'I need help to set my landlord policies',
      suffix: 'city'
    },
    tags: ['policy_recommendations']
  },
  [LANDLORD_REQUEST_INVOICE]: {
    subject: {
      prefix: 'I need an invoice',
      suffix: 'referenceCode'
    },
    tags: ['invoice_request']
  }
};

export default {
  ACTIVATE_INSTANT_BOOKING,
  BOOKING_STATUS,
  CLAIM_GUARANTEE,
  COMMERCIAL_ADVICE,
  LANDLORD_ACCOUNT_ACCESS,
  LANDLORD_BC_CANCELLATION,
  LANDLORD_CHANGE_CONTACT_DETAILS,
  LANDLORD_DATES_MODIFICATION,
  LANDLORD_DELETE_ACCOUNT,
  LISTING_MODIFICATION,
  LL_PANEL_ACCESS,
  LL_PANEL_BUG,
  LL_PANEL_DOUBTS,
  MODIFY_PICTURES,
  PAYMENT_MISSING,
  PUBLISH_PROPERTIES,
  SETUP_LL_POLICIES,
  TENANT_COMPLAINT,
  UNPUBLISH_REQUEST,
  UPDATE_AVAILABILITY,
  LANDLORD_REASON_IDS,
  LANDLORD_REQUEST_INVOICE
};
