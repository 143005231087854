import { memo } from 'react';
import PropTypes from 'prop-types';
import { trans, withLocales } from '@spotahome/soyuz/client';
import { localesShape } from '@spotahome/soyuz/shapes';
import Cookies from 'universal-cookie';

import { setRouteLocale } from '../../router';

import * as S from './styles';

const LanguageSwitcher = memo(({ locales, theme }) => {
  const supportedLocales = [...Object.keys(locales.ui)];
  const currentLocale = locales.current;

  const setLocaleCookie = selectedLocale => {
    const cookies = new Cookies();
    const options = { path: '/', maxAge: 2628000 }; // expires after one month
    return cookies.set('locale', selectedLocale, options);
  };

  const handlerOnChange = event => {
    const selectedLocale = event.target.value;
    setLocaleCookie(selectedLocale);
    window.location.pathname = setRouteLocale(selectedLocale);
  };

  return (
    <S.LanguageSwitcherContainer theme={theme}>
      <S.TextContainer>
        <S.Menu>{currentLocale}</S.Menu>
        <S.ArrowDownIcon />
      </S.TextContainer>
      <S.LanguageSwitcherSelect
        defaultValue={currentLocale}
        onChange={handlerOnChange}
      >
        {supportedLocales.sort().map(locale => (
          <option key={locale} value={locale}>
            {trans(`locale.${locale}`)}
          </option>
        ))}
      </S.LanguageSwitcherSelect>
    </S.LanguageSwitcherContainer>
  );
});

LanguageSwitcher.defaultProps = {
  theme: 'default'
};

LanguageSwitcher.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired,
  theme: PropTypes.oneOf(['default', 'dark', 'light'])
};

export default withLocales()(LanguageSwitcher);
