import PropTypes from 'prop-types';

import StyledPhoneInput from './styles';

const PhoneInput = ({
  country = '',
  onlyCountries = [],
  preferredCountries = [],
  excludeCountries = [],
  placeholder = '',
  searchPlaceholder = '',
  inputProps = {},
  autoFormat = undefined,
  disableDropdown = undefined,
  disableCountryCode = undefined,
  enableAreaCodes = undefined,
  enableLongNumbers = undefined,
  enableSearch = undefined,
  disableSearchIcon = undefined,
  hasError = false,
  ...restProps
}) => {
  return (
    <StyledPhoneInput
      country={country}
      onlyCountries={onlyCountries}
      preferredCountries={preferredCountries}
      excludeCountries={excludeCountries}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      inputProps={inputProps}
      autoFormat={autoFormat}
      disableDropdown={disableDropdown}
      disableCountryCode={disableCountryCode}
      enableAreaCodes={enableAreaCodes}
      enableLongNumbers={enableLongNumbers}
      enableSearch={enableSearch}
      disableSearchIcon={disableSearchIcon}
      hasError={hasError}
      {...restProps}
    />
  );
};

PhoneInput.propTypes = {
  /** initial country */
  country: PropTypes.string,
  /** country codes to be included */
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  /** country codes to be at the top */
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  /** array of country codes to be excluded */
  excludeCountries: PropTypes.arrayOf(PropTypes.string),
  /** custom placeholder */
  placeholder: PropTypes.string,
  /** custom search placeholder */
  searchPlaceholder: PropTypes.string,
  /** props to pass into the input */
  inputProps: PropTypes.object,
  /** on/off phone formatting */
  autoFormat: PropTypes.bool,
  /** disable dropdown */
  disableDropdown: PropTypes.bool,
  /** disable countrycode */
  disableCountryCode: PropTypes.bool,
  /** enable local area codes for all countries */
  enableAreaCodes: PropTypes.bool,
  /** enable long numbers */
  enableLongNumbers: PropTypes.bool,
  /** enable search in the dropdown */
  enableSearch: PropTypes.bool,
  /** hide icon for the search field */
  disableSearchIcon: PropTypes.bool,
  /** Indicates whether the input has an error */
  hasError: PropTypes.bool
};

export default PhoneInput;
