const STATES_CONFIG = {
  init: {
    on: {
      SELECT: 'show_sections'
    }
  },
  show_sections: {
    on: {
      BACK: 'init',
      SELECT: 'show_form'
    }
  },
  show_form: {
    on: {
      BACK: 'show_sections',
      NEXT: 'show_success'
    }
  },
  show_success: {
    on: {
      END: 'init'
    }
  }
};

export default STATES_CONFIG;
