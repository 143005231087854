const ERROR_DEFAULT_TRANS_KEY = 'landlord.signup.something-went-wrong';

export const getErrorTransKey = (
  error,
  fallbackKey = ERROR_DEFAULT_TRANS_KEY
) => {
  if (error?.response?.status === 429) {
    return 'landlord.signup.too-many-requests';
  }
  if (
    error?.response?.data?.error === 'landlord.create_account.already_exists'
  ) {
    return 'landlord.create_account.already_exists';
  }

  return error?.response?.data?.detail || fallbackKey;
};

export default { getErrorTransKey };
