import { measureDomNode } from '../../../../utils/dom';
import { space } from '../../../../shared/spacing';
import fonts from '../../../../shared/fonts';

export function measureSelectMenuWidth(options) {
  let innerHtml = '';
  options.forEach(option => {
    innerHtml += `<div>${option.label}</div>`;
  });
  const node = document.createElement('div');
  node.innerHTML = innerHtml;
  const style = `
    position: absolute;
     /* this is more or less the space.x1 + 2 borders size */ 
    padding: ${space.x125};
    font-size: ${fonts.sizes.s};
    `;
  const measure = measureDomNode(node, style);
  return measure.width;
}

export function isValueControlled(value) {
  return value !== undefined;
}

export function firstOption(options) {
  return options.length > 0 ? options[0] : null;
}

export function firstOptionValue(options) {
  const option = firstOption(options);
  return option ? option.value : null;
}

export function firstOptionLabel(options) {
  const option = firstOption(options);
  return option ? option.label : null;
}

export function findLabel(value, options) {
  const filteredOptions = options.filter(option => option.value === value);
  return firstOptionLabel(filteredOptions);
}

export function defaultValue(value, options, placeholder) {
  if (value === null || value === undefined) {
    if (placeholder) return null;
    return firstOptionValue(options);
  }
  return value;
}
