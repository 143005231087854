import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from '@apollo/client';

import { trans, useSoyuzUser } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';
import RedesignButton from '@spotahome/landlord-panel-ui-library/src/components/RedesignButton';

import TextField from '../../Redesign/TextField';

import { trackDeleteAccountConfirmation } from '../../../lib/segment/settings';

import LANDLORD_DISABLE_ACCOUNT_MUTATION from '../../../graphql/landlord/mutation/disableLandlord';

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogActions,
  StyledCloseButton,
  StyledAvatar,
  StyledTitle,
  StyledSubtitle,
  StyledLabel,
  StyledInputWrapper,
  StyledButtonWrapper
} from './styles';
import ProfileSvg from './ProfileSvg';

function DeleteAccountModal({
  modalOpen,
  onClose,
  landlordEmail,
  handleOnDeleteAccount = null,
  titleText = trans('sahc.settings.deleteAccount.modal.title'),
  confirmButtonText = trans('sahc.settings.deleteAccount.modal.submit')
}) {
  const [email, setEmail] = useState('');

  const [disableLandlordAccount] = useMutation(
    LANDLORD_DISABLE_ACCOUNT_MUTATION
  );
  const user = useSoyuzUser();

  const handleOnDelete = async () => {
    await disableLandlordAccount();
    if (handleOnDeleteAccount) {
      await handleOnDeleteAccount();
    }
    trackDeleteAccountConfirmation(user);
    window.location = '/api/logout';
  };

  return (
    <StyledDialog open={modalOpen} onClose={onClose}>
      <StyledDialogContent>
        <StyledCloseButton>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </StyledCloseButton>
        <StyledAvatar>
          <ProfileSvg />
        </StyledAvatar>
        <StyledTitle>
          <Typography variant="HeadingL">{titleText}</Typography>
        </StyledTitle>
        <StyledSubtitle>
          <Typography variant="ParagraphL">
            {trans('sahc.settings.deleteAccount.modal.subtitle')}
          </Typography>
        </StyledSubtitle>
        <StyledLabel>
          <Typography variant="HeadingS">
            {trans('sahc.settings.deleteAccount.modal.label')}
          </Typography>
        </StyledLabel>
        <StyledInputWrapper>
          <TextField
            label={'Email'}
            defaultValue={''}
            name="bankAccountName"
            onChange={event => setEmail(event.target.value)}
            error={false}
          />
        </StyledInputWrapper>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButtonWrapper>
          <RedesignButton type={'tertiary'} onClick={onClose} fullWidth>
            <Typography variant="LabelS">
              {trans('sahc.settings.deleteAccount.modal.stay')}
            </Typography>
          </RedesignButton>
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <RedesignButton
            type={'primary'}
            onClick={handleOnDelete}
            disabled={email.trim() !== landlordEmail}
            fullWidth
          >
            <Typography variant="LabelS" color={'white'}>
              {confirmButtonText}
            </Typography>
          </RedesignButton>
        </StyledButtonWrapper>
      </StyledDialogActions>
    </StyledDialog>
  );
}

DeleteAccountModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  landlordEmail: PropTypes.string.isRequired,
  handleOnDeleteAccount: PropTypes.func,
  titleText: PropTypes.string,
  confirmButtonText: PropTypes.string
};

export default DeleteAccountModal;
