import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import NativeSelect from '../NativeSelect/NativeSelect';
import optionShape from '../../shapes/option';

import debounceThrottle from '../../../../utils/debounce-throttle';
import device from '../../../../shared/device';

import SelectControl from './SelectControl';
import {
  modeTypes,
  modeTypesArray,
  touchModeTypes,
  touchModeTypesArray
} from './mode-types';

/**
 * Calculates the final mode of the select
 * @param mode
 * @param touchMode
 * @returns {*}
 */
function calculateMode(mode, touchMode) {
  if (touchMode === touchModeTypes.INHERITED) return mode;

  return device.isTouchDevice() ? modeTypes.NATIVE : mode;
}

/**
 * Select wrapper over the real control
 */
class Select extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { mode, touchMode } = nextProps;
    let nextState = {};

    if (mode !== state.mode || touchMode !== state.touchMode) {
      nextState = { ...nextState, mode: calculateMode(mode, touchMode) };
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }
  state = {
    mode: calculateMode(this.props.mode, this.props.touchMode)
  };

  constructor(props) {
    super(props);

    this.setModeProxy = debounceThrottle(this.setMode);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setModeProxy);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setModeProxy);
  }

  setMode = () => {
    const { mode, touchMode } = this.props;
    this.setState(() => ({
      mode: calculateMode(mode, touchMode)
    }));
  };

  get isNativeMode() {
    return this.state.mode === modeTypes.NATIVE;
  }

  render() {
    const {
      placeholder,
      isOptionDisabled,
      value,
      onChange,
      options,
      portalTargetSelector,
      ...htmlProps
    } = this.props;

    const { mode } = this.state;

    return (
      <Fragment>
        {this.isNativeMode ? (
          <NativeSelect
            placeholder={placeholder}
            isOptionDisabled={isOptionDisabled}
            value={value}
            dropdownButton={this.props.mode === modeTypes.DROPDOWN}
            onChange={onChange}
            options={options}
            {...htmlProps}
          />
        ) : (
          <SelectControl
            mode={mode}
            placeholder={placeholder}
            isOptionDisabled={isOptionDisabled}
            value={value}
            onChange={onChange}
            options={options}
            portalTargetSelector={portalTargetSelector}
            {...htmlProps}
          />
        )}
      </Fragment>
    );
  }
}

Select.defaultProps = {
  mode: modeTypes.SELECT,
  touchMode: touchModeTypes.NATIVE,
  options: [],
  value: undefined,
  placeholder: '',
  isOptionDisabled: undefined,
  onChange: undefined,
  portalTargetSelector: ''
};

Select.propTypes = {
  /** Mode in which the select is displayed */
  mode: PropTypes.oneOf(modeTypesArray),
  /** Mode in which the select is displayed on touch device */
  touchMode: PropTypes.oneOf(touchModeTypesArray),
  /** Array of options values */
  options: PropTypes.arrayOf(optionShape),
  /** Controlled prop for the value of the select */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The initial placeholder for the select */
  placeholder: PropTypes.string,
  /** Function which receives every value of the select to check if is disabled or not */
  isOptionDisabled: PropTypes.func,
  /** Callback for the value change */
  onChange: PropTypes.func,
  /**
   * If the select should use a portal anchored to the selector, body (useful for overflow containers)
   *
   * This options is disabled when the select is in 'native' mode
   */
  portalTargetSelector: PropTypes.string
};

Select.modeTypes = modeTypes;
Select.modeTypesArray = modeTypesArray;
Select.touchModeTypes = touchModeTypes;
Select.touchModeTypesArray = touchModeTypesArray;

export default Select;
