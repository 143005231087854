import PropTypes from 'prop-types';

import InputLabel from '../InputLabel';
import InputHint from '../InputHint';
import InputError from '../InputError';

import * as S from './styles';

function InputGroup({
  children,
  mode = 'normal',
  id = undefined,
  label = null,
  description = null,
  hint = null,
  error = null,
  labelProps = {},
  contentProps = {},
  ...restProps
}) {
  const containerTag = mode === 'fieldset' ? 'fieldset' : 'div';
  const labelTag = mode === 'fieldset' ? 'legend' : 'label';

  return (
    <S.Container as={containerTag} mode={mode} {...restProps}>
      <InputLabel
        as={labelTag}
        htmlFor={id}
        {...labelProps}
        description={description}
      >
        {label}
      </InputLabel>
      <S.Content {...contentProps}>{children}</S.Content>
      {error && <InputError>{error}</InputError>}
      {hint && <InputHint>{hint}</InputHint>}
    </S.Container>
  );
}

InputGroup.propTypes = {
  /** Normally a input or group of inputs. */
  children: PropTypes.node.isRequired,
  /** The fieldset mode should be used with checkboxes and radio buttons, among other special cases. */
  mode: PropTypes.oneOf(['normal', 'fieldset']),
  /** This id will be used to add the "for" HTML attribute to the label. */
  id: PropTypes.string,
  /** The label of the field (or legend of the fieldset if mode="fieldset"), normally a string. */
  label: PropTypes.node,
  /** The description of the field. Is less prominent than the label, normally a string. */
  description: PropTypes.node,
  /** Hint of the input, normally a string. */
  hint: PropTypes.node,
  /** Error to be shown, normally a string. */
  error: PropTypes.node,
  /** Props to be spread in the label (or legend of the fieldset if mode="fieldset"). */
  labelProps: PropTypes.object,
  /** Props to be spread in the content element. */
  contentProps: PropTypes.object
};

export default InputGroup;
