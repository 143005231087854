import Tracking from '@spotahome/soyuz-tracking';

import { addDataLayerGAEvent } from '../tracking/common';

const GET_STARTED_USER_IS_TENANT = 'GetStartedUserIsTenant';
const GET_STARTED_USER_IS_LANDLORD = 'GetStartedUserIsLandlord';

const SIGNUP_BUTTON_ACTION_EVENT = 'create-user-account-button';
const SIGNUP_CATEGORY_EVENT = 'landlord-signup';

const USER_CREATION_CLICKED = 'UserCreationClicked';
const USER_CREATION_SUCCESS = 'UserCreationSuccess';

export const trackUserIsTenant = () => {
  Tracking.segment.trackEvent(GET_STARTED_USER_IS_TENANT);
};

export const trackUserIsLandlord = () => {
  Tracking.segment.trackEvent(GET_STARTED_USER_IS_LANDLORD);
};

export const trackUserCreationSuccess = ({ signUpMethod, id }) => {
  // --- TO DEPRECATE --- START
  Tracking.ga.sendEvent(
    SIGNUP_CATEGORY_EVENT,
    SIGNUP_BUTTON_ACTION_EVENT,
    `${signUpMethod}-submit-method`
  );
  // --- TO DEPRECATE --- END
  Tracking.segment.trackEvent(USER_CREATION_SUCCESS, null, {
    signup_method: signUpMethod,
    id
  });
  addDataLayerGAEvent({ eventAction: USER_CREATION_SUCCESS });
};

export const trackUserCreationClicked = ({ signUpMethod }) => {
  // --- TO DEPRECATE --- START
  Tracking.ga.sendEvent(
    SIGNUP_CATEGORY_EVENT,
    SIGNUP_BUTTON_ACTION_EVENT,
    `${signUpMethod}-click`
  );
  // --- TO DEPRECATE --- END
  Tracking.segment.trackEvent(USER_CREATION_CLICKED, null, {
    signup_method: signUpMethod
  });
};
