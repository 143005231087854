import Router from '@spotahome/auth-common/src/router';
import ThemeProvider from '@spotahome/landlord-panel-ui-library/src/components/ThemeProvider';

import routes from './routes';

const Auth = () => (
  <ThemeProvider>
    <Router routes={routes} />;
  </ThemeProvider>
);

Auth.NAME = 'Auth';

export default Auth;
