import { routeIds, getPath } from '@spotahome/auth-common/src/routes/auth';

import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import CannotAccess from './containers/CannotAccess';
import SetPassword from './containers/SetPassword';
import RedeemInvitation from './containers/RedeemInvitation';
import CreateAccountStep from './containers/Signup/CreateAccountStep';
import AdditionalInfoStep from './containers/Signup/AdditionalInfoStep';
import GetStartedStep from './containers/Signup/GetStartedStep';

const routes = [
  {
    id: routeIds.LOGIN,
    path: getPath(routeIds.LOGIN),
    component: Login
  },
  {
    id: routeIds.FORGOT_PASSWORD,
    path: getPath(routeIds.FORGOT_PASSWORD),
    component: ForgotPassword,
    backRoute: routeIds.LOGIN
  },
  {
    id: routeIds.CANNOT_ACCESS,
    path: getPath(routeIds.CANNOT_ACCESS),
    component: CannotAccess,
    backRoute: routeIds.LOGIN
  },
  {
    id: routeIds.SET_PASSWORD,
    path: getPath(routeIds.SET_PASSWORD),
    component: SetPassword
  },
  {
    id: routeIds.REDEEM_INVITATION,
    path: getPath(routeIds.REDEEM_INVITATION),
    component: RedeemInvitation
  },
  {
    id: routeIds.SIGNUP_GET_STARTED,
    path: getPath(routeIds.SIGNUP_GET_STARTED),
    component: GetStartedStep
  },
  {
    id: routeIds.SIGNUP_NEW_ACCOUNT,
    path: getPath(routeIds.SIGNUP_NEW_ACCOUNT),
    component: CreateAccountStep,
    backRoute: routeIds.SIGNUP_GET_STARTED
  },
  {
    id: routeIds.SIGNUP_ADDITIONAL_INFO,
    path: getPath(routeIds.SIGNUP_ADDITIONAL_INFO),
    component: AdditionalInfoStep
  }
];

export default routes;
