import Tracking from '@spotahome/soyuz-tracking';

const LOGIN_BUTTON_CLICKED = 'LoginButtonClicked';

const LOGIN_ERROR_THROWN = 'LoginErrorThrown';

const LOGIN_BUTTON_ACTION_EVENT = 'login-button';

const LOGIN_CATEGORY_EVENT = 'landlord-login';

const FORGOT_PASSWORD_ACTION_EVENT = 'forgot-password';

const CANNOT_ACCESS_LINK_ACTION_EVENT = 'cannot-access-link';
const FORGOT_PASSWORD_BUTTON_CLICKED = 'ForgotPasswordButtonClicked';
const FORGOT_PASSWORD_EMAIL_SUBMIT = 'ForgotPasswordEmailSubmit';

const FORGOT_PASSWORD_ERROR_THROWN = 'ForgotPasswordErrorThrown';

const INVALID_RESET_PASSWORD_TOKEN_USED = 'InvalidResetPasswordTokenUsed';
const RESET_PASSWORD_ERROR_SUBMIT = 'ResetPasswordErrorSubmit';

const REDEEM_INVITATION_ERROR_SUBMIT = 'RedeemInvitationErrorSubmit';
const REDEEM_INVITATION_SUBMIT = 'RedeemInvitationSubmit';
const REDEEM_INVITATION_SUBMITTED = 'RedeemInvitationSubmitted';

export const loginMethods = {
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  GOOGLE: 'google'
};

export const trackLoginButtonClicked = ({ loginMethod }) => {
  Tracking.segment.trackEvent(LOGIN_BUTTON_CLICKED, null, {
    login_method: loginMethod
  });
  Tracking.ga.sendEvent(
    LOGIN_CATEGORY_EVENT,
    LOGIN_BUTTON_ACTION_EVENT,
    `${loginMethod}-click`
  );
};

export const trackLoginErrorThrown = ({ error }) => {
  Tracking.segment.trackEvent(LOGIN_ERROR_THROWN, null, {
    error_type: error.response.data
  });
};

export const trackForgotPasswordClicked = () => {
  Tracking.segment.trackEvent(FORGOT_PASSWORD_BUTTON_CLICKED);

  Tracking.ga.sendEvent(
    LOGIN_CATEGORY_EVENT,
    FORGOT_PASSWORD_ACTION_EVENT,
    'click-link'
  );
};

export const trackCannotAccessClicked = () => {
  Tracking.ga.sendEvent(
    LOGIN_CATEGORY_EVENT,
    FORGOT_PASSWORD_ACTION_EVENT,
    'click-link'
  );

  Tracking.segment.trackEvent(CANNOT_ACCESS_LINK_ACTION_EVENT);
};

export const trackForgotPasswordEmailSubmit = ({ email }) => {
  Tracking.ga.sendEvent(
    LOGIN_CATEGORY_EVENT,
    FORGOT_PASSWORD_ACTION_EVENT,
    'email-submit'
  );

  Tracking.segment.trackEvent(FORGOT_PASSWORD_EMAIL_SUBMIT, null, {
    forgot_password_email: email
  });
};

export const trackForgotPasswordEmailErrorThrown = ({ error }) => {
  Tracking.segment.trackEvent(FORGOT_PASSWORD_ERROR_THROWN, null, {
    error_type: error.response.data
  });
};

export const trackInvalidResetPasswordTokenUsed = ({ error }) => {
  Tracking.segment.trackEvent(INVALID_RESET_PASSWORD_TOKEN_USED, null, {
    error_type: error.response.data
  });
};

export const trackResetPasswordErrorSubmit = ({ error }) => {
  Tracking.segment.trackEvent(RESET_PASSWORD_ERROR_SUBMIT, null, {
    error_type: error.response.data
  });
};

export const trackRedeemInvitationErrorSubmit = ({ error }) => {
  Tracking.segment.trackEvent(REDEEM_INVITATION_ERROR_SUBMIT, null, {
    error_type: error.response.data
  });
};

export const trackRedeemInvitationSubmit = () => {
  Tracking.segment.trackEvent(REDEEM_INVITATION_SUBMIT, null, {});
};

export const trackRedeemInvitationSubmitted = () => {
  Tracking.segment.trackEvent(REDEEM_INVITATION_SUBMITTED, null, {});
};
