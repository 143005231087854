import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import { mediaQueries } from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';
import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';

import TransitionContainer from '../../components/TransitionContainer';

export const LayoutTransitionContainer = styled(TransitionContainer)`
  width: 100%;
`;

export const LayoutTransitionContent = styled.div`
  width: 100%;
  margin: auto;
  padding: ${space.x3} ${space.x2} ${space.x3};

  ${mediaQueries.sm(css`
    max-width: ${pixelsToRem(486)};
  `)}

  ${mediaQueries.lg(css`
    padding: ${pixelsToRem(96)} ${space.x4} ${space.x7};

    @media screen and (min-height: 601px) {
      padding-top: ${pixelsToRem(160)};
    }
  `)}
`;

export const LayoutTransitionContentWrapper = styled.div``;
