import PropTypes from 'prop-types';

const RichTranslation = ({
  trans,
  tag: Tag = 'span',
  translationKey,
  params = {},
  ...rest
}) => (
  <Tag
    {...rest}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: trans(translationKey, params)
    }}
  />
);

RichTranslation.propTypes = {
  /** Translation function that receives the translationKey and returns the actual translation */
  trans: PropTypes.func.isRequired,
  /** The translation key to be translated */
  translationKey: PropTypes.string.isRequired,
  /** An object of params to be replaced in the translation, the key wrapped in {} will be replaced by its value. */
  params: PropTypes.object,
  /** The HTML element to render. 'span' by default. */
  tag: PropTypes.string
};

export default RichTranslation;
