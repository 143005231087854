import { styled } from '@mui/material/styles';
import CardMUI from '@mui/material/Card';
import CardHeaderMUI, { cardHeaderClasses } from '@mui/material/CardHeader';

export const Card = styled(CardMUI)(({ colorModification }) => ({
  backgroundColor: 'var(--sah-secondary-blue-light)',
  boxShadow: 'none',
  borderRadius: '16px',
  ...(colorModification === 'pink' && {
    backgroundColor: 'var(--sah-secondary-pink-light)'
  }),
  ...(colorModification === 'beige' && {
    backgroundColor: 'var(--sah-tertiary)'
  })
}));

export const CardHeader = styled(CardHeaderMUI)({
  [`& .${cardHeaderClasses.action}`]: {
    marginTop: -8,
    marginLeft: 8,
    marginBottom: 0
  },
  [`& .${cardHeaderClasses.avatar}`]: {
    marginRight: 12
  }
});

export const Title = styled('span')({
  color: 'var(--sah-dark)',
  '& > b': {
    fontFamily: "'PoppinsMedium', 'Poppins-fallback'",
    fontWeight: '400'
  }
});
