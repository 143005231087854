import styled from '@emotion/styled';

import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import InputError from '@spotahome/auth-common/src/components/InputError';
import { Container } from '@spotahome/auth-common/src/components/Form';

export const RelativeContainer = styled(Container)`
  position: relative;
`;

export const StyledInputError = styled(InputError)`
  font-size: ${fonts.sizes.m};
`;
