import styled from '@emotion/styled';

import { formContainerStyles } from '@spotahome/auth-common/src/components/Form';
import SocialButton from '@spotahome/auth-common/src/components/SocialButton';

export const SocialSignup = styled.div`
  ${formContainerStyles()}
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FacebookButton = styled(SocialButton)``;

export const GoogleButton = styled(SocialButton)``;
