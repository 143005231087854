import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

function columnBorderRadius(columnPosition) {
  switch (columnPosition) {
    case 'left':
      return {
        radius: '8px 0px 0px 8px',
        width: '1px 0px 1px 1px'
      };
    case 'center':
      return {
        radius: '0px',
        width: '1px 0px 1px 1px'
      };
    case 'right':
      return {
        radius: '0px 8px 8px 0px',
        width: '1px'
      };
    default:
      return {
        radius: '8px',
        width: '1px'
      };
  }
}
function rowBorderRadius(rowPosition) {
  switch (rowPosition) {
    case 'top':
      return {
        radius: '8px 8px 0px 0px',
        width: '1px 1px 0px'
      };
    case 'center':
      return {
        radius: '0px',
        width: '1px 1px 0px'
      };
    case 'bottom':
      return {
        radius: '0px 0px 8px 8px',
        width: '1px'
      };
    default:
      return {
        radius: '8px',
        width: '1px'
      };
  }
}

function getBorderRadius(columnPosition, rowPosition) {
  if (columnPosition) return columnBorderRadius(columnPosition);
  if (rowPosition) return rowBorderRadius(rowPosition);

  return {
    radius: '8px',
    width: '1px'
  };
}

export const TextFieldInput = styled(TextField)(
  ({ columnPosition, rowPosition }) => ({
    '& label.MuiFormLabel-root': {
      fontFamily: "'PoppinsLight', 'Poppins-fallback'",
      fontWeight: '300',
      fontSize: '12px'
    },
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px)'
    },
    '& label.Mui-focused': {
      color: 'var(--sah-medium)'
    },
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: "'PoppinsLight', 'Poppins-fallback'",
      fontWeight: '300',
      fontSize: '14px',
      '& input': {
        padding: '32px 12px 12px',
        '&::placeholder': {
          fontFamily: "'PoppinsLight', 'Poppins-fallback'",
          fontWeight: '300'
        },
        '&.Mui-disabled': {
          color: 'var(--sah-light)'
        }
      },
      '&.Mui-error fieldset': {
        borderWidth: '1px'
      },
      '& fieldset': {
        top: 0,
        borderColor: 'var(--sah-medium)',
        borderRadius: getBorderRadius(columnPosition, rowPosition).radius,
        borderWidth: getBorderRadius(columnPosition, rowPosition).width
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--sah-dark)',
        borderWidth: '2px'
      }
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'none'
    },
    '& .MuiInput-underline:hover:before': {
      border: 0
    },
    '& .MuiInput-underline:after': {
      border: 0
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      fontFamily: "'PoppinsLight', 'Poppins-fallback'",
      fontWeight: '300',
      fontSize: '12px'
    }
  })
);
