import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { trans } from '@spotahome/soyuz/client';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import { Container, Title } from '@spotahome/auth-common/src/components/Form';

import * as S from './styles';

const ForgotPasswordEmailSent = ({ email }) => (
  <Container>
    <Title data-cy="forgot-password-submitted-heading">
      {trans('landlord.login.forgot-password-email-sent.heading')}
    </Title>
    <S.DescriptionContainer>
      <S.Description>
        {trans('landlord.login.forgot-password-email-sent.description', {
          email
        })}
      </S.Description>
      <S.BackToLoginBtn
        data-cy="forgot-password-submitted-back-login"
        as={Link}
        to={getRoutePath('landlord.login')}
      >
        {trans('landlord.login.forgot-password-email-sent.back-to-login')}
      </S.BackToLoginBtn>
    </S.DescriptionContainer>
  </Container>
);

ForgotPasswordEmailSent.propTypes = {
  email: PropTypes.string.isRequired
};

export default ForgotPasswordEmailSent;
