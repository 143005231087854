import PropTypes from 'prop-types';

import { getPagePath, trans, useSoyuzLocales } from '@spotahome/soyuz/client';

import {
  loginMethods,
  trackLoginButtonClicked
} from '@spotahome/auth-common/src/tracking/login';

import Facebook from '@spotahome/auth-common/src/components/icons/Facebook';
import Google from '@spotahome/auth-common/src/components/icons/Google';

import * as S from './styles';

const SocialLogin = ({ hideFacebookOauth, hideGoogleOauth }) => {
  const { current: locale } = useSoyuzLocales();

  const getSocialLoginUrl = platform =>
    getPagePath('show-login', {
      params: {
        authMethod: platform
      },
      query: { locale, subscribe: 'no' }
    });

  const handleGoogleClick = () => {
    trackLoginButtonClicked({ loginMethod: loginMethods.GOOGLE });
  };

  const handleFacebookClick = () => {
    trackLoginButtonClicked({ loginMethod: loginMethods.FACEBOOK });
  };

  return (
    <S.SocialLogin>
      {hideFacebookOauth ? null : (
        <S.ButtonWrapper>
          <S.FacebookButton
            data-cy="login-facebook-button"
            onClick={handleFacebookClick}
            as="a"
            href={getSocialLoginUrl('facebook')}
            icon={<Facebook />}
          >
            {trans('landlord.login.social-login.facebook')}
          </S.FacebookButton>
        </S.ButtonWrapper>
      )}
      {hideGoogleOauth ? null : (
        <S.ButtonWrapper>
          <S.GoogleButton
            data-cy="login-gmail-button"
            onClick={handleGoogleClick}
            as="a"
            href={getSocialLoginUrl('google')}
            icon={<Google />}
          >
            {trans('landlord.login.social-login.google')}
          </S.GoogleButton>
        </S.ButtonWrapper>
      )}
    </S.SocialLogin>
  );
};

SocialLogin.propTypes = {
  hideFacebookOauth: PropTypes.bool.isRequired,
  hideGoogleOauth: PropTypes.bool.isRequired
};

export default SocialLogin;
