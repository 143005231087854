import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const SearchHome = props => (
  <BaseIcon width="44" height="44" viewBox="0 0 44 45" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.7 8c.5-.3 1.1-.3 1.6 0l7.5 5.7c.3.2.4.6.4 1V24c0 .7-.5 1.3-1.2 1.3H10c-.7 0-1.3-.6-1.3-1.3v-9.3c0-.4.2-.8.5-1L16.7 8zm-5.4 7.3v7.4h12.4v-7.4l-6.2-4.7-6.3 4.7z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.5 32.5a15 15 0 100-30 15 15 0 000 30zm0 2.5a17.5 17.5 0 100-35 17.5 17.5 0 000 35z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.1 34.4c-.5-.5-.5-1.3 0-1.7l3.6-3.6c.4-.5 1.2-.5 1.7 0l8.5 8.5c.5.5.5 1.3 0 1.8l-3.5 3.5c-.5.5-1.3.5-1.8 0l-8.5-8.5zm2.7-.9l6.7 6.8 1.7-1.8-6.7-6.7-1.7 1.7z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.1 34.4c.5.5 1.3.5 1.8 0l3.5-3.5c.5-.5.5-1.3 0-1.8l-2.7-2.8c-.5-.4-1.3-.4-1.8 0L26.3 30c-.4.5-.4 1.3 0 1.8l2.8 2.7zm.9-2.6l-1-1 1.8-1.8 1 1-1.8 1.8z"
      clipRule="evenodd"
    />
  </BaseIcon>
);

export default SearchHome;
