import { gql } from '@apollo/client';

export const MUTATION_CREATE_SUPPORT_TICKET = gql`
  mutation createSupportTicket($input: CreateSupportTicketInput!) {
    createSupportTicket(input: $input) {
      id
      status
    }
  }
`;

export const MUTATION_CREATE_SUPPORT_TICKET_UNLOGGED = gql`
  mutation createSupportTicketUnlogged(
    $input: CreateSupportTicketUnloggedInput
  ) {
    createSupportTicketUnlogged(input: $input) {
      id
      status
    }
  }
`;
