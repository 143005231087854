import styled from '@emotion/styled';
import { css } from '@emotion/react';

import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import { mediaQueries } from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';
import { pixelsToRem } from '@spotahome/landlord-panel-ui-library/src/utils/units';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: column;
  background: ${colors.athensGray};
  overflow: initial;

  ${mediaQueries.lg(css`
    flex-direction: row;
    overflow: auto;
    height: initial;
  `)}
`;

export const ContentPanel = styled.div`
  font-family: ${fonts.GothamMedium};
  flex-grow: 1;
  position: relative;
  overflow: auto;

  ${mediaQueries.sm(css`
    flex: 1;
    flex-grow: 1.5;
  `)}

  ${mediaQueries.lg(css`
    overflow: initial;
  `)}
`;

export const SidePanel = styled.div`
  background: ${colors.prussianBlue};
  overflow: hidden;
  padding: ${space.x9} ${space.x2} ${space.x2};
  ${mediaQueries.lg(css`
    flex: 1;
    flex-grow: 1;
    padding: ${pixelsToRem(96)} ${space.x4} 0;

    @media screen and (min-height: 601px) {
      padding-top: ${pixelsToRem(160)};
    }
  `)}
`;

export const SidePanelContentWrapper = styled.div`
  background: ${colors.prussianBlue};
  display: flex;
  align-items: center;
  color: ${colors.almostWhite};
  height: 100%;

  ${mediaQueries.lg(css`
    align-items: initial;
    justify-content: center;
    padding-bottom: ${space.x4};
  `)}
`;

export const TopContainer = styled.div`
  font-family: ${fonts.GothamMedium};
`;

export const LogoLink = styled.a`
  position: absolute;
  display: block;
  left: ${space.x2};
  top: ${space.x3};

  ${mediaQueries.lg(css`
    left: ${space.x4};
  `)}
`;

export const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  right: ${space.x2};
  top: ${space.x3};
  height: ${space.x4};
  display: flex;
  align-items: center;

  ${mediaQueries.lg(css`
    right: ${space.x4};
  `)}
`;

export const globalStyles = css`
  html,
  body,
  #root {
    height: 100vh;
  }
`;
