import BaseIcon from '@spotahome/landlord-panel-ui-library/src/icons/Base';

const Verified = props => (
  <BaseIcon width="44" height="54" {...props} viewBox="0 0 38 48">
    <path
      fill="currentColor  "
      d="M16.2 31.6l13.2-13.2-1.867-1.933L16.2 27.8l-5.734-5.667L8.6 24l7.6 7.6zM19 47.867c-5.378-1.556-9.834-4.79-13.368-9.702C2.099 33.255.333 27.733.333 21.6V7.2L19 .2l18.666 7v14.4c0 6.133-1.767 11.655-5.301 16.565-3.532 4.912-7.988 8.146-13.365 9.702zm0-2.8c4.622-1.467 8.444-4.4 11.466-8.8C33.49 31.867 35 26.977 35 21.6V9L19 3.067 3 9v12.6c0 5.378 1.51 10.267 4.533 14.667 3.022 4.4 6.844 7.333 11.467 8.8z"
    />
  </BaseIcon>
);

export default Verified;
