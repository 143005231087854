import styled from '@emotion/styled';

import { space } from '../../shared/spacing';
import fonts from '../../shared/fonts';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalText = styled.p`
  padding: ${space.x1};
  margin-bottom: 0;
  font-size: ${fonts.sizes.m};
  font-family: ${fonts.GothamMedium};
`;
