import { useState, useEffect } from 'react';

function useForm(initialFormValues, validationSchema) {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setValues(initialFormValues);
  }, [JSON.stringify(initialFormValues)]);

  const mapFormErrors = formErrors =>
    formErrors.reduce((acc, formError) => {
      const { path, message } = formError;
      return { ...acc, [path]: message };
    }, {});

  const validateForm = async form => {
    try {
      await validationSchema.validate(form, { abortEarly: false });
      return null;
    } catch (validationError) {
      return validationError.inner;
    }
  };

  const handleChange = async ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value
    });
  };

  const handleSubmit = submitCallback => async event => {
    event.preventDefault();
    setIsSubmitting(true);

    const formErrors = await validateForm(values);
    if (formErrors) {
      setErrors({ ...mapFormErrors(formErrors) });
      setIsSubmitting(false);
    } else {
      setErrors({});
      submitCallback(values, { setIsSubmitting });
    }
  };

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting
  };
}

export default useForm;
