import { ApolloProvider } from '@apollo/client';

import { ThemeProvider, LANDLORD_THEME } from '@spotahome/ui-library/base';

import getGqlClient from '@spotahome/auth-common/src/graphql/client';

import AdditionalInfoStep from './AdditionalInfoStep';

const Signup = () => (
  <ThemeProvider theme={LANDLORD_THEME}>
    <ApolloProvider client={getGqlClient()}>
      <AdditionalInfoStep />
    </ApolloProvider>
  </ThemeProvider>
);

export default Signup;
