import * as yup from 'yup';

export const CITY = 'city';
export const REFERENCE_CODE = 'referenceCode';
export const MOVE_IN_DATE = 'moveInDate';
export const NAME = 'name';
export const EMAIL = 'email';
export const COMMENT = 'comment';
export const LANDLORD_TYPE = 'landlordType';
export const RENTABLE_UNIT_ID = 'rentableUnitId';
export const NEIGHBOURHOOD = 'neighbourhood';
export const PROPERTY_TYPE = 'propertyType';
export const BUDGET = 'budget';
export const TENANT_PROFILE = 'tenantProfile';
export const PROPERTY_QUESTION = 'propertyQuestion';
export const LISTING_INACCURACY_CATEGORY = 'listingInaccuracyCategory';
export const LISTING_MODIFICATION_TYPE = 'listingModificationType';
export const NUMBER_PROPERTIES = 'numberProperties';
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_NUMBER_OPTIONAL = 'phoneNumberOptional';
export const DEVICE = 'device';
export const BROWSER = 'browser';
export const NEW_CONTACT_DETAILS = 'new_contact_details';
export const CANCELLATION_REASON = 'cancellationReason';
export const UNPUBLISH_REASON = 'unpublish_reason';
export const ID_NUMBER = 'idNumber';
export const FULL_ADDRESS = 'fullAddress';

const BUDGET_MIN_AMOUNT = 99;

export const FIELD_IDS = [
  CITY,
  REFERENCE_CODE,
  MOVE_IN_DATE,
  NAME,
  EMAIL,
  COMMENT,
  LANDLORD_TYPE,
  RENTABLE_UNIT_ID,
  NEIGHBOURHOOD,
  PROPERTY_TYPE,
  BUDGET,
  TENANT_PROFILE,
  PROPERTY_QUESTION,
  LISTING_INACCURACY_CATEGORY,
  LISTING_MODIFICATION_TYPE,
  NUMBER_PROPERTIES,
  PHONE_NUMBER,
  PHONE_NUMBER_OPTIONAL,
  DEVICE,
  BROWSER,
  NEW_CONTACT_DETAILS,
  CANCELLATION_REASON,
  UNPUBLISH_REASON,
  ID_NUMBER,
  FULL_ADDRESS
];

export const FORM_FIELDS = {
  [CITY]: {
    type: 'cityAutocompleter',
    props: {
      required: true
    },
    schema: yup
      .string()
      .transform((_, input) =>
        input != null && input.toString ? input.toString() : input
      )
      .ensure()
      .required('contact-us.required-field')
  },
  [REFERENCE_CODE]: {
    type: 'text',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [MOVE_IN_DATE]: {
    type: 'date',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [NAME]: {
    type: 'text',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [EMAIL]: {
    type: 'email',
    props: {
      required: true
    },
    schema: yup
      .string()
      .email('contact-us.required-field')
      .ensure()
      .required('contact-us.required-field')
  },
  [COMMENT]: {
    type: 'textarea',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [RENTABLE_UNIT_ID]: {
    type: 'text',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [LANDLORD_TYPE]: {
    type: 'select',
    props: {
      options: [
        {
          locoKey: 'contact-us.field.landlordType.option.particular',
          value: 'b2b'
        },
        {
          locoKey: 'contact-us.field.landlordType.option.company',
          value: 'b2c'
        }
      ],
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [NEIGHBOURHOOD]: {
    type: 'textarea',
    schema: yup.string().ensure()
  },
  [PROPERTY_TYPE]: {
    type: 'select',
    props: {
      required: true,
      options: [
        {
          locoKey: 'contact-us.field.propertyType.room',
          value: 'room'
        },
        {
          locoKey: 'contact-us.field.propertyType.studio',
          value: 'studio'
        },
        {
          locoKey: 'contact-us.field.propertyType.apt-one-bed',
          value: 'one-bed-apartment'
        },
        {
          locoKey: 'contact-us.field.propertyType.apt-two-bed',
          value: 'two-bed-apartment'
        },
        {
          locoKey: 'contact-us.field.propertyType.three-bed-plus-apt',
          value: 'three-bed-apartment-plus'
        }
      ]
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [BUDGET]: {
    type: 'number',
    props: {},
    schema: yup
      .number()
      .typeError('contact-us.field.budget.validation.number-required')
      .moreThan(
        BUDGET_MIN_AMOUNT,
        'contact-us.field.budget.validation.minimum-amount'
      )
  },
  [TENANT_PROFILE]: {
    type: 'select',
    props: {
      options: [
        {
          locoKey: 'contact-us.field.tenantProfile.student',
          value: 'student'
        },
        {
          locoKey: 'contact-us.field.tenantProfile.professional',
          value: 'professional'
        },
        {
          locoKey: 'contact-us.field.tenantProfile.postgraduate',
          value: 'postgraduate'
        },
        {
          locoKey: 'contact-us.field.tenantProfile.family-group',
          value: 'family-group'
        }
      ]
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [LISTING_MODIFICATION_TYPE]: {
    type: 'select',
    props: {
      options: [
        {
          locoKey: 'contact-us.field.listingModificationType.option.features',
          value: 'features'
        },
        {
          locoKey:
            'contact-us.field.listingModificationType.option.landlord-policies',
          value: 'landlord-policies'
        },
        {
          locoKey:
            'contact-us.field.listingModificationType.option.description',
          value: 'description'
        }
      ]
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [PHONE_NUMBER_OPTIONAL]: {
    type: 'phone',
    props: {
      required: false
    },
    schema: yup.string().ensure()
  },
  [PHONE_NUMBER]: {
    type: 'phone',
    props: {
      required: true
    },
    schema: yup.string().ensure()
  },
  [NUMBER_PROPERTIES]: {
    type: 'select',
    props: {
      options: [
        {
          locoKey: 'contact-us.field.numberProperties.under5',
          value: 'under5'
        },
        { locoKey: 'contact-us.field.numberProperties.5more', value: '5more' }
      ]
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [DEVICE]: {
    type: 'text',
    props: {
      required: false
    },
    schema: yup.string()
  },
  [BROWSER]: {
    type: 'text',
    props: {
      required: false
    },
    schema: yup.string()
  },
  [NEW_CONTACT_DETAILS]: {
    type: 'textarea',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [CANCELLATION_REASON]: {
    type: 'textarea',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [UNPUBLISH_REASON]: {
    type: 'textarea',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [ID_NUMBER]: {
    type: 'text',
    props: {
      required: false
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  },
  [FULL_ADDRESS]: {
    type: 'textarea',
    props: {
      required: true
    },
    schema: yup.string().ensure().required('contact-us.required-field')
  }
};
