import { useState } from 'react';

import queryString from 'query-string';

import * as Yup from 'yup';
import { trans, useSoyuzFlag } from '@spotahome/soyuz/client';

import Input from '@spotahome/landlord-panel-ui-library/src/components/Input';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import userClient from '@spotahome/auth-common/src/api/user-client';
import {
  Container,
  Title,
  Form,
  SubmitButton
} from '@spotahome/auth-common/src/components/Form';
import InputGroup from '@spotahome/auth-common/src/components/InputGroup';
import Disclaimer from '@spotahome/auth-common/src/components/Disclaimer';

import useForm from '@spotahome/auth-common/src/hooks/useForm';

import {
  loginMethods,
  trackCannotAccessClicked,
  trackForgotPasswordClicked,
  trackLoginButtonClicked,
  trackLoginErrorThrown
} from '@spotahome/auth-common/src/tracking/login';
import { getErrorTransKey } from '@spotahome/auth-common/src/lib/errors';

import SocialLogin from './components/SocialLogin';

import * as S from './styles';

const getRedirectUrl = () => {
  if (window?.location) {
    return queryString.parse(window.location.search).redirectUrl;
  }

  return '';
};

const INITIAL_FORM_VALUES = {
  email: '',
  password: ''
};

const FORM_SCHEMA = Yup.object({
  email: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}$/,
      trans('landlord.signup.create-account.email.error.format')
    )
    .required(trans('landlord.signup.create-account.email.error.required')),
  password: Yup.string().required(
    trans('landlord.signup.create-account.password.error.required')
  )
});

const LoginForm = () => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { values, errors, handleSubmit, handleChange, isSubmitting } = useForm(
    INITIAL_FORM_VALUES,
    FORM_SCHEMA
  );
  const hideFacebookOauth = useSoyuzFlag('LandlordHideFacebookOauth').isOn();
  const hideGoogleOauth = useSoyuzFlag('LandlordHideGoogleOauth').isOn();

  const handleSubmitFormError = error => {
    const errorMessage = trans(getErrorTransKey(error));
    setSubmitErrorMessage(errorMessage);
    trackLoginErrorThrown({ error });
  };

  const onSubmit = async ({ email, password }, { setIsSubmitting }) => {
    try {
      const redirectUrl = getRedirectUrl();

      const redirectTo = await userClient.login({
        username: email.toLowerCase(),
        password,
        redirectUrl
      });
      setIsLoggedIn(true);
      document.location.href = redirectTo.data;
    } catch (error) {
      handleSubmitFormError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleForgotPasswordClick = () => {
    trackForgotPasswordClicked();
  };

  const handleCannotAccessClick = () => {
    trackCannotAccessClicked();
  };

  const handleLoginClick = () => {
    trackLoginButtonClicked({ loginMethod: loginMethods.EMAIL });
  };

  return (
    <Container>
      <Title data-cy="login-heading">{trans('landlord.login.heading')}</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputGroup
          id="email"
          label={trans('landlord.login.email.label')}
          error={trans(errors.email)}
        >
          <Input
            data-cy="login-username"
            id="email"
            name="email"
            placeholder={trans('landlord.login.email.placeholder')}
            value={values.email}
            onChange={handleChange}
            hasError={errors.email}
            type="email"
            required
          />
        </InputGroup>
        <InputGroup
          id="password"
          label={trans('landlord.login.password.label')}
          error={trans(errors.password)}
        >
          <Input
            data-cy="login-password"
            id="password"
            name="password"
            placeholder={trans('landlord.login.password.placeholder')}
            value={values.password}
            onChange={handleChange}
            hasError={errors.password}
            type="password"
            required
          />
        </InputGroup>
        <S.ForgotPasswordLink
          onClick={handleForgotPasswordClick}
          data-cy="forgot-password-link"
          to={getRoutePath('landlord.login.forgot-password')}
        >
          {trans('sahc.login.label.forgot_password')}
        </S.ForgotPasswordLink>
        <S.ForgotPasswordLink
          onClick={handleCannotAccessClick}
          data-cy="cannot-access"
          to={getRoutePath('landlord.login.cannot-access')}
        >
          {trans('sahc.login.label.cannot-access')}
        </S.ForgotPasswordLink>

        {!isSubmitting && submitErrorMessage && (
          <S.StyledInputError data-cy="login-error">
            {submitErrorMessage}
          </S.StyledInputError>
        )}
        <Disclaimer translationKey={'sahc.login.terms_and_conditions'} />
        <SubmitButton
          data-cy="login-button"
          type="submit"
          loading={isSubmitting || isLoggedIn}
          onClick={handleLoginClick}
        >
          {trans('landlord.login.login')}
        </SubmitButton>
      </Form>
      {hideFacebookOauth && hideGoogleOauth ? null : (
        <SocialLogin
          hideFacebookOauth={hideFacebookOauth}
          hideGoogleOauth={hideGoogleOauth}
        />
      )}
      <S.SignupPrompt>
        {trans('landlord.login.not-a-member')}{' '}
        <S.SignupLink
          data-cy="signup-link"
          href={getRoutePath('landlord.signup.get-started')}
        >
          {trans('landlord.login.signup')}
        </S.SignupLink>
      </S.SignupPrompt>
    </Container>
  );
};

export default LoginForm;
