import Tracking from '@spotahome/soyuz-tracking';

const BILLING_DETAILS_EDITED = 'BillingDetailsEdited';
const DELETE_ACCOUNT_BUTTON_CLICKED = 'deleteAccountButtonClicked';
const DELETE_ACCOUNT_CONFIRMATION = 'deleteAccountConfirmed';
const WANT_TO_STAY_BUTTON_CLICKED = 'deleteWantToStayButtonClicked';
const SETTINGS_BACK_BUTTON_CLICKED = 'SettingsBackButtonClicked';

// eslint-disable-next-line import/prefer-default-export
export const trackBillingDetailsEdited = (
  user,
  isFirstTime,
  isMultiBankAccount
) => {
  Tracking.segment.trackEvent(BILLING_DETAILS_EDITED, user, {
    first_instance: isFirstTime ? 'yes' : 'no',
    multi_bank_account: isMultiBankAccount ? 'yes' : 'no'
  });
};

export const trackSettingsPlansButtonClicked = user => {
  Tracking.segment.trackEvent('SettingsPlansButtonClicked', user);
  Tracking.hotjar.recordTag(['SettingsPlansButtonClicked']);
};

export const trackDeleteAccountButtonClicked = user => {
  Tracking.segment.trackEvent(DELETE_ACCOUNT_BUTTON_CLICKED, user, {
    user_id: user.id
  });
};

export const trackWantToStayButtonClicked = user => {
  Tracking.segment.trackEvent(WANT_TO_STAY_BUTTON_CLICKED, user, {
    user_id: user.id
  });
};

export const trackDeleteAccountConfirmation = user => {
  Tracking.segment.trackEvent(DELETE_ACCOUNT_CONFIRMATION, user, {
    user_id: user.id
  });
};

export const trackBackButtonClicked = user => {
  Tracking.segment.trackEvent(SETTINGS_BACK_BUTTON_CLICKED, user);
};
