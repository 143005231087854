import styled from '@emotion/styled';

import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

export const Label = styled.label`
  display: block;
  margin-bottom: ${space.x05};
  font-size: ${fonts.sizes.s};
  line-height: ${fonts.lineHeights.regular};
`;

export const LabelText = styled.div`
  font-family: ${fonts.GothamMedium};
  color: ${colors.grey};
`;

export const Description = styled.div`
  margin-top: ${space.x025};
  color: ${colors.grey};
`;
