import styled from '@emotion/styled';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import {
  space,
  border
} from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import Button from '@spotahome/landlord-panel-ui-library/src/components/Button';

const SocialButton = styled(Button)`
  position: relative;
  top: 1px;
  border: 1px solid grey;
  border-radius: ${border.radius};
  background: none;
  padding: ${space.x2} ${space.x4};
  text-decoration: none;
  color: ${colors.grey};
  display: inline-block;
  font-size: ${fonts.sizes.s};

  &:active,
  &:hover {
    &:not(:disabled) {
      color: ${colors.grey};
      background: none;
      box-shadow: none;
      transform: none;
    }
  }
`;

export default SocialButton;
