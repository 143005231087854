import { gql } from '@apollo/client';

const ADDITIONAL_INFO_QUERY = gql`
  query additionalInfo($input: UserIdInput!) {
    userInfo(input: $input) {
      id
      roles
      email
      fullname
      locale
    }
  }
`;

export default ADDITIONAL_INFO_QUERY;
