import styled from '@emotion/styled';

import { Link } from 'react-router';

import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';

import InputError from '@spotahome/auth-common/src/components/InputError';

import { formContainerStyles } from '@spotahome/auth-common/src/components/Form';

export const HelpText = styled.div`
  margin-top: ${space.x05};
  text-align: right;
  font-size: ${fonts.sizes.xs};
`;

export const OrSeperator = styled.div`
  position: relative;
  text-align: center;
  margin: ${space.x4} 0;

  ${formContainerStyles()}

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 45%;
    height: 1px;
    margin-top: 1px;
    background-color: ${colors.grey};
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 45%;
    height: 1px;
    margin-top: 1px;
    background-color: ${colors.grey};
  }
`;

export const LoginPrompt = styled.p`
  margin: ${space.x4} 0;

  ${formContainerStyles()}
`;

export const LoginLink = styled(Link)`
  color: ${colors.primary};
`;

export const StyledInputError = styled(InputError)`
  font-size: ${fonts.sizes.m};
`;
