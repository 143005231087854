import BaseIcon from './Base';

const ChevronDown = props => (
  <BaseIcon width="17" height="10" viewBox="0 0 16 9" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 .572l-8 8-8-8L.572 0 8 7.446 15.429 0 16 .572z"
    />
  </BaseIcon>
);

export default ChevronDown;
