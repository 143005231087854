import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  StyledDisplay,
  StyledHeading,
  StyledParagraph,
  StyledLabel,
  StyledTag
} from './styles';

const components = {
  DisplayXXL: { Component: StyledDisplay, size: 'xxl' },
  DisplayXL: { Component: StyledDisplay, size: 'xl' },
  DisplayL: { Component: StyledDisplay, size: 'l' },
  DisplayM: { Component: StyledDisplay, size: 'm' },
  DisplayS: { Component: StyledDisplay, size: 's' },
  DisplayXS: { Component: StyledDisplay, size: 'xs' },
  HeadingL: { Component: StyledHeading, size: 'l' },
  HeadingM: { Component: StyledHeading, size: 'm' },
  HeadingS: { Component: StyledHeading, size: 's' },
  ParagraphXL: { Component: StyledParagraph, size: 'xl' },
  ParagraphL: { Component: StyledParagraph, size: 'l' },
  ParagraphM: { Component: StyledParagraph, size: 'm' },
  ParagraphMTall: { Component: StyledParagraph, size: 'm' },
  ParagraphS: { Component: StyledParagraph, size: 's' },
  ParagraphSTall: { Component: StyledParagraph, size: 'st' },
  ParagraphSShort: { Component: StyledParagraph, size: 'ss' },
  ParagraphXS: { Component: StyledParagraph, size: 'xs' },
  LabelM: { Component: StyledLabel, size: 'm' },
  LabelS: { Component: StyledLabel, size: 's' },
  TagL: { Component: StyledTag, size: 'l' },
  TagM: { Component: StyledTag, size: 'm' },
  TagS: { Component: StyledTag, size: 's' }
};

const Typography = ({
  variant,
  dataTest = undefined,
  color = null,
  children,
  isHTML = false,
  className = null,
  renderAs = null
}) => {
  const { Component, size } = components[variant];
  const StyledComponent = renderAs
    ? Component.withComponent(renderAs)
    : Component;

  if (isHTML) {
    return (
      <StyledComponent
        className={className}
        data-test={dataTest}
        color={color}
        size={size}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  }

  return (
    <StyledComponent
      className={className}
      data-test={dataTest}
      color={color}
      size={size}
    >
      {children}
    </StyledComponent>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'DisplayXXL',
    'DisplayXL',
    'DisplayL',
    'DisplayM',
    'DisplayS',
    'DisplayXS',
    'HeadingL',
    'HeadingM',
    'HeadingS',
    'ParagraphXL',
    'ParagraphL',
    'ParagraphM',
    'ParagraphMTall',
    'ParagraphS',
    'ParagraphSTall',
    'ParagraphSShort',
    'ParagraphXS',
    'LabelM',
    'LabelS',
    'TagL',
    'TagM',
    'TagS'
  ]).isRequired,
  dataTest: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHTML: PropTypes.bool,
  className: PropTypes.string,
  renderAs: PropTypes.string
};

export default memo(Typography);
