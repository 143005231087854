import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const DIRECTION = {
  BACKWARD: 'Backward',
  FORWARD: 'Forward'
};

const animations = {
  transitionIn: keyframes`
    0% {
      opacity: 0;
      /* margin-left: 80px; */
    }
    100% {
      opacity: 1;
      /* margin-left: 0; */
    }
  `,
  transitionOut: keyframes`
    0% {
      opacity: 1;
      /* margin-left: 0; */
    }
    100% {
      opacity: 0;
      /* margin-left: -80px; */
    }
  `,
  transitionInBackward: keyframes`
    0% {
      opacity: 0;
      /* margin-left: -80px; */
    }
    100% {
      opacity: 1;
      /* margin-left: 0; */
    }
  `,
  transitionOutBackward: keyframes`
    0% {
      opacity: 1;
      /* margin-left: 0; */
    }
    100% {
      opacity: 0;
      /* margin-left: 80px; */
    }
  `
};

export const TransitionContainer = styled.div`
  ${({ show }) =>
    !show
      ? `
  position: absolute;
  top: 0;
  `
      : ''}
  animation: ${({ show, direction, animateOnLoad }) => {
    if (!animateOnLoad) return '';
    const animationSuffix =
      direction === DIRECTION.BACKWARD ? DIRECTION.BACKWARD : '';
    return show
      ? animations[`transitionIn${animationSuffix}`]
      : animations[`transitionOut${animationSuffix}`];
  }}
    0.3s;
  animation-timing-function: cubic-bezier(0.183, 0, 0.037, 1);
`;

export default { TransitionContainer };
