import styled from '@emotion/styled';

import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';

import { formContainerStyles } from '@spotahome/auth-common/src/components/Form';
import SocialButton from '@spotahome/auth-common/src/components/SocialButton';

export const SocialLogin = styled.div`
  margin-top: ${space.x3};

  ${formContainerStyles()}
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FacebookButton = styled(SocialButton)``;

export const GoogleButton = styled(SocialButton)``;
