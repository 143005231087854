import { Link } from 'react-router';
import { trans } from '@spotahome/soyuz/client';

import { getRoutePath } from '@spotahome/auth-common/src/router';

import { Container, Title } from '@spotahome/auth-common/src/components/Form';

import * as S from './styles';

const PasswordCreated = () => (
  <Container>
    <Title>{trans('sahc.redeem-invitation.password-created.heading')}</Title>
    <S.DescriptionContainer>
      <S.Description>
        {trans('sahc.redeem-invitation.password-created.description')}
      </S.Description>
      <S.BackToLoginBtn as={Link} to={getRoutePath('landlord.login')}>
        {trans('sahc.redeem-invitation.password-created.login')}
      </S.BackToLoginBtn>
    </S.DescriptionContainer>
  </Container>
);

export default PasswordCreated;
